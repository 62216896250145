class TableHeader {
  /* -------------------------------- INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER --------------------------------
    
        id:- Use for uniquely identify each column in data table,
        accessor: - Use to locate the record from json objcet, If want to access the record from the child, use . operator.
                    Example:- 
                                1> name, is from parent, ( {name:'name'} )
                                2> owner.name, where owner is parent & name is inside owner object, ( owner:{name:'name'} )
        dataType:- This value can be string, number, boolean, date. This use for alignment of table header & table body.
                    1> string & date - left (handle in DataTable.js)
                    2> boolean- center (handle in DataTable.js)
                    3> number-  right (handle in DataTable.js)
        sortable:- Use to enable & disable sorting for perticuler column.
        disablePadding:- Use to enable padding.
        visibility:- Use to toggle column visibility.
        width:- Use to set individual column min-width in px. It's should be number format.
        label:- Refer the values from translations file, (./src/translations/**.js).
        className:- It's basically the js file name & all the file should be available in utils folder.
        functionName:-  It's basically the function name, which should be available inside above className 
        
    ----------------------------- END OF INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER -----------------------------*/

  static adminTableHeader = [

// Booking ID
// Patient  name
// Mobile number
// Hospital name
// Booking status
// Convenience fee
// Created on
// Updated on

    {
      // Booking Id
      id: 'booking',
      accessor: 'orderId',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      label: 'Recharge Id',
      className: '',
      functionName: '',
    },
    

    {
      // PatientName
      id: 'name',
      accessor: 'userId.name',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,    
       label: 'Name',
      className: '',
      functionName: '',
    },
    // {
    //   id: 'lname',
    //   accessor: 'lname',
    //   dataType: 'date',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 120,
    //   label: 'user.lname',
    //   className: '',
    //   functionName: '',
    // },

    {
      id: 'mobileNo',
      accessor: 'userId.mobileNo',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      label: 'Mobile No',
      className: '',
      functionName: '',
    },
    {
      // hospital Name
      id: 'planName',
      accessor: 'planName',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      label: 'Plan Name',
      className: '',
      functionName: '',
    },
    
    {
      // hospital Name
      id: 'planType',
      accessor: 'planType',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
   
            label: 'plan Type',
      className: '',
      functionName: '',
    },
    {
      // hospital Name
      id: 'subscribedPrice',
      accessor: 'subscribedPrice',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
      // overflow:"hidden",
      // width:"100px",
            label: 'subscribed Price',
      className: '',
      functionName: '',
    },
    

    {
      // status
      id: 'status',
      accessor: 'transactionType',
      dataType: 'string',
      sortable: true,
      disablePadding: false,
      visibility: true,
   
      label: 'Invoice',
      className: 'StringUtils',
      functionName: 'invoice',
    },

   

   
    
   
 

    {
      id: 'createdAt',
      accessor: 'createdAt',
      dataType: 'date',
      sortable: true,
      disablePadding: false,
      visibility: true,
   
      label: 'createdAt',
      className: 'DateConversion',
      functionName: 'unixToLocaleDate',
    },
    {
      id: 'updatedAt',
      accessor: 'updatedAt',
      dataType: 'date',
      sortable: true,
      disablePadding: false,
      visibility: true,
   
      label: 'updatedAt',
      className: 'DateConversion',
      functionName: 'unixToLocaleDate',
    },

  ];
}
export default TableHeader;
