import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  TextField,
  Typography,
  Tooltip,
  Hidden,
  FormControlLabel,
  Button,
  Checkbox,
  Avatar,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  

} from '@material-ui/core';
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IoIosInformationCircle } from 'react-icons/io';
import { MdDeleteSweep, MdCloudUpload } from 'react-icons/md';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
// import landingImg from "../../../../../assets/images/Pic.png";
import "./aev.scss";
import TextareaAutosize from '@mui/material/TextareaAutosize';

import FileUpload from '../../../../templates/FileUpload';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FileUploadTableCheckBox from '../../../../templates/FileUploadTableCheckBox';
import LocalStorage from '../../../../config/LocalStorage';
import DateFnsUtils from '@date-io/date-fns';
import isEmpty from '../../../../utils/isEmpty';
import APIRequest from '../../../../utils/APIRequest';
import FormValidation from '../../../../utils/FormValidation';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import DateConversion from '../../../../utils/DateConversion'; 
import { connect } from 'react-redux';
import SnackbarUtils from '../../../../utils/SnackbarUtils';
import {FaInfoCircle} from 'react-icons/fa';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ChipInput from 'material-ui-chip-input';
import { config } from 'aws-sdk';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // overflowX: "auto",

    paddingLeft: 10,
    paddingRigt: 10,
    
  },
  paper: {
    padding: 10,
    margin: 10,
    height: '100%',
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: '-5%',
    position: 'relative',
    left: '42%',
  },
  userTypeMenuItem: {
    minWidth: '50%',
    marginTop: '6px',
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  main :{
    flexDirection:"row",
    justifyContent:"spaceBetween",
    width:"80%",
    margin:"auto",
    border:"1px solid blue",

},
main2 :{
  flexDirection:"column"
},
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
const userFormDetails = {
  name: '',
  description: '',
  mobileNo: '',
  visitPatientHome: false,
  consultationFee: '',
  active: true,
  workStartedYear: new Date().getFullYear(),
  profileImg: '', //pending
  gender: '',
  education: [
    {
      year: '',
      college: '',
      course: '',
      specialization: '',
    },
  ],
  experience: [
    {
      startAt: new Date(),
      stillWorkingHere: false,
      endAt: new Date(),
      clinic: '',
      specialization: '',
    },
  ],
  award: [
    {
      title: '',
      year: '',
      certificates: '',
    },
  ],
  qualification: [],
  specialization: '',
  otherSpecialization: '',
  membership: [],
  registration: '',
  licenseNo: '',
  designation: '',
  services: [],
  contact: {
    email: '',
    fax: '',
    // mobileNo: '',
    alternativeNo: '',
    extension: '',
    officeNo: '',
  },
  township: '',
  city: '',
  state: '',
  country: '',
  pincode: '',
  fragmentedAddress: '',
  priority: 10,
  isPremiumMember: false,
  socialMedia: [
    {
      platform: '',
      siteLink: '',
    },
  ],
};

function AEVForm(props) {
  const classes = useStyles();
  const [userForm, setUserForm] = useState({
    status:"",
    // name: '',
    // description: '',
    // mobileNo: '',
    // visitPatientHome: false,
    // consultationFee: '',
    // active: true,

    // profileImg: '', //pending
    // gender: '',
    // education: [
    //   {
    //     year: '',
    //     college: '',
    //     course: '',
    //     specialization: '',
    //   },
    // ],
    // experience: [
    //   {
    //     startAt: new Date(),
    //     stillWorkingHere: false,
    //     endAt: new Date(),
    //     clinic: '',
    //     specialization: '',
    //   },
    // ],
    // award: [
    //   {
    //     title: '',
    //     year: '',
    //     certificates: '',
    //   },
    // ],
    // qualification: [],
    // specialization: '',
    // otherSpecialization: '',
    // membership: [],
    // registration: '',
    // licenseNo: '',
    // designation: '',
    // services: [],
    // contact: {
    //   email: '',
    //   fax: '',
    //   // mobileNo: '',
    //   alternativeNo: '',
    //   extension: '',
    //   officeNo: '',
    // },
    // township: '',
    // city: '',
    // state: '',
    // country: '',
    // pincode: '',
    // fragmentedAddress: '',
    // priority: 10,
    // isPremiumMember: false,
    // socialMedia: [
    //   {
    //     platform: '',
    //     siteLink: '',
    //   },
    // ],
  });
  const [aws] = useState(LocalStorage.aws);
  const [priorityOptions, setPriorityOptions] = useState([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
  ]);
  const [selectedRowId, setSelectedRowId] = useState("");

  const [yearOptions, setYearOptions] = useState([]);
  const [searchSpecialization, setSearchSpecialization] = useState("")
  const [specializationOptions, setSpecializationOptions] = useState([]);
  const profileImageFileUpload = useRef();
  const awardFileUpload = useRef();
  const [profileImageFileBase64, setProfileImageFileBase64] = useState([]);
  const [file, setFile] = useState('');
  const [fileUploadStatus, setFileUploadStatus] = useState(false);

  const [serviceOptions, setServiceOptions] = useState([]);
  const [socialMediaOptions, setSocialMediaOptions] = useState([]);

  const [awardFile, setAwardFile] = useState(null);
  const [awardFileBase64, setAwardFileBase64] = useState('');
  const [imageAwardIndex, setImageAwardIndex] = useState(0);
const[companyName,SetCompanyName]=useState([])
const[review,setReview]=useState([])
const [reviewid,setReviewId]=useState("")
const [reviewdata,setReviewData]=useState({
  feedback:"",
  ratings:"",
})

// console.log(companyName);
 
  const generateYears = () => {
    let currentYear = new Date().getFullYear();
    let years = [];
    let startYear = 1950;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    setYearOptions(years);
  };

  useEffect(() => {
    // getServiceOptions();
    // getSocialMediaPlatform();
    generateYears();
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === 'submit'
    ) {
      sendToServer();
      reviewupdate();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === 'reset'
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== '' &&
      props.formDialog.divType !== 'new' &&
      props.formDialog.functionName === ''
    )
     {
       getEditable();
    }
  }, [props.formDialog]);




  
useEffect(() => {
    APIRequest.request(
      'GET',
      ConfigAPIURL.getSpecialization
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.code === 100 && response.data.responseCode === 109
        ) {
            setSpecializationOptions(response.data.result);
          }
        }
      }
    );
  }, []);
  
  const ambulance = () => {
    // APIRequest.request(
    //   "POST",
    //   ConfigAPIURL.ambulanceOrders,
    //   JSON.stringify({
    //       // recordId: "6321a9060d00da3c7010555a",
    //      recordId: props.rowDetails._id,
    //   })
    // ).then((response) => {
    //    console.log(response);
    //   if (response !== undefined && response !== null) {
    //     if (response.code === 100 && response.data.responseCode === 109) {
    //       let result=response?.data?.result
    //       result["pickUpDateTime"]=DateConversion.unixToLocaleDateTime(result["pickUpDateTime"])

    //       // result1["pickUpDateTime"]=DateConversion.unixToLocaleDate(result["pickUpDateTime"])

    //       SetCompanyName(result);
    //       console.log("krishna",result )
    //     }
    //             // if(SetCompanyName(response?.data?.result.scheduledDateTime)){
    //             //   console.log("date")
    //             // }
    //   }
    // });
  };
  const ambulanceReview=()=>{
    
    console.log(props.rowDetails._id)
    APIRequest.request(
      "GET",
      ConfigAPIURL.ambulancereview+"?recordId="+`${props.rowDetails._id}`,
      
"",
    ).then((response)=>{
      console.log(response)
      if (response !== undefined && response !== null) {
        if (response.data.responseCode === 109) {
          let result=response?.data?.result
          setReview(result)
          setReviewData(result)
          setReviewData({
            ...reviewdata,
            feedback:result[0]?.feedback,
            ratings:result[0]?.rating,
            feedback1:result,
            ratings1:result,
          })
          setReviewId(result[0]?._id)
          console.log(result,"review")
          // console.log(SetCompanyName)
        }
      }
    })
  }
            console.log(reviewdata,"review1")

  useEffect(() => {
    ambulance();
    ambulanceReview();
  }, []);
  const reviewupdate=()=>{
    APIRequest.request(
      "PUT",
      ConfigAPIURL.ambulanceupdatereview,
      JSON.stringify({
        recordId:reviewid,
        feedback:reviewdata?.feedback
      })
    ).then((response)=>{
      console.log(response)
      if (response !== undefined && response !== null) {
        if (
          response.code === 100 &&
          response.data.responseCode === 109
        ) {

          // setSubmit(true)
          SnackbarUtils.sucess(
            "method" === "POST"
              ? props.t("snackbar.saved")
              : props.t("snackbar.updated"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
          props.handleFormDialog();
          // props.tableQuery({
          //   keyword: '',
          //   page: 0,
          //   pageSize: 10,
          //   sortField: '',
          //   userType: 'all',
          //   sortOrder: 'false',
          //   active: true,
          // });
          // setUserForm(userForm);
        }
        if (
          response.code === 100 &&
          response.data.responseCode === 114
        ) {
          SnackbarUtils.error(
            props.t("snackbar.dupliRecords"),
            "bottomCenter",
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });
        }
      }
    })
  }
  const resetForm = () => {
    setUserForm({
      name: '',
      description: '',
      mobileNo: '',
      visitPatientHome: false,
      consultationFee: '',
      active: true,

      profileImg: '', //pending
      gender: '',
      education: [
        {
          year: '',
          college: '',
          course: '',
          specialization: '',
        },
      ],
      experience: [
        {
          startAt: new Date(),
          stillWorkingHere: false,
          endAt: new Date(),
          clinic: '',
          specialization: '',
        },
      ],
      award: [
        {
          title: '',
          year: '',
          certificates: '',
        },
      ],
      qualification: [],
      specialization: '',
      otherSpecialization: '',
      membership: [],
      registration: '',
      licenseNo: '',
      designation: '',
      services: [],
      contact: {
        email: '',
        fax: '',
        // mobileNo: '',
        alternativeNo: '',
        extension: '',
        officeNo: '',
      },
      township: '',
      city: '',
      state: '',
      country: '',
      pincode: '',
      fragmentedAddress: '',
      priority: 10,
      isPremiumMember: false,
      socialMedia: [
        {
          platform: '',
          siteLink: '',
        },
      ],
    });
  };
const date=""

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== 'new'
    ) 
    {
      APIRequest.request(
        "POST",
        ConfigAPIURL.rechargeid,
        JSON.stringify({
          // recordId: "6321a9060d00da3c7010555a",
          userId:props?.rowDetails?.userId,
          tid: props?.rowDetails?.tid,
        })
        // ConfigAPIURL.ambulanceOrders,
        // JSON.stringify({
        //   // recordId: "6321a9060d00da3c7010555a",
        //   recordId: props.rowDetails._id,
        // })
      )
      .then((response) => {
        if (response !== undefined && response !== null) {
          if (response?.code === 100 && response?.data?.responseCode === 109) {
            let result = response?.data?.result;
          // result["pickUpDateTime"] = DateConversion.unixToLocaleDateTime(
          //   result["pickUpDateTime"]
          // );
           setUserForm(result)
          // setUserForm({
          //   ...userForm,
          //   status:result?.status
          // });
          // result1["pickUpDateTime"]=DateConversion.unixToLocaleDate(result["pickUpDateTime"])

          SetCompanyName(result);
           
          }
        }
      });
    }
  };
  
  // const BookingDate=companyName?.pickUpDateTime?.split(" ",3)
  //  console.log("1234",BookingDate?.[0])

  //keyboard shortcut

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case 'save':
        sendToServer();
        reviewupdate();
        break;
      case 'reset':
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey('');
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    APIRequest.request(
      "PUT",
      ConfigAPIURL.ambulancestatus,
      JSON.stringify({
       recordId:props.rowDetails._id,
       status:userForm?.status,
      })
    ).then((response) => {
     if (response !== undefined && response !== null) {
       if (
         response.code === 100 &&
         response.data.responseCode === 109
       ) {
        
         // setSubmit(true)
         SnackbarUtils.sucess(
           "method" === "POST"
             ? props.t("snackbar.saved")
             : props.t("snackbar.updated"),
           "bottomCenter",
           3000
         ).then((notification) => {
           props.publishNotification(notification);
         });
          props.handleFormDialog();
         // props.tableQuery({
         //   keyword: '',
         //   page: 0,
         //   pageSize: 10,
         //   sortField: '',
         //   userType: 'all',
         //   sortOrder: 'false',
         //   active: true,
         // });
         setUserForm(userForm);
         setSelectedRowId("");
       }
       if (
         response.code === 100 &&
         response.data.responseCode === 114
       ) {
         SnackbarUtils.error(
           props.t("snackbar.dupliRecords"),
           "bottomCenter",
           3000
         ).then((notification) => {
           props.publishNotification(notification);
         });
       }
     }
      console.log(response ,"ambulance");
    });
  };
  var utcSeconds = 1345688;



console.log(userForm,"status")
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: "black",
  }));
  return (
//   <Stack
//   style={{margin:"auto",border:"1px solid red",width:"80%",marginTop:"50px",height:"auto"}}
//   >

// <Grid container>
//   <Grid item sm={6}>
//   <Stack style={{

// }}>
//       <img 
//       style={{objectFit:"contain",
//       width:"120px",
//       height:"70px",
//     borderRadius:"50px"}}
//       src="https://s3.ap-south-1.amazonaws.com/hospinity/logo/1675062357952_Hospinity.png" alt="logo"/>

//     </Stack>
//     <Stack>
//       <Typography>Nekaaramitra</Typography>
//     </Stack>
//   </Grid>
//   <Grid item sm={6}>
  
//   </Grid>
// </Grid>


//   </Stack>
  <Document>
    <Page style={{}}>
      <View 
      // className="main"
      style={
        styles.main
        }>
        
        <View 
        // className="main2"
        style={        styles.main1
        }>
          <img
          style={{objectFit:"contain",
                 width:"120px",
                 height:"70px",
               borderRadius:"50px",}}
          src="https://s3.ap-south-1.amazonaws.com/hospinity/logo/1675062357952_Hospinity.png" alt="logo"/>
          <Text style={{ fontFamily: "OpenSans-Bold" }}>
        Hospinity
        </Text>
        </View>
        
      
        <View>
          Retail Invoivce Bill
        </View>


      </View>

    </Page>

  </Document>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: 'SHORTCUTKEY', value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);
