import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import APIRequest from "../../../../../utils/APIRequest";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import { ArrowUpload24Filled } from "@fluentui/react-icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Stack } from "@fluentui/react";

const UpdateLoom = (props) => {
  const {
    isModalOpen,
    setModalOpen,
    loom,
    getEditable,
    showNotification,
    userId,
    listAllLooms,
    loomId,
    handleClosepop,
  } = props;

  const [loomDetailsData, setLoomDetails] = useState({
    serviceNo: "",
    loomNo: "",
    description: "",
    images: [],
    loomType: "",
    userImages: [],
  });

  const handleClose = () => {
    setModalOpen(false);
    handleClosepop();
  };

  const uploadFile = (props) => {
    APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      props
    ).then((response) => {
      if (response !== undefined && response !== null) {
        const attachmentUrl = {
          name: "",
          type: "",
          url: response.data.attachmentUrl[0],
          note: "",
        };
        setLoomDetails((prevData) => ({
          ...prevData,
          images: [...prevData.images, attachmentUrl],
        }));
      }
    });
  };

  const uploadFileHandler = (event) => {
    console.log(" got called");
    if (event.target.files.length === 0) return;

    [...event.target.files].forEach((file) => {
      let formData = new FormData();
      formData.append(`attachment`, file);
      formData.append("bucketName", "profile");
      uploadFile(formData);
    });
  };

  const sendToServer = () => {
    let url = ConfigAPIURL.updateLoomDetails;
    let method = "POST";
    const payload = {
      userId: userId,
      loomId: loomId?._id,
      userImages: loomDetailsData?.images,
      ...loomDetailsData,
    };
    console.log(payload);
    APIRequest.request(method, url, JSON.stringify(payload)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            listAllLooms();
            handleClose();
            handleClosepop();
            SnackbarUtils.sucess(
              "Loom data updated successfully!",
              "bottomCenter",
              3000
            ).then((notification) => {
              showNotification(notification);
            });
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              "Something went wrong! Please try again later.",
              "bottomCenter",
              3000
            ).then((notification) => {
              showNotification(notification);
            });
          }
        }
      }
    );
  };

  const loomDetails = () => {
    let url = ConfigAPIURL.getLoomDetails;
    let method = "POST";
    const payload = {
      userId: userId,
      loomId: loomId?._id,
    };
    APIRequest.request(method, url, JSON.stringify(payload)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            setLoomDetails({
              serviceNo: response?.data?.loom?.serviceNo,
              loomNo: response?.data?.loom?.loomNo,
              description: response?.data?.loom?.description,
              images: response?.data?.loom?.images,
              loomType: response?.data?.loom?.loomType,
              userImages: response?.data?.loom?.userImages,
            });
          }
        }
      }
    );
  };

  useEffect(() => {
    if (!isModalOpen) return;
    loomDetails();
  }, [isModalOpen]);

  const deleteUpload = (index) => {
    const newAttachment = loomDetailsData?.images.filter(
      (value, i) => i !== index
    );
    setLoomDetails({
      ...loomDetailsData,
      images: newAttachment,
    });
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      maxHeight="md"
     
    >
      <DialogTitle>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Update Loom
        </Typography>
      </DialogTitle>
      <DialogContent style={{ gap: 8 ,position:"relative"}}>
        <TextField
          label="Service Number"
          variant="outlined"
          className="textborder"
          value={loomDetailsData?.serviceNo || ""}
          onChange={(e) =>
            setLoomDetails({
              ...loomDetailsData,
              serviceNo: e.target.value,
            })
          }
          required
          fullWidth
          style={{ marginTop: 20 }}
        />
        <TextField
          label="Loom Number"
          variant="outlined"
          className="textborder"
          value={loomDetailsData?.loomNo || ""}
          onChange={(e) =>
            setLoomDetails({
              ...loomDetailsData,
              loomNo: e.target.value,
            })
          }
          required
          fullWidth
          style={{ marginTop: 20 }}
        />
        <TextField
          label="Loom Type"
          variant="outlined"
          className="textborder"
          value={loomDetailsData?.loomType || ""}
          onChange={(e) =>
            setLoomDetails({
              ...loomDetailsData,
              loomType: e.target.value,
            })
          }
          required
          fullWidth
          style={{ marginTop: 20 }}
        />
        <Stack
          horizontal
          style={{
            gap: "4px",
            width: "100%",
            marginBottom: "8px",
            marginTop: "8px",
            minHeight: "130px",
            overflowX: "auto",
            background: "#F9F9F9",
            paddingLeft: "20px",
            borderRadius: "8px",
            border: "1px solid #E5E5E5",
          }}
        >
          <Stack horizontal gap={10} style={{ flex: "1 0 auto" }}>
            {/* Render images and PDFs */}
            {loomDetailsData &&
              loomDetailsData?.images?.map((value, i) => (
                <Stack
                  style={{
                    position: "relative",
                    width: "170px",
                    marginBottom: "8px",
                  }}
                  key={i}
                >
                  <Stack
                    verticalAlign="center"
                    horizontalAlign="center"
                    style={{
                      width: "28px",
                      height: "28px",
                      background: "white",
                      position: "absolute",
                      borderRadius: "100%",
                      top: "23px",
                      right: "6px",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon
                      onClick={() => deleteUpload(i)}
                      className="deleteIcon"
                      style={{ color: "#FF8D00" }}
                    />
                  </Stack>
                  {value.url &&
                    value.url.trim() !== "" &&
                    (value.url.endsWith(".pdf") ? (
                      <embed
                        src={value.url}
                        type="application/pdf"
                        width="100%"
                        height="150px"
                        onClick={() => window.open(value.url, "_blank")}
                      />
                    ) : (
                      <img
                        src={value.url}
                        alt=""
                        onClick={() => window.open(value.url, "_blank")}
                        style={{
                          objectFit: "contain",
                          width: "90%",
                          height: "150px",
                          cursor: "pointer",
                        }}
                      />
                    ))}
                </Stack>
              ))}
          </Stack>
          <Stack
            style={{
              position: "absolute",
              top: "48%",
              right: "9px",
              transform: "translateY(-50%)",
              // background: "#F9F9F9", // Ensure the background matches the parent to cover the gap
              padding: "8px",
              borderRadius: "8px",
              border: "1px solid #E5E5E5",
            }}
          >
            <label htmlFor="image">
              <ArrowUpload24Filled style={{ cursor: "pointer" }} />
            </label>
            <input
              accept=".png, .jpg, .jpeg, .pdf"
              multiple
              id="image"
              type="file"
              style={{ display: "none" }}
              onChange={uploadFileHandler}
            />
          </Stack>
        </Stack>

        <TextField
          label="Notes"
          variant="outlined"
          className="textborder"
          value={loomDetailsData?.description || ""}
          onChange={(e) =>
            setLoomDetails({
              ...loomDetailsData,
              description: e.target.value,
            })
          }
          required
          fullWidth
          multiline
          rows={4}
          style={{ marginTop: 20, wordWrap: "break-word" }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={sendToServer} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLoom;
