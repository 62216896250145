import { Stack } from "@mui/material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useUpload from "../hooks/useUpload";

const SendMessage = ({ sendMessage, selectedChat }) => {
  const [textMessage, setTextMessage] = useState("");
  const [isEmojiVisible, setIsEmojiVisible] = useState(false);

  const handleSendMessage = () => {
    if (!textMessage) return;
    sendMessage(selectedChat?.requestedBy._id, textMessage);
    setTextMessage("");

    return;
  };
  const handleMedia = (media) => {
    sendMessage(selectedChat?.requestedBy._id, "", media);
  };
  const { fileUpload } = useUpload(handleMedia);

  return (
    <Stack style={{ flexDirection: "row", alignItems: "center", gap: 3 }}>
      <Stack
        style={{
          width: "100%",
          height: "40px",

          borderRadius: "12px",
          flexDirection: "row",
          alignItems: "center",
          padding: "8px .5rem ",
          background: "#fff",
          justifyContent: "center",
          position: "relative",
          boxShadow:
            "0px 6.400000095367432px 14.399999618530273px 0px #00000021",
        }}
      >
        {" "}
        <textarea
          // rows={1}
          className="scroll"
          autoFocus
          onKeyDown={(e) => {
            const keyCode = e.which || e.keyCode;

            if (keyCode === 13 && !e.shiftKey) {
              e.preventDefault();
              if (!textMessage) return;
              if (e.code === "Enter") handleSendMessage();
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              if (!textMessage) return;
              handleSendMessage();
            }
          }}
          style={{
            resize: "none",
            height: "20px",
            outline: "none",
            border: "none",
            flexGrow: 2,
            backgroundColor: "#fff",
            fontFamily: "Poppins",
          }}
          type="text"
          placeholder="Message"
          value={textMessage}
          onChange={(e) => setTextMessage(e.target.value)}
        />
      </Stack>
      <label htmlFor="input">
        <AttachFileIcon
          style={{
            transform: "rotate(45deg)",
            cursor: "pointer",
            color: "#FF8D00",
          }}
        />
      </label>
      <input
        type="file"
        style={{ display: "none", wordBreak: "break-word" }}
        id="input"
        multiple
        onChange={(e) => fileUpload(e, handleMedia)}
      />
      {
        <button
          disabled={textMessage ? false : true}
          style={{
            outline: "none",
            border: "none",
            background: "#FF8D00",
            cursor: "pointer",
            color: "#fff",
            height: "38px",
            width: "75px",
          }}
          onClick={handleSendMessage}
        >
          Send
        </button>
      }
    </Stack>
  );
};

export default SendMessage;
