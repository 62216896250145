import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  Font,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import styled from "@react-pdf/styled-components";
import Lato from "./Lato-Bold.ttf";
import LatoRegular from "./Lato-Regular.ttf";
import LatoBlack from "./Lato-Black.ttf";
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 45,
    paddingHorizontal: 35,
    backgroundColor: "#fff",
    Font:"Lato"
  },
  section: {
    marginTop: 50,
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
  },
  imageCover: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%",
  },
  view: {
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  section1: {
    marginTop: 60,
    textAlign: "center",
    fontSize: 34,
    fontWeight: "bold",
  },
  image: {
    objectFit: "cover",
  },
  font:{
    Font:"Lato"

  }
});
Font.register({
  family: 'Lato',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
});

const Invoice = (data,) => {

  const amount=data?.data?.planPrice/10
  const discount=data?.data?.discount/10
  const discountammount=amount*discount
  console.log(discountammount)
  console.log(data?.company,"company");

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottomColor: "#000",
              borderBottomWidth: 1,
            }}
          >
            <View style={{ justifyContent: "center", alignItems: "center" }}>
            {/* <Image
                src={{
                  uri: "https://s3.ap-south-1.amazonaws.com/nekaaramitra/profile/1680596948436_Nekaaramitra.png",
                }}
                style={{
                  width: "30px",
                  height: "30px",
                  // resizeMode: "contain",
                }}
              /> */}
                    <Image
                src={{
                  uri: "https://s3.ap-south-1.amazonaws.com/nekaaramitra/profile/1680600425273_Frame%2034929.png",
                }}
                style={{
                  width: "30px",
                  height: "30px",
                  // resizeMode: "contain",
                }}
              />
              <Text
                style={{
                  // width: 120,
                  height: 30,
                  resizeMode: "contain",
                }}
              >
                                    {data?.company?.companyAddress?.companyName}
              </Text>
            </View>
            <View style={{ justifyContent: "center" }}>
              <Text
                style={{
                  fontSize: 11,
                  // padding: 4,
                 fontFamily:"Lato",
                  fontWeight: "bold",
                  resizeMode: "contain",

                }}
              >
                {"Retail Invoice / Bill"}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: "#000",
              borderBottomWidth: 1,
              // padding: "0.5rem",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                width: "50%",
                padding: 5,
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    // fontWeight: 'bold',
                    paddingLeft: 10,
                    // fontFamily:"Opensans",
                  }}
                >
                  {"Name : "}
                </Text>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    fontWeight: "bold",
                    // fontFamily:"Opensans",
                  }}
                >
                  {data?.data?.userId?.name}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    margin: 0,
                    paddingLeft: 10,
                    // fontFamily:"Opensans",
                  }}
                >
                  {"MobileNo"} :{" "}
                </Text>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    fontWeight: "bold",
                    magin: 0,
                    // fontFamily:"Opensans",
                  }}
                >
                                   {data?.data?.userId?. mobileNo
}
                </Text>
              </View>
            </View>
            <View
              style={{
                justifyContent: "center",
                // width: "50%",
                padding: 5,
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    // fontWeight: 'bold',
                    paddingLeft: 10,
                    // fontFamily:"Opensans",
                  }}
                >
                  {"Invoice No"} :{" "}
                </Text>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    fontWeight: "bold",
                    // fontFamily:"Opensans",
                  }}
                >
       {data?.data?. orderId}

                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    margin: 0,
                    paddingLeft: 10,
                    // fontFamily:Lato,
                  }}
                >
                  {"Booking Date"} :{" "}
                </Text>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    fontWeight: "bold",
                    magin: 0,
                    // fontFamily:Lato,
                  }}
                >
 {new Date(data?.data?.createdAt * 1000).getDate() +
                  "/" +
                  (new Date(data?.data?.createdAt * 1000).getMonth() + 1) +
                  "/" +
                  new Date(data?.data?.createdAt * 1000).getFullYear()}                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              // border: "2px solid black",
              flexDirection: "row",
              borderBottomColor: "#000",
              // borderBottomWidth: 1,
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
              border:"1px solid red"
            }}
          >
            <View
              style={{
                width: "50%",
                padding: 5,
              }}
            >
              <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    fontWeight: "bold",
                    paddingLeft: 10,
                    // fontFamily:Lato,
                  }}
                >
                  From :{" "}
                </Text>
              </View>
              <View style={{ width: "100%" }}>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    //   fontWeight: "bold",
                    // fontFamily:Lato,
                    marginTop: "5px",
                  }}
                >
                                    {data?.company?.companyAddress?.companyName}

                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    marginTop: "5px",

                    //   fontWeight: "bold",
                    // fontFamily:Lato,
                  }}
                >
                  {data?.company?.companyAddress?.fragmentedAddress}
                </Text>
                
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    //   fontWeight: "bold",
                    // fontFamily:Lato,
                    marginTop: "5px",
                  }}
                >
                 {data?.company?.companyAddress?.state}

                </Text>
                {/* <Text
                style={{
                  textAlign: "left",
                  fontSize: 9,
                  fontWeight: "bold",
                  paddingLeft: 10,
                  // fontFamily:Lato,
                }}
              >
                {"City"} :{" "}
                {isMentor
                  ? data?.mentorId?.address?.city
                  : data?.userId?.address?.city}
              </Text> */}
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    //   fontWeight: "bold",
                    paddingLeft: 10,
                    // fontFamily:Lato,
                    marginTop: "5px",
                  }}
                >
                  {/* {"Contact "} :{" "} */}
                  {data?.company?.companyAddress?.mobileNo}

                  {/* {isMentor
                  ? "+91 " + data?.mentorId?.mobileNo
                  : "+91 " + data?.userId?.mobileNo} */}
                </Text>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    //   fontWeight: "bold",
                    paddingLeft: 10,
                    // fontFamily:Lato,
                    marginTop: "5px",
                  }}
                >
                  {/* {"Contact "} :{" "} */}
                  {data?.company?.companyAddress?.email}
                  {/* {isMentor
                  ? "+91 " + data?.mentorId?.mobileNo
                  : "+91 " + data?.userId?.mobileNo} */}
                </Text>
              </View>
            </View>

            <View
              style={{
                width: "50%",
                padding: 5,
                justifyContent:"flex-end",
                marginBottom:"-65px"
              }}
            >
              <View style={{ flexDirection: "row", flexWrap: "wrap",
            justifyContent:"flex-end"
            }}>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    fontWeight: "bold",
                    paddingLeft: 10,
                    // fontFamily:Lato,
                  }}
                >
                  To :{" "}
                </Text>
              </View>
              <View style={{ width: "100%" ,            justifyContent:"flex-end"
}}>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    //   fontWeight: "bold",
                    // fontFamily:Lato,
                    marginTop: "5px",

                    alignSelf:"flex-end"
                  }}
                >
                  {data?.data?.userId?.name}
                </Text>

                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    marginTop: "5px",

                    //   fontWeight: "bold",
                    // fontFamily:Lato,
                    alignSelf:"flex-end"

                  }}
                >
                  {data?.data?.userId?.flatNo+""+data?.data?.userId?.streetName+""+data?.data?.userId?.city}
                </Text>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    paddingLeft: 10,
                    alignSelf:"flex-end",

                    //   fontWeight: "bold",
                    // fontFamily:Lato,
                  }}
                >
                 {data?.data?.userId?.state}

                  {/* {"LandMark"} : {data.address.landMark} */}
                </Text>
                
               
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    //   fontWeight: "bold",
                    paddingLeft: 10,
                    // fontFamily:Lato,
                    marginTop: "5px",
                    alignSelf:"flex-end"

                  }}
                >
                  {/* {"Contact "} :{" "} */}
                  {data?.data?.userId?.mobileNo}
                  {/* {isMentor
                  ? "+91 " + data?.mentorId?.mobileNo
                  : "+91 " + data?.userId?.mobileNo} */}
                </Text>
                <Text
                  style={{
                    textAlign: "left",
                    fontSize: 10,
                    //   fontWeight: "bold",
                    paddingLeft: 10,
                    // fontFamily:Lato,
                    marginTop: "5px",
                    alignSelf:"flex-end"

                  }}
                >
                  {/* {"Contact "} :{" "} */}
                  {data?.data?.userId?.email}
                  {/* {isMentor
                  ? "+91 " + data?.mentorId?.mobileNo
                  : "+91 " + data?.userId?.mobileNo} */}
                </Text>
       
              </View>
              {/*  */}
            </View>
          
          </View>

          <View
          style={{
            // borderTopColor:"#000",
          borderBottomColor:"#000",
          borderRightColor:"#000",
          borderLeftColor:"#000",
        
        }}
          >
            <View
            style={{
              // marginBottom:"-190px",

            }}
            >
          <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#000",
            padding: 10,
            marginTop: "1rem",
            marginTop:"15px",
            backgroundColor: "#17024B",
          }}
        >
          
          <View style={{ justifyContent: "center", width: "20%" }}>
            <Text
              style={{
                fontSize: 11,
                textAlign: "center",
                // fontFamily:Lato,
                fontWeight: "bold",
                color:"white"
              }}
            >
              Plan Type
            </Text>
          </View>
    
          <View style={{ justifyContent: "center", width: "20%" }}>
            <Text
              style={{
                fontSize: 11,
                textAlign: "center",
                // fontFamily:Lato,
                fontWeight: "bold",
                color:"white"
              }}
            >
              {/* {"invoicePDF.discount"} */}
              {"Plan Charges"}
            </Text>
          </View>
      

          <View style={{ justifyContent: "center", width: "20%" }}>
            <Text
              style={{
                fontSize: 11,
                textAlign: "center",
                // fontFamily:Lato,
                fontWeight: "bold",
                color: "white",
              }}
            >
              {"IGST (18%)"}

              {/* {"invoicePDF.billAmount"} */}
            </Text>
          </View>
          <View style={{ justifyContent: "center", width: "20%" }}>
            <Text
              style={{
                fontSize: 11,
                textAlign: "center",
                // fontFamily:Lato,
                fontWeight: "bold",
                color: "white",
              }}
            >
              {"SGST (9%)"}

              {/* {"invoicePDF.billAmount"} */}
            </Text>
          </View>
          <View style={{ justifyContent: "center", width: "20%" }}>
            <Text
              style={{
                fontSize: 11,
                textAlign: "center",
                // fontFamily:Lato,
                fontWeight: "bold",
                color: "white",
              }}
            >
              {"CGST (9%)"}

              {/* {"invoicePDF.billAmount"} */}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#000",
            padding: 10,
            marginTop: "1rem",
            marginTop:"15px",
          }}
        >
          
          <View style={{ justifyContent: "center", width: "20%" }}>
            <Text
              style={{
                fontSize:"10",
                textAlign: "center",
                // fontFamily: "Lato",
                // fontWeight: "bold",
                textTransform: "capitalize",

              }}
            >
              {data?.data?.planName}
            </Text>
          </View>
    
          <View style={{ justifyContent: "center", width: "20%" }}>
            <Text
              style={{
                fontSize:"10",
                textAlign: "center",
                // fontFamily: "Lato",
                // fontWeight: "bold",
              }}
            >
              {data?.data?.planPrice-discountammount}
            </Text>
          </View>
      

          <View style={{ justifyContent: "center", width: "20%" }}>
            {/* {userId?.state===""} */}
            {(data?.data?.userId?.state===data?.company?.companyAddress?.state)||(data?.data?.userId?.state==="")?
            <Text
            style={{
              fontSize:"10",
              textAlign: "center",
              // fontFamily: "Lato",
              // fontWeight: "bold",
              // color: "white",

            }}
          >
            {data?.data?.taxAmount}

          </Text>:     <Text
            style={{
              fontSize:"10",
              textAlign: "center",
              // fontFamily: "Lato",
              // fontWeight: "bold",
              // color: "white",

            }}
          >
            0

          </Text>
}
            
          </View>
          <View style={{ justifyContent: "center", width: "20%" }}>
          {data?.company?.companyAddress?.state!==data?.data?.userId?.state? <Text
              style={{
                fontSize:"10",
                textAlign: "center",
                // fontFamily: "Lato",
                // fontWeight: "bold",
                // color: "white",
              }}
            >
              {data?.data?.taxAmount/2}

            </Text>:
             <Text
             style={{
               fontSize:"10",
               textAlign: "center",
               // fontFamily: "Lato",
               // fontWeight: "bold",
               // color: "white",
             }}
           >
             0

           </Text>
            }

           
          </View>
          <View style={{ justifyContent: "center", width: "20%" }}>
          {data?.company?.companyAddress?.state!==data?.data?.userId?.state? <Text
              style={{
                fontSize:"10",
                textAlign: "center",
                // fontFamily: "Lato",
                // fontWeight: "bold",
                // color: "white",
              }}
            >
              {data?.data?.taxAmount/2}

            </Text>:
             <Text
             style={{
               fontSize:"10",
               textAlign: "center",
               // fontFamily: "Lato",
               // fontWeight: "bold",
               // color: "white",
             }}
           >
             0

           </Text>
            }
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderBottomColor: "#000",
            padding: 10,
            marginTop: "1rem",
            marginTop:"15px",
           justifyContent:"flex-end",
          }}
        >
          
          <View style={{ flexDirection:"row", width:"30%"  }}>
            <Text
              style={{
                fontSize:"10",
                textAlign: "center",
                // fontFamily: "Lato",
                fontWeight: "bold",
              }}
            >
              Total Amount :{" "}
            </Text>
            <Text
              style={{
                fontSize:"10",
                textAlign: "center",
                // fontFamily: "Lato",
                // fontWeight: "bold",
                marginRight:"-45px"

                
              }}
            >
              {data?.data?.subscribedPrice}
            </Text>
          </View>
    
         
      

         
        </View>
        </View>
        </View>

        <View style={{marginTop:"15px",}}>
          <View>
            <Text
            style={{
              fontSize: 11,
             textAlign: "left",
            //  fontFamily: "Lato",
             fontWeight: "bold",
            //  color: "white",
            }}
             
            >Delecration</Text>
          </View>
          <View style={{marginTop:"10px"}}>
            <Text
            style={{
              fontSize: 10,
             textAlign: "left",
            //  fontFamily: "Lato",
            //  fontWeight: "bold",
            //  color: "white",
            }}
             
            >
              We Declare that the invoice shows the actual price of the Services describe
          and that all particulars are true and correct
          
          </Text>
          </View>


        </View>

        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
