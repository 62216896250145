import { useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import DataGrid from "./DataGrid";
import { withTranslation } from "react-i18next";
import { Grid, makeStyles } from "@material-ui/core";

import { connect } from "react-redux";

import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px%",
  },
}));

function Plans(props) {
  const classes = useStyles();
  

  const {publishNotification} = props;

  return (
    <div className={classes.root}>
      <Grid container style={{ textTransform: "capitalize" }}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <DataGrid publishNotification={publishNotification} />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    sideDrawerData: state.sideDrawerData,
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(Plans)
);
