import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import TableQuery from './TableQuery';
import LocalStorage from '../../../../config/LocalStorage';
import TableHeader from './TableHeader';
import SnackbarUtils from '../../../../utils/SnackbarUtils';
import { connect } from 'react-redux';
import APIRequest from '../../../../utils/APIRequest';
import SlideInDialog from '../../../../templates/dialog/SlideInDialog';
import DataTable from '../../../../templates/tables/DataTable';
import APIRequestDataTableQuery from '../../../../utils/APIRequestDataTableQuery';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import TableButtons from '../../../../templates/buttons/TableButtons';

import FormDialog from '../../../../templates/dialog/FormDialog';
import AEVForm from './AEVForm';
// import AEVForm from './A';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: '-5%',
    position: 'relative',
    left: '42%',
  },
  userTypeMenuItem: {
    minWidth: '50%',
    marginTop: '6px%',
  },
}));

function Advertisement(props) {
  const classes = useStyles();

  const [slideInDialog, setSlideInDialog] = useState(false);
  /* table query body -
	keyword, page, pageSize, sortField, sortOrder should be constant for all the page. 
	Here status is use for filter condition.
	*/
  const [query, setQuery] = useState({
    page:0,
    pageSize:10,
    status:"all",
    active:true,
    startDate:null,
    endDate:null,
    startAt:null,
    endAt: null,
  });
  // initial table record
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });

  const [openForm, setOpenForm] = useState({
    status: false,
    title: '',
    divType: '',
    functionName: '',
  });
  // user form Values
  const [userForm, setUserForm] = useState({
    userType: '',
    active: false,
    fname: '',
    lname: '',
    mobileNo: '',
    email: '',
    age: '',
    dob: new Date(),
    gender: '',
    weight: 0,
    height: 0,
    isSmoker: false,
    alcoholIntake: 'none',
    allergies: '',
    chronicConditions: '',
    familyHistory: '',
    surgeries: '',
    password: '',
  });

  const handleSlideInDialog = () => {
    setSlideInDialog(false);
    tableQuery(query);
  };
  // selected table record information
  const [rowDetails, setRowDetails] = useState(null);
  // buttons list for data table
  const [buttonList, setButtonDetails] = useState([]);

  // useful of on page load
  useEffect(() => {
    tableQuery(query); // calling table api on page load by default value
    setButtonDetails(LocalStorage.adminButtonPermission);
  }, [LocalStorage.adminButtonPermission]);
  // table query body value handler
  const handleQuery = (query) => {
    setQuery(query);
    tableQuery(query);
  };
  // table query body value reset handler
  const resetQuery = () => {
    setQuery({
      keyword: '',
      page: 0,
      pageSize: 10,
      sortField: '',
      sortOrder: 'false',
      startAt: null,
      endAt: null,
      active: true,
      status:"all",
      startDate:null,
      endDate:null
    });
    tableQuery({
      keyword: '',
      page: 0,
      pageSize: 10,
      sortField: '',
      sortOrder: 'false',
      startAt: null,
      endAt: null,
      active: true,
      status:"all",
      startDate:null,
      endDate:null
    });
  };
  // fetch table record from server with current query body
  const tableQuery = (query) => {
    if (
      LocalStorage.adminButtonPermission.find(function (element) {
        return element.button === 'query' ? true : false;
      })
    ) {
      APIRequestDataTableQuery.request(
        'POST',
        ConfigAPIURL.advertisement,
        JSON.stringify(query)
      ).then((tableData) => {
        setTableData(tableData);
        console.log(tableData)
      });
    } else {
      // notification(props.t('toaster.tableRecordPermission'), 'error');
    }
  };
  const notification = (message, type) => {
    SnackbarUtils[type](message, 'bottomCenter', 3000).then((notification) => {
      props.publishNotification(notification);
    });
  };
  // table record selected callback
  const tableCallBack = (rowDetails) => {
    setRowDetails(rowDetails);
  };
  //table button call back function
  const tableAdd = () => {
    setOpenForm({
      status: true,
      title: props.t('advertisements.addAdvertisements'),
      divType: 'new',
      functionName: '',
    });
  };
  const tableEdit = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== '') {
      setOpenForm({
        status: true,
        title: props.t('advertisements.editAdvertisements'),
        divType: 'edit',
        functionName: '',
      });
    } else {
      SnackbarUtils.error(
        props.t('snackbar.selectRow'),
        'bottomCenter',
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const tableView = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== '') {
      setOpenForm({
        status: true,
        title: props.t('advertisements.viewAdvertisements'),
        divType: 'view',
        functionName: '',
      });
    } else {
      SnackbarUtils.error(
        props.t('snackbar.selectRow'),
        'bottomCenter',
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const tableDelete = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== '') {
      if (query.active === true) {
        setSlideInDialog(true);
      } else {
        SnackbarUtils.error(
          props.t('snackbar.recordAlreadyDeleted'),
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      SnackbarUtils.error(
        props.t('snackbar.selectRow'),
        'bottomCenter',
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const tableUpload = () => {
    // console.log('tableUpload');
  };
  const tableApprove = () => {
    // console.log('tableApprove');
  };
  const tableReject = () => {
    // console.log('tableReject');
  };
  const tableCheckStatus = () => {
    // console.log('tableCheckStatus');
  };
  const handleFormDialog = () => {
    setRowDetails(null);
    setOpenForm({ status: false, title: '', divType: '', functionName: '' });
  };
  const submit = () => {
    setOpenForm({ ...openForm, functionName: 'submit' });
  };
  const resetForm = () => {
    setOpenForm({ ...openForm, functionName: 'reset' });
  };

  // const handleDeleteRequest = (response) => {
  //   if (response === 'agree') {
  //     APIRequest.request(
  //       'GET',
  //       ConfigAPIURL.advertisementDelete,
  //       JSON.stringify({ recordId: rowDetails._id })
  //     ).then((response) => {
  //       console.log(response)
  //       if (response !== undefined && response !== null) {
  //         if (response.code === 100 && response.data.responseCode === 109) {
  //           SnackbarUtils.sucess(
  //             props.t('snackbar.deletesuccess'),
  //             'bottomCenter',
  //             3000
  //           ).then((notification) => {
  //             props.publishNotification(notification);
  //           });
  //           handleSlideInDialog();
  //         }
  //       }
  //     });
  //   } else {
  //     handleSlideInDialog();
  //   }
  // };
  const handleDeleteRequest = (response) => {
    if (response === 'agree') {
      APIRequest.request(
        'PUT',
        ConfigAPIURL.deleteadvertisment,
          JSON.stringify({ recordId: rowDetails._id })
        // ""
      ).then((response) => {
        console.log(response)
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t('snackbar.deletesuccess'),
              'bottomCenter',
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            handleSlideInDialog();
          }
        }
      });
    } else {
      handleSlideInDialog();
    }
  };

  return (
    <div className={classes.root}>
      <Grid container 
      style={{textTransform:"capitalize"
    }}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <DataTable
            tableHeader={TableHeader.tableHeader} // table column header
            tableData={tableData} // pass table record
            query={query} // pass query condition
            setQuery={handleQuery} // update query fields
            resetQuery={resetQuery} // reset filter menu values
            selectAll={false} //select all check box props
            openForm={openForm} //un select all check box props
            tableCallBack={tableCallBack}
            searchLabel={props.t('advertisements.searchlabel')}
            queryComponent={<TableQuery query={query} setQuery={handleQuery} />} //filter menu option
            buttonComponent={
              <TableButtons
                 tableAdd={tableAdd}
                tableEdit={tableEdit}
                tableView={tableView}
                tableDelete={tableDelete}
                tableUpload={tableUpload}
                tableApprove={tableApprove}
                tableReject={tableReject}
                tableCheckStatus={tableCheckStatus}
                buttonList={buttonList}
              />
            }
          />
        </Grid>
      </Grid>
      <FormDialog
        sideDrawerData={props.sideDrawerData}
        formDialog={openForm}
        handleFormDialog={handleFormDialog}
        submit={submit}
        resetForm={resetForm}
        formComponent={
          <AEVForm
            formDialog={openForm}
            handleFormDialog={handleFormDialog}
            rowDetails={rowDetails}
            tableQuery={tableQuery}
          />
        }
      />

      <SlideInDialog
        title={props.t('advertisements.deleteAdvertisements')}
        contentComponent={props.t('dialog.delete')}
        fullWidth={true}
        maxWidth={'sm'}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleDeleteRequest}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    sideDrawerData: state.sideDrawerData,
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(mapStateToProps, mapDispachToProps)(Advertisement)
);
