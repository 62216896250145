import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Tooltip,
  Checkbox,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { connect } from "react-redux";
import { MdDeleteSweep, MdCloudUpload } from "react-icons/md";
import DateFnsUtils from "@date-io/date-fns";
import imageCompression from "browser-image-compression";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalStorage from "../../../../config/LocalStorage";
import FileUploadTableCheckBox from "../../../../templates/FileUploadTableCheckBox";
import FileUpload from "../../../../templates/FileUpload";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import FormValidation from "../../../../utils/FormValidation";
import Toaster from "../../../../utils/Toaster";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { IoIosInformationCircle } from "react-icons/io";
import { Stack } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingLeft: 10,
    paddingRigt: 10,
    marginTop: 60,
    background:"white"
  },
  paper: {
    padding: 10,
    height: "100%",
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px",
  },

  formGrid: {
    marginBottom: 10,
  },
}));
const addFormDetails = {
  title: "",
  description: "",
  adPosition: "",
  siteLink: "",
  category: "",
  typeOfLink: "banner",
  userType:"",
  moduleType:"",

  priority: "normal", // high, normal,low
  active: true,
  images: [],
  lat: "",
  lng: "",
  // searchKey: [], //chip input
  products: [], //inventory ids
  bannerList: [

  ],
  // startAt: new Date().setSeconds(0),
  // endAt: new Date(new Date().setDate(new Date().getDate() + 7)).setSeconds(59),
  startAt: Math.floor(new Date().getTime() / 1000.0),
  endAt: Math.floor(
    new Date(new Date(new Date().setDate(new Date().getDate() + 7))).getTime() /
      1000.0
  ),
  // startAt:new Date(),
  // endAt:new Date()
};

function AEVForm(props) {
  const classes = useStyles();
  const [addForm, setAddForm] = useState(addFormDetails);
  console.log(addForm);

  const [bannerListVal, setBannerListVal] = useState({
    image: "",
    siteLink: "",
    // searchKey: [],
    category: "",
  });
  // const [coridinates, setCordinates] = useState([{ lat: "", long: "" }]);
  // console.log(bannerListVal)
  const [selectedImages, setSelectedImages] = useState([]);

  const [galleryFileBase64, setGalleryFileBase64] = useState([]);

  const [bannerCheckBox, setBannerCheckBox] = useState([]);

  const [aws] = useState(LocalStorage.aws);
  const [attachmentsFileBase64, setattachmentsFileBase64] = useState([]);
  const [file, setFile] = useState("");
  const [fileUploadStatus, setFileUploadStatus] = useState(false);

  const bannerFileUpload = useRef();

  const galleryFileUpload = useRef();

  const [adPositionOptions, setAdPositionOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [addstart,setAddStart]=useState(addFormDetails.startAt)

  useEffect(() => {
    getCategoryOptions();
    getOptions();
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "submit"
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === "reset"
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "" &&
      props.formDialog.divType !== "new" &&
      props.formDialog.functionName === ""
    ) {
      getEditable();
    }
  }, [props.formDialog]);

  const resetForm = () => {
    setAddForm(addFormDetails);
    setGalleryFileBase64([]);
    setBannerListVal({
      image: "",
      siteLink: "",
      // searchKey: [],
      category: "",
    });
  };

  const [moreInfoOpen, setMoreInfoOpen] = useState(false);

  const handleTooltipOpen = () => {
    setMoreInfoOpen(true);
  };

  const getCategoryOptions = () => {
    // APIRequest.request(
    //   "POST",
    //   ConfigAPIURL.getOptions,
    //   JSON.stringify({ name: "category" })
    // ).then((response) => {
    //   if (response !== undefined && response !== null) {
    //     if (
    //       response.data.responseCode !== undefined &&
    //       response.data.responseCode !== null
    //     ) {
    //       if (response.data.responseCode === 109) {
    //         setCategoryOptions(response.data.options[0].options);
    //       }
    //     }
    //   }
    // });
  };
  const getOptions = () => {
    // APIRequest.request(
    //   "POST",
    //   ConfigAPIURL.options,
    //   JSON.stringify({ name: "adPosition" })
    // ).then((response) => {
    //   if (response !== undefined && response !== null) {
    //     if (
    //       response.data.responseCode !== undefined &&
    //       response.data.responseCode !== null
    //     ) {
    //       if (response.data.responseCode === 109) {
    //         setAdPositionOptions(response.data.options[0].options);
    //       }
    //     }
    //   }
    // });
  };

  const handleTooltipClose = () => {
    setMoreInfoOpen(false);
  };

  const handleGalleryImage = (event) => {
    if (
      aws !== "" &&
      event.target.files[0] !== undefined &&
      event.target.files[0] !== null
    ) {
      if (
        event.target.files[0].type.endsWith("jpeg") ||
        event.target.files[0].type.endsWith("png")
      ) {
        [...event.target.files].map(async (data) => {
          let img = new Image();

          img.src = window.URL.createObjectURL(data);

          img.onload = () => {
            // if (img.width !== 436) {
            //   SnackbarUtils.error(
            //     props.t('advertisements.imageWidthRequired') + img.width,
            //     'bottomCenter',
            //     3000
            //   ).then((notification) => {
            //     props.publishNotification(notification);
            //   });
            //   return;
            // }
            // if (img.height !== 224) {
            //   SnackbarUtils.error(
            //     props.t('advertisements.imageHeightRequired') + img.height,
            //     'bottomCenter',
            //     3000
            //   ).then((notification) => {
            //     props.publishNotification(notification);
            //   });
            //   return;
            // }

            let reader = new FileReader();
            let url = "";
            if (data.size < 1000000) {
              url = reader.readAsDataURL(data);
            } else {
              SnackbarUtils.error(
                props.t("snackbar.fileLarge"),
                "bottomCenter",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
              return;
            }
            SnackbarUtils.sucess(
              props.t("snackbar.uploadReady"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });

            reader.onloadend = function () {
              setGalleryFileBase64(reader.result.split("base64,")[1]);
              setFile(data);
              setFileUploadStatus(true);
              galleryFileUpload.current._s3Upload();
            }.bind(this);
          };

          return;
        });
      } else {
        setFileUploadStatus(false);
        setGalleryFileBase64("");
        setFile("");
        SnackbarUtils.error(
          props.t("snackbar.onlyImage"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      setFileUploadStatus(false);
      setGalleryFileBase64("");
      setFile("");
      SnackbarUtils.error(
        props.t("snackbar.errorUploading"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  // const handleBannerImage = (event) => {
  //   if (event.target.files[0] !== undefined && event.target.files[0] !== null) {
  //     setFileUploadStatus(true);
  //     [...event.target.files].map(async (data) => {
  //       let img = new Image();
  //       img.src = window.URL.createObjectURL(data);

  //       img.onload = async () => {
  //         let formData = new FormData();
  //         if (data.size > 3000000) {
  //           SnackbarUtils.error(
  //             props.t("snackbar.fileLarge"),
  //             "bottomCenter",
  //             3000
  //           ).then((notification) => {
  //             props.publishNotification(notification);
  //           });
  //         }

  //         if (data.size > 300000) {
  //           var options = {
  //             maxSizeMB: 1,
  //             maxWidthOrHeight: 1920,
  //             useWebWorker: true,
  //           };

  //           let compressedFile = await imageCompression(
  //             event.target.files,
  //             options
  //           );

  //           formData.append(`attachment`, compressedFile);
  //         } else {
  //           formData.append(`attachment`, data);
  //         }

  //         formData.append("bucketName", "logo");

  //         APIRequest.multipartForDataRequest(
  //           "PUT",
  //           ConfigAPIURL.uploadFile,
  //           formData
  //         ).then((response) => {
  //           console.log(response)
  //           if (response !== undefined && response !== null) {
  //             setTimeout(function () {
  //               setFileUploadStatus(false);
  //               // setAddForm({
  //               // 	...addForm,
  //               // 	bucketName: response.data.attachmentUrl[0],
  //               // });
  //               setBannerListVal({
  //                 ...bannerListVal,
  //                 image: response.data.attachmentUrl[0],
  //               });
  //             }, 1000);
  //           } else {
  //             setFileUploadStatus(false);
  //           }
  //         });
  //       };
  //     });
  //   } else {
  //     setFileUploadStatus(false);
  //     SnackbarUtils.error(
  //       props.t("snackbar.errorUploading"),
  //       "bottomCenter",
  //       3000
  //     ).then((notification) => {
  //       props.publishNotification(notification);
  //     });
  //   }
  // };
  const imageupload = async (e) => {
    const formdata = new FormData();
    const file = e.target.files[0];
    formdata.append("attachment", file);
    formdata.append("bucketName", "advertisementBucket");

    const response = await APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      formdata
    );
    if (response.data.responseCode === 109) {
      setTimeout(() => {
        let userResult = response?.data?.attachmentUrl[0];
        console.log(userResult);

        // setImageData.push(obj)
        //  moveImagesToUserForm(response?.data?.attachmentUrl[0]);

        // addForm.advertisementList.push({
        //   mediaUrl: userResult,
        //   siteLink: bannerListVal?.siteLink,
        // });

        setAddForm({
          ...addForm,
          bannerList: [
            ...addForm.bannerList,
            {
              image: userResult,
              siteLink: bannerListVal.siteLink,
            },
          ],
        });
      }, 1);
    }
  };

  const imageTableRowDetails = (selected) => {
    if (selected !== undefined && selected !== null) {
      setSelectedImages(selected);
    }
  };

  const _s3GalleryImageUrl = (url) => {
    let user = addForm;
    user.images.push(url);
    setAddForm(user);
    SnackbarUtils.sucess(
      props.t("snackbar.successUpload"),
      "bottomCenter",
      3000
    ).then((notification) => {
      props.publishNotification(notification);
    });
    setFileUploadStatus(false);
    setGalleryFileBase64("");
    setFile("");
  };

  const _deleteS3Files = (event) => {
    let form = addForm;
    selectedImages.map((selectedimages, idx) => {
      let index = form.images.indexOf(selectedimages);
      if (index !== -1) {
        form.images.splice(index, 1);
      }
    });
    setAddForm({ ...addForm, form });
  };

  const handleCategoryOptions = (event) => {
    setAddForm({
      ...addForm,
      category: event.target.value,
    });
  };
  const handleBannerCategoryOptions = (event) => {
    setBannerListVal({
      ...bannerListVal,
      category: event.target.value,
    });
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== "new"
    ) {
      APIRequest.request(
        "GET",
        ConfigAPIURL.advertismentbyid +
          "?recordId=" +
          `${props.rowDetails._id}`,
        // JSON.stringify({ AdvId: props.rowDetails._id })
        ""
      ).then((response) => {
        console.log(response);
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            let userResult = response?.data?.result;
            // console.log(userResult)
            // userResult.startAt = new Date(userResult.startAt * 1000);
            // userResult.endAt = new Date(userResult.endAt * 1000);
            // userResult.searchKey =
            //   userResult.searchKey !== ''
            //     ? userResult.searchKey.split(',')
            //     : [];

            setAddForm({
              ...addForm,

              title: userResult.title,
              description: userResult.description,
              adPosition: userResult.adPosition,
              userType:userResult.userType,
              moduleType:userResult.moduleType,
              siteLink: userResult.siteLink,
              category: userResult.category,
              typeOfLink: userResult.typeOfLink,
              priority: userResult.priority,
              active: userResult.active,
              // location: {
              //   coordinates: [
              //     userResult.location.coordinates[0],
              //     userResult.location.coordinates[1],
              //   ],
              // },
              lat: userResult?.location?.coordinates[1],
              lng: userResult.location?.coordinates[0],
              images: userResult.images,
              // searchKey: userResult.searchKey,
              products: userResult.products,
              bannerList: userResult.bannerList,
              startAt: userResult.startAt,
              endAt: userResult.endAt,
            });
          }
        }
      });
    }
  };

  const sendToServer = () => {
    let url = ConfigAPIURL.createadvertisment;
    let method = "POST";

    if (
      props.formDialog.divType === "edit" ||
      props.formDialog.divType === "view"
    ) {
      url = ConfigAPIURL.advertismentupdate;
      addForm["recordId"] = props.rowDetails._id;
      method = "PUT";
    }

    let fieldValidation = ["title",  ];

    // addForm.typeOfLink === 'external'
    //   ? ['title', 'endAt', 'startAt', 'typeOfLink', 'siteLink']
    //   : addForm.typeOfLink === 'internal'
    //   ? ['title', 'searchKey', 'endAt', 'category', 'startAt', 'typeOfLink']
    //   : ['title', 'typeOfLink'];
    // console.log("add form when sending to server",addForm);

    FormValidation.validation(fieldValidation, addForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          if (
            (addForm.typeOfLink === "internal" ||
              addForm.typeOfLink === "external") &&
            addForm.images.length < 1
          ) {
            Toaster.error(props.t("toaster.uploadImages"), "topRight");
            return;
          }
          let productIds = [];
          if (addForm.typeOfLink === "product") {
            addForm.products.map((productsSelected) => {
              productIds.push(productsSelected._id);
            });
          }
          APIRequest.request(method, url, JSON.stringify(addForm)).then(
            (response) => {
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  SnackbarUtils.sucess(
                    props.t("snackbar.updatedSuccess"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                  props.handleFormDialog();
                  props.tableQuery({
                    keyword: "",
                    page: 0,
                    pageSize: 10,
                    sortField: "",
                    userType: "all",
                    sortOrder: "false",
                    active: true,
                    status:"all",
                    startDate:null,
                    endDate:null,
                    startAt:null,
                    endAt: null,
                  });
                  setAddForm(addFormDetails);
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t("snackbar.dupliRecords"),
                    "bottomCenter",
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  const handleBannerImage = (event) => {
    if (aws !== '') {
      [...event.target.files].map(async (data) => {
        let reader = new FileReader();
        let url = '';
        if (data.size < 3000000) {
          url = reader.readAsDataURL(data);
        } else {
          SnackbarUtils.error(
            props.t('snackbar.fileLarge'),
            'bottomCenter',
            3000
          ).then((notification) => {
            props.publishNotification(notification);
          });

          return;
        }
        SnackbarUtils.sucess(
          props.t('snackbar.uploadReady'),
          'bottomCenter',
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });

        reader.onloadend = function () {
          // SnackbarUtils.error('Error uploading file',   'bottomCenter', 3000).then(
          //   (notification) => {
          //     props.publishNotification(notification);
          //   }
          // );
          setattachmentsFileBase64(reader.result.split('base64,')[1]);
          setFile(data);
          setFileUploadStatus(true);
          bannerFileUpload.current._s3Upload();
          handleBannerPush()
        }.bind(this);
      });
    } else {
      setFileUploadStatus(false);
      setattachmentsFileBase64('');
      setFile('');
      SnackbarUtils.error(
        props.t('snackbar.errorUploading'),
        'bottomCenter',
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const handleDeleteVideo = (ind) => {
    let form = addForm;

    delete form.bannerList[ind];
    let temp = [];
    form.bannerList.map((banner, ind) => {
      if (banner) {
        temp.push(banner);
      }
    });

    form.bannerList = temp;
    setBannerCheckBox([false]);

    setAddForm({ ...addForm, form });
  };

  const _s3BannerImageUrl = (url) => {
    setFileUploadStatus(false);
    setattachmentsFileBase64("");
    setFile("");
    setBannerListVal({
      ...bannerListVal,
      image: url,
    });
    SnackbarUtils.sucess(
      props.t("snackbar.successUpload"),
      "bottomCenter",
      3000
    ).then((notification) => {
      props.publishNotification(notification);
    });
  };

  const handleBannerPush = () => {
    let fieldValidation = ["image",];
    FormValidation.validation(fieldValidation, bannerListVal).then(
      (ValidationResult) => {
        if (ValidationResult) {
          let adform = addForm;
      
          adform.bannerList.push(bannerListVal);
          // console.log(bannerListVal,"1st response")
          let bannercheckbox = bannerCheckBox;
          bannercheckbox.push(false);
          setBannerListVal({ ...bannerCheckBox }, bannercheckbox);
          setAddForm(adform);
          setBannerListVal({
            ...bannerListVal,
            image: "",
            siteLink: "",
            // searchKey: [],
            category: "",
          });
        }
      }
    );
  };

  const handleCheckBox = (event, index) => {
    let bannerCheck = bannerCheckBox;
    bannerCheck[index] = event.target.checked;
    setBannerCheckBox([...bannerCheckBox], bannerCheck);
  };

  const handleDeleteBanner = () => {
    let form = addForm;
    bannerCheckBox.map((banners, index) => {
      if (banners) {
        delete form.bannerList[index];
      }
    });
    let temp = [];
    form.bannerList.map((banner, idx) => {
      if (banner) {
        temp.push(banner);
      }
    });

    form.bannerList = temp;
    setBannerCheckBox([false]);

    setAddForm({ ...addForm, form });
  };

  // const handleStartAtDateChange = (date) => {
  //   if (
  //     Math.floor(date.getTime()) ===
  //     (addForm.endAt.setSeconds(0), addForm.endAt.setMilliseconds(0))
  //   ) {
  //     SnackbarUtils.error(
  //       props.t('snackbar.cannotBeEqual'),
  //       'bottomCenter',
  //       3000
  //     ).then((notification) => {
  //       props.publishNotification(notification);
  //     });
  //     return;
  //   }
  //   if (
  //     Math.floor(date.getTime()) >
  //     (addForm.endAt.setSeconds(0), addForm.endAt.setMilliseconds(0))
  //   ) {
  //     SnackbarUtils.error(
  //       props.t('toaster.startDateLesser'),
  //       'bottomCenter',
  //       3000
  //     ).then((notification) => {
  //       props.publishNotification(notification);
  //     });
  //     return;
  //   }
  //   setAddForm({
  //     ...addForm,
  //     startAt: date,
  //   });
  // };

  const handleStartAtDateChange = (date) => {
    let current = new Date().setSeconds(0);
    let start = new Date(date).setSeconds(0);
    let end;

    if (start === current) {
      SnackbarUtils.error(
        props.t("snackbar.cannotBeEqual"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    if (new Date(start).getTime() >= end) {
      SnackbarUtils.error(
        props.t("toaster.startDateLesser"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    setAddForm({
      ...addForm,
      startAt: start,
    });
   };
  const handleEndAtDateChange = (date) => {
    let current = new Date().setSeconds(0);
    let end = new Date(date).setSeconds(59);
    let start;
    if (start === current) {
      SnackbarUtils.error(
        props.t("snackbar.cannotBeEqual"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    if (new Date(end).getTime() <= start) {
      SnackbarUtils.error(
        props.t("toaster.endDateLesser"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return;
    }
    setAddForm({
      ...addForm,
      endAt: end,
    });
  };
  // const handleEndAtDateChange = (date) => {
  //   if (
  //     Math.floor(date.getTime()) ===
  //     (addForm.startAt.setSeconds(0), addForm.startAt.setMilliseconds(0))
  //   ) {
  //     SnackbarUtils.error(
  //       props.t('snackbar.cannotBeEqual'),
  //       'bottomCenter',
  //       3000
  //     ).then((notification) => {
  //       props.publishNotification(notification);
  //     });
  //     return;
  //   }
  //   if (
  //     Math.floor(date.getTime()) <
  //     (addForm.startAt.setSeconds(0), addForm.startAt.setMilliseconds(0))
  //   ) {
  //     SnackbarUtils.error(
  //       props.t('toaster.endDateLesser'),
  //       'bottomCenter',
  //       3000
  //     ).then((notification) => {
  //       props.publishNotification(notification);
  //     });
  //     return;
  //   }
  //   setAddForm({
  //     ...addForm,
  //     endAt: date,
  //   });
  // };

  return (
    <div className={classes.root}>
      {/* {console.log("krishna", addForm?.lat, addForm?.lng, addForm)} */}

      <Grid container style={{ height: "100%" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
                style={{ marginTop:"8px" ,padding:"10px"}}
              >
                <TextField
                  variant="outlined"
                  id="title"
                  label={props.t("advertisements.title")}
                  autoComplete="off"
                  required
                  value={addForm.title}
                  fullWidth
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      title: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                />
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                // className={classes.formGrid}
                style={{ marginTop:"8px",padding:"10px" }}
              >
                <TextField
                  variant="outlined"
                  label={props.t("advertisements.typeoflink")}
                  select
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  id="typeOfLink"
                  defaultValue={addForm.typeOfLink}
                  value={addForm.typeOfLink}
                  required
                  fullWidth
                  onChange={(event) => {
                    setAddForm({
                      ...addForm,
                      typeOfLink: event.target.value,
                      category: "",
                      products: [],
                      // searchKey: [],
                      siteLink: "",
                      images: [],
                    });
                    setBannerListVal({
                      ...bannerListVal,
                      image: "",
                      siteLink: "",
                      // searchKey: [],
                      category: "",
                    });
                  }}
                >
                  

                  <MenuItem key={1} value={"banner"}>
                    {props.t("advertisements.link.banner")}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ marginTop:"8px",padding:"10px" }}
                // className={classes.formGrid}
              >
                <TextField
                  variant="outlined"
                  id="active"
                  label={props.t("advertisements.active")}
                  // className={classes.userTypeMenuItem}
                  select
                  required
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  fullWidth
                  defaultValue={addForm.active}
                  value={addForm.active}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      active: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={true}>
                    {props.t("advertisements.active")}
                  </MenuItem>
                  <MenuItem key={1} value={false}>
                    {props.t("advertisements.inactive")}
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                // className={classes.formGrid}
                style={{marginTop:"15px",padding:"10px"}}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={props.t("advertisements.startAt")}
                    id="startAt"
                    style={{width:"100%"}}
                    inputFormat="dd/MM/yyyy"
                    value={
                      addForm.startAt !== null
                        ? new Date(addForm.startAt * 1000)
                        : null
                    }
                    minDate={
                      props.formDialog.divType === "new" ? new Date() : null
                    }
                    maxDate={
                      props.formDialog.divType === "new"
                        ? new Date(addForm.endAt * 1000)
                        : null
                    }
                    onChange={(event) =>
                      setAddForm({
                        ...addForm,
                        startAt: Math.floor(
                          new Date(
                            new Date(new Date(event).setHours(0)).setMinutes(0)
                          ).setSeconds(0) / 1000
                        ),
                      })
                    }
                    renderInput={(params) => <TextField
                      variant="outlined"
                      fullWidth
                      // style={{marginTop:"10px"}}
                      {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                // className={classes.formGrid}
                style={{marginTop:"15px",padding:"10px"}}

              >
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDateTimePicker
                      animateYearScrolling={false}
                      // disableFuture={true}
                      // disablePast={true}
                      variant="dialog"
                      required
                      fullWidth
                      disabled={
                        props.formDialog.divType === "new" ||
                        props.formDialog.divType === "edit"
                          ? false
                          : true
                      }
                      format="dd/MM/yyyy hh:mm a"
                      margin="normal"
                      id="endAt"
                      label={props.t("advertisements.endAt")}
                      value={addForm.endAt}
                      // onChange={(date) => {
                      //    handleEndAtDateChange(date);
                      //   // setAddForm({
                      //   //   ...addForm,
                      //   //   endAt: event.target.value,
                      //   // });
                      // }}
                      onChange={(event) =>
                        setAddForm({
                          ...addForm,
                          endAt: Math.floor(
                            new Date(
                              new Date(new Date(event).setHours(23)).setMinutes(
                                59
                              )
                            ).setSeconds(59) / 1000
                          ),
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      minDate={
                        addForm.startAt !== null
                          ? new Date(addForm.startAt)
                          : new Date(0)
                      }
                    />
                  </Grid>
                </MuiPickersUtilsProvider> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={props.t("advertisements.endAt")}
                    id="endAt"
                    inputFormat="dd/MM/yyyy"
                    value={
                      addForm.endAt !== null
                        ? new Date(addForm.endAt * 1000)
                        : null
                    }
                    minDate={
                      props.formDialog.divType === "new"
                        ? new Date(addForm.startAt * 1000)
                        : null
                    }
                    onChange={(event) =>
                      setAddForm({
                        ...addForm,
                        endAt: Math.floor(
                          new Date(
                            new Date(new Date(event).setHours(23)).setMinutes(
                              59
                            )
                          ).setSeconds(59) / 1000
                        ),
                      })
                    }
                    renderInput={(params) => <TextField
                      variant="outlined"
                      fullWidth
                      // style={{marginTop:"10px"}}
                      {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              {/* <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: '13px' }}
                className={classes.formGrid}
              >
                <TextField
                  id='priority'
                  label={props.t('advertisements.priority')}
                  // className={classes.userTypeMenuItem}
                  select
                  disabled={
                    props.formDialog.divType === 'new' ||
                    props.formDialog.divType === 'edit'
                      ? false
                      : true
                  }
                  required
                  fullWidth
                  defaultValue={addForm.priority}
                  value={addForm.priority}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      priority: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={'low'}>
                    {props.t('advertisements.low')}
                  </MenuItem>
                  <MenuItem key={1} value={'normal'}>
                    {props.t('advertisements.normal')}
                  </MenuItem>
                  <MenuItem key={2} value={'high'}>
                    {props.t('advertisements.high')}
                  </MenuItem>
                </TextField>
              </Grid>
            */}

              {/* <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                item
                style={{ marginTop: "13px" }}
                className={classes.formGrid}
              >
                <TextField
                  variant="outlined"
                  id="priority"
                  label={props.t("advertisements.priority")}
                  // className={classes.userTypeMenuItem}
                  select
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  required
                  fullWidth
                  defaultValue={addForm.priority}
                  value={addForm.priority}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      priority: event.target.value,
                    })
                  }
                >
                  <MenuItem key={0} value={"low"}>
                    {props.t("advertisements.low")}
                  </MenuItem>
                  <MenuItem key={1} value={"normal"}>
                    {props.t("advertisements.normal")}
                  </MenuItem>
                  <MenuItem key={2} value={"high"}>
                    {props.t("advertisements.high")}
                  </MenuItem>
                </TextField>
              </Grid> */}

            

              {/* <Grid
                item
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className={classes.formGrid}
                style={{ marginTop:"8px" }}
              >
                <TextField
                  id="adPosition"
                  variant="outlined"
                  label={props.t("advertisements.position")}
                  // className={classes.userTypeMenuItem}
                  select
                  required
                  fullWidth
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                  defaultValue={addForm.adPosition}
                  value={addForm.adPosition}
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      adPosition: event.target.value,
                    })
                  }
                >
                  {adPositionOptions.map((options, index) => (
                    <MenuItem key={index} value={options.value}>
                      {options.display}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}

             
             

              {/* <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop:"8px" }}
                className={classes.formGrid}
              >
                <TextField
                  variant="outlined"
                  id="lat"
                  label={props.t("advertisements.latitude")}
                  autoComplete="off"
                  multiline
                  // rows={1}
                  // required
                  fullWidth
                  // value={addForm?.location?.coordinates[0]}
                  value={addForm?.lat}
                  // value1={console.log(addForm.location.coordinates[0])}
                  // onChange={
                  //   (e) => {

                  //     setAddForm({
                  //       ...addForm,
                  //     lat: event.target.value,
                  //     });
                  //   }
                  //   // console.log(event.target.value)
                  // }
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      lat: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                />
              </Grid>

              <Grid
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop:"8px" }}
                className={classes.formGrid}
              >
                <TextField
                  variant="outlined"
                  id="lng"
                  label={props.t("advertisements.longitude")}
                  autoComplete="off"
                  multiline
                  // rows={1}
                  // required
                  fullWidth
                  value={addForm?.lng}
              
                  onChange={(event) =>
                    setAddForm({
                      ...addForm,
                      lng: event.target.value,
                    })
                  }
                  disabled={
                    props.formDialog.divType === "new" ||
                    props.formDialog.divType === "edit"
                      ? false
                      : true
                  }
                />
              </Grid> */}


              <Grid xl={6} lg={6} md={12} sm={12} xs={12}
              style={{marginTop:"8px",padding:"10px"}}
              >
                     <TextField
                       label={props.t('advertisements.siteLink')}
                       variant="outlined"
                       id='siteLink'
                       fullWidth
                       required
                       value={bannerListVal.siteLink}
                       onChange={(event) =>
                         setBannerListVal({
                           ...bannerListVal,
                           siteLink: event.target.value,
                         })
                       }
                       disabled={
                         props.formDialog.divType === 'new' ||
                         props.formDialog.divType === 'edit'
                           ? false
                           : true
                       }
                       autoComplete='off'
                     />
                   </Grid>


            </Grid>
            
            {addForm?.typeOfLink === "banner" ? (
              <Stack style={{ padding: "10px" }}>
                <Stack
                  flexDirection={"row"}
                  style={{ justifyContent: "space-between" }}
                >
                  <Stack style={{ marginTop: "10px" }}>
                    <Typography style={{ fontSize: "20px", fontWeight: 600 }}>
                      Upload Ads
                    </Typography>
                  </Stack>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    //   className={classes.input}
                    id="icon-button-file"
                    type="file"
                    onChange={imageupload}
                  />
                  <label htmlFor="icon-button-file">
                    <Button
                      // variant="outlined"
                      // className={classes.button}
                      style={{ fontFamily: "Open Sans" }}
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                </Stack>
                <Grid container>
                  {addForm?.bannerList?.map((banners, ind) => (
                    <>
                      {console.log(banners)}

                      <Grid
                        xl={4}
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        style={{ flexDirection: "row", position: "relative" }}
                      >
                        <img
                          sx={
                            {
                              // height: "100px",
                              // marginRight: "-50px",
                            }
                          }
                          style={{ objectFit: "contain" }}
                          src={banners?.image}
                          alt="Uploaded"
                          height={300}
                          width={300}
                          onClick={(e) => window.open(e.target.src, "_blank")}
                        ></img>

                        <Stack style={{ flexDirection: "column" }}>
                          <Stack
                            style={{
                              zIndex: 12,
                              position: "absolute",
                              top: "190px",
                              left: "15px",
                            }}
                            onClick={() => handleDeleteVideo(ind)}
                          >
                            <Typography
                              style={{
                                fontSize: "16px",
                                color: "black",
                                fontFamily: "Open Sans",
                                fontWeight: 400,
                                marginLeft: "5px",
                              }}
                            >
                              Delete
                            </Typography>
                          </Stack>
                          <Stack
                            style={{
                              width: "141px",
                              height: "36px",
                              borderRadius: "8px",
                              zIndex: 12,
                              position: "absolute",
                              top: "190px",
                              left: "140px",
                            }}
                          >
                            <button
                              style={{
                                width:"100%",
                                background: "white",
                                border: "none",
                                fontFamily: "Open Sans",
                                fontWeight: 600,
                                fontSize: "16px",
                                borderRadius: "8px",

                              }}
                              onClick={(e) => window.open(banners?.image, "_blank")}


                            >
                              Watch Preview
                            </button>
                          </Stack>
                        </Stack>
                      </Grid>
                      {/* <Grid
                        xl={4}
                        lg={4}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ margin: "40px 0px", height: "100px" }}
                      >
<p>
<a href={banners?.mediaUrl} target="_blank">Uploaded Video</a>
</p>
                      </Grid> */}
                    </>
                  ))}
                </Grid>
              </Stack>
            ) : (
              <></>
            )}
          </Paper>
        </Grid>
        
      </Grid>

      <FileUpload
        ref={galleryFileUpload}
        aws={aws}
        file={file}
        base64={galleryFileBase64}
        bucket={aws.advertisementBucket}
        callback={_s3GalleryImageUrl}
      />
      <FileUpload
        ref={bannerFileUpload}
        aws={aws}
        file={file}
        base64={attachmentsFileBase64}
        bucket={aws.advertisementBucket}
        callback={_s3BannerImageUrl}
      />
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(AEVForm)
);
