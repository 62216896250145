import React from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  MenuItem,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import LocalStorage from "../../../../config/LocalStorage";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TableQuery(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.innerPadding}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label={props.t("query.activeStatus")}
            select
            defaultValue={props.query.active}
            value={props.query.active}
            onChange={(event) =>
              props.setQuery({ ...props.query, active: event.target.value })
            }
            style={{
              width: "auto",
              minWidth: "90%",
              marginBottom: 10,
              color: "#000 !important",
            }}
          >
            <MenuItem key={0} value={true}>
              {props.t("role.active")}
            </MenuItem>
            <MenuItem key={1} value={false}>
              {props.t("role.inactive")}
            </MenuItem>
          </TextField>
        </Grid>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            label={"Post Status"}
            select
            defaultValue={props.query.postStatus}
            value={props.query.postStatus}
            onChange={(event) =>
              props.setQuery({ ...props.query, postStatus: event.target.value })
            }
            style={{
              width: "auto",
              minWidth: "90%",
              marginBottom: 10,
              color: "#000 !important",
            }}
          >
            <MenuItem key={0} value={false}>
              {"Temporary"}
            </MenuItem>
            <MenuItem key={0} value={"all"}>
              {"All"}
            </MenuItem>
            <MenuItem key={1} value={true}>
              {"Permanent"}
            </MenuItem>
          </TextField>
        </Grid>

        <Grid>
          {/* <TextField
          label={props.t("query.userType")}
          select
          defaultValue={props.query.userType}
          value={props.query.userType}
          onChange={(event) =>
            props.setQuery({
              ...props.query,
              userType: event.target.value,
            })
          }
          style={{ width: "auto", minWidth: "90%", marginBottom: 10 }}
        >
          <MenuItem key={0} value={"all"}>
            {props.t("query.all")}
          </MenuItem>
          <MenuItem key={1} value={"member"}>
            {props.t("user.member")}
          </MenuItem>
          <MenuItem key={2} value={"admin"}>
            {props.t("user.admin")}
          </MenuItem>
          <MenuItem key={3} value={"shop"}>
            {props.t("user.shop")}
          </MenuItem>
          
        </TextField> */}
          <TextField
            label={props.t("query.sector")}
            select
            defaultValue={props.query.sector}
            value={props.query.sector}
            onChange={(event) =>
              props.setQuery({
                ...props.query,
                sector: event.target.value,
              })
            }
            style={{
              width: "auto",
              minWidth: "90%",
              marginBottom: 10,
              color: "#000 !important",
            }}
          >
            <MenuItem key={0} value={"all"}>
              {props.t("query.all")}
            </MenuItem>
            <MenuItem key={0} value={"handloom"}>
              {props.t("user.handloom")}
            </MenuItem>
            <MenuItem key={1} value={"powerloom"}>
              {props.t("user.powerloom")}
            </MenuItem>
          </TextField>
          {/* <TextField
          label={props.t("query.status")}
          select
          defaultValue={props.query.status}
          value={props.query.status}
          onChange={(event) =>
            props.setQuery({
              ...props.query,
              status: event.target.value,
            })
          }
          style={{ width: "auto", minWidth: "90%", marginBottom: 10 }}
        >
                    <MenuItem key={0} value={"all"}>
            {"All"}
          </MenuItem>
          <MenuItem key={1} value={"pending"}>
            {"Pending"}
          </MenuItem>
          <MenuItem key={2} value={"approve"}>
            {"Approve"}
          </MenuItem>
          <MenuItem key={3} value={"blocked"}>
            {"Blocked"}
          </MenuItem>
          <MenuItem key={4} value={"rejected"}>
            {"Rejected"}
          </MenuItem>
          <MenuItem key={5} value={"reported"}>
            {"Reported"}
          </MenuItem>
          
          
        </TextField> */}
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="startDate"
                fullWidth
                maxDate={
                  props.query.endDate !== null
                    ? new Date(props.query.endDate * 1000)
                    : new Date()
                }
                label={props.t("query.startFrom")}
                format="dd/MM/yyyy"
                // defaultValue={props.query.startDate !== null ? new Date(props.query.startDate * 1000) : null}
                defaultValue={
                  props.query.startDate !== null
                    ? new Date(props.query.startDate * 1000)
                    : null
                }
                value={
                  props.query.startDate !== null
                    ? new Date(props.query.startDate * 1000)
                    : null
                }
                style={{ marginTop: "-0px", maxWidth: 200 }}
                // onChange={(event) =>
                //   props.setQuery({
                //     ...props.query,
                //     startDate: Math.floor(new Date(event).getTime() / 1000.0),
                //   })
                // }

                onChange={(date) =>
                  props.setQuery({
                    ...props.query,
                    startDate: Math.floor(
                      new Date(
                        new Date(new Date(date).setHours(0)).setMinutes(0)
                      ).setSeconds(0) / 1000
                    ),
                  })
                }
                // InputProps={{
                //   disabled: true,
                // }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} className="querydate">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="endDate"
                fullWidth
                minDate={
                  props.query.startDate !== null
                    ? new Date(props.query.startDate * 1000)
                    : new Date(0)
                }
                label={props.t("query.endBefore")}
                format="dd/MM/yyyy"
                defaultValue={
                  props.query.endDate !== null
                    ? new Date(props.query.endDate * 1000)
                    : null
                }
                value={
                  props.query.endDate !== null
                    ? new Date(props.query.endDate * 1000)
                    : null
                }
                // value={new Date}
                style={{ marginTop: "-0px", maxWidth: 200 }}
                // onChange={(event) =>
                //   props.setQuery({
                //     ...props.query,
                //     endDate: Math.floor(new Date(event).getTime() / 1000.0),
                //   })
                // }
                onChange={(date) =>
                  props.setQuery({
                    ...props.query,
                    endDate: Math.floor(
                      new Date(
                        new Date(new Date(date).setHours(23)).setMinutes(59)
                      ).setSeconds(59) / 1000
                    ),
                  })
                }
                // InputProps={{
                //   disabled: true,
                // }}

                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default withTranslation("translations")(TableQuery);
