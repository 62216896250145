import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  TextareaAutosize,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { Stack } from "@mui/material";
import landingImg from "../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
import "./dashboard.scss";
import Cash from "../../../../assets/images/Cash.png";
import Enquiry from "../../../../assets/images/Enquiry.png";
import Revenue from "../../../../assets/images/Fee.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";
const TotalSubscriptions = (props) => {
  const myDate=new Date()
  const [value, setValue] = React.useState(dayjs(new Date()));
  const [startDate, setStartDate] = useState(myDate.setDate(-10));
  const [endDate, setEndDate] = useState(new Date());
  const [subscription, setSubscription] = useState([]);
  // console.log(startDate, "startDate");
  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };
  // console.log(startDate, "startDate");
  const handelstarDate = (newValue) => {
    setStartDate(newValue);
  };
  const handelendDate = (newValue) => {
    setEndDate(newValue);
  };
  const TotalSubscriptions = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.subscriptions +
        "?startDate=" +
        `${new Date(startDate) / 1000}` +
        "&" +
        "endDate=" +
        `${new Date(endDate) / 1000}`,
      ""
    ).then((response) => {
      let userResult = response?.data?.result;
      // setGlobalCount(userResult)
      setSubscription(userResult);
      // console.log(response);
    });
  };
  // console.log(subscription, "subscription");
  useEffect(() => {
    TotalSubscriptions();
  }, [{ startDate, endDate }]);

  return (
    <div >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Stack mt={1}>
            <Typography className="form">From</Typography>
            <Stack
              style={{
                // width: "130px",
                // height: "40px",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                padding: "10px",
                borderRadius: "10px",
              }}
              className="calaneder"

            >
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // label="Basic example"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  //  onChange={handelstarDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider> */}

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{
                  width: 'auto',
                  minWidth: '100%',
                }}
                margin='normal'
                disableFuture={true}
                autoOk={true}
                id='startAt'
                //  label={"End Date"}
                format='dd/MM/yyyy'
                value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                maxDate={
                  endDate !== null
                    ? new Date(endDate)
                    : new Date()
                }              />
            </MuiPickersUtilsProvider>
            </Stack>
          </Stack>
        </Grid>
        {/* <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
          <Stack
            style={{
              marginTop: "60px",
              fontSize: "16px",
              fontWeight: 600,
              marginLeft: "10px",
            }}
          >
            -
          </Stack>
        </Grid> */}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Stack mt={1}>
            <Typography className="form">To</Typography>
            <Stack
              style={{
                // width: "130px",
                // height: "40px",
                borderRadius: "10px",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                padding: "10px",
              }}
              className="calaneder"

            >
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // label="Basic example"
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  // onChange={handelendDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider> */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{
                  width: 'auto',
                  minWidth: '100%',
                }}
                margin='normal'
                disableFuture={true}
                autoOk={true}
                id='endAt'
                //  label={"End Date"}
                format='dd/MM/yyyy'
                value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  minDate={
                    startDate !== null
                      ? new Date(startDate )
                      : new Date(0)
                  } 
                                 KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Stack
        style={{
         paddingTop:"8px",
          width: "80px",
          height: "80px",
          margin: "auto",
        }}
      >
        <img src={Cash} alt="subscriptions" />
      </Stack>
      <Stack>
        <Typography className="subcount">₹{subscription}</Typography>
      </Stack>
      <Stack>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19px",
            color: "#000000",
            margin: "auto",
          }}
        >
          ₹ {subscription} Subscribers
        </Typography>
      </Stack>
    </div>
  );
};

export default TotalSubscriptions;
