/* eslint-disable array-callback-return */
import React from 'react';
import Toaster from './Toaster';
import Validation from './Validation';
import { Translation } from 'react-i18next';
const FormValidation = {
  validation: async function (fieldList, state) {
    
    let emptyField = true;
    fieldList.map((data) => {
      {console.log(data,"data")}
      try {
        state[data] = state[data].trim();
      } catch (err) {}

      if (
        state[data] === undefined ||
        state[data] === null ||
        state[data] === '' ||
        state[data].length === 0 ||
        (/email/gi.test(data) && !Validation.emailValidation(state[data])) ||
        (/emailId/gi.test(data) && !Validation.emailValidation(state[data])) ||
        (/url/gi.test(data) && !Validation.urlValidation(state[data])) ||
        (/website/gi.test(data) && !Validation.urlValidation(state[data]))
      ) {
        let classList = document
          .getElementById(data)
          .parentElement.classList.value.split(' ');
          console.log("classlist",classList)
        let flag = false;
        classList.map((data) => {
          if (data === 'MuiInput-underline') flag = true;
          return null;
        });
        if (flag) {
          document.getElementById(data).parentElement.classList.add('medentry');
        } else {
          document.getElementById(data).classList.add('medentry');
        }

        emptyField = false;
      }
      return '';
    });
    if (!emptyField) {
      Toaster.error(
        <Translation ns='translations'>
          {(t, { i18n }) => (
            <div
              style={{
                textAlign: 'center',
              }}
              className={'pl-2'}
            >
              {t('toaster.fillup_mandatory_fields')}
            </div>
          )}
        </Translation>,
        'topRight'
      );
    }
    return emptyField;
  },
};
export default FormValidation;
