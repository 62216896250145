import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  TextField,
  Typography,
  Tooltip,
  Hidden,
  Button,
  Avatar,
  IconButton,
  Card,
  CardContent,
  InputAdornment,
  MenuItem,
  Paper,
} from '@material-ui/core';
import { IoIosInformationCircle } from 'react-icons/io';
import { MdDeleteSweep, MdCloudUpload } from 'react-icons/md';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';



import FileUpload from '../../../../templates/FileUpload';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FileUploadTableCheckBox from '../../../../templates/FileUploadTableCheckBox';
import LocalStorage from '../../../../config/LocalStorage';
import DateFnsUtils from '@date-io/date-fns';
import ChipInput from 'material-ui-chip-input';
import APIRequest from '../../../../utils/APIRequest';
import FormValidation from '../../../../utils/FormValidation';
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from 'react-redux';
import SnackbarUtils from '../../../../utils/SnackbarUtils';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingLeft: 10,
    paddingRigt: 10,
  },
  paper: {
    padding: 10,
    margin: 10,
    height: '100%',
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: '-5%',
    position: 'relative',
    left: '42%',
  },
  userTypeMenuItem: {
    minWidth: '50%',
    marginTop: '6px',
  },
  formGrid: {
    marginBottom: 10,
    paddingLeft: 10,
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));
const userFormDetails = {
  doctorId: '',
  category: 'Book Appointment', //dropdown
  //video consultation(videoConsultation)/ book appointment
  operationSlots: [
    {
      day: 0,
      timeslots: [
        {
          shift: 'Morning',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Afternoon',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Evening',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Night',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
      ],
    },
    {
      day: 1,
      timeslots: [
        {
          shift: 'Morning',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Afternoon',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Evening',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Night',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
      ],
    },
    {
      day: 2,
      timeslots: [
        {
          shift: 'Morning',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Afternoon',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Evening',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Night',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
      ],
    },
    {
      day: 3,
      timeslots: [
        {
          shift: 'Morning',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Afternoon',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Evening',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Night',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
      ],
    },
    {
      day: 4,
      timeslots: [
        {
          shift: 'Morning',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Afternoon',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Evening',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Night',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
      ],
    },
    {
      day: 5,
      timeslots: [
        {
          shift: 'Morning',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Afternoon',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Evening',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Night',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
      ],
    },
    {
      day: 6,
      timeslots: [
        {
          shift: 'Morning',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Afternoon',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Evening',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
        {
          shift: 'Night',
          availableIn: '', //clinicId
          slot: [], //09:00AM
          isHoliday: false,
        },
      ],
    },
  ],
};

function AEVForm(props) {
  const classes = useStyles();
  const [userForm, setUserForm] = useState(userFormDetails);
  const [dayOptions, setDayOptions] = useState([
    { display: props.t('clinic.sunday'), value: 0 },
    { display: props.t('clinic.monday'), value: 1 },
    { display: props.t('clinic.tuesday'), value: 2 },
    { display: props.t('clinic.wednesday'), value: 3 },
    { display: props.t('clinic.thursday'), value: 4 },
    { display: props.t('clinic.friday'), value: 5 },
    { display: props.t('clinic.saturday'), value: 6 },
  ]);
  const [morningSlotOptions, setMorningSlotOptions] = useState([]);
  const [afternoonSlotOptions, setAfternoonSlotOptions] = useState([]);
  const [eveningSlotOptions, setEveningSlotOptions] = useState([]);
  const [nightSlotOptions, setNightSlotOptions] = useState([]);
  const [availableInOptions, setAvailableInOptions] = useState([]);
  const [tabValues, setTabValues] = useState([0, 0, 0, 0, 0, 0, 0]);
  useEffect(() => {
    getMorningSlotOptions();
    getAfternoonSlotOptions();
    getEveningSlotOptions();
    getNightSlotOptions();
    if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === 'submit'
    ) {
      sendToServer();
    } else if (
      props.formDialog.functionName !== undefined &&
      props.formDialog.functionName !== null &&
      props.formDialog.functionName === 'reset'
    ) {
      resetForm();
    }
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== '' &&
      props.formDialog.divType !== 'new' &&
      props.formDialog.functionName === ''
    ) {
      getClinicIds();
      getEditable();
    }
  }, [props.formDialog]);

  const handleTabChange = (event, newValue, index) => {
    tabValues[index] = newValue;
    // tabValues = [
    //   newValue,
    //   newValue,
    //   newValue,
    //   newValue,
    //   newValue,
    //   newValue,
    //   newValue,
    // ];

    setTabValues([...tabValues]);
  };
  const getClinicIds = () => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.clinicDetailsBasedOnDoctor,
      JSON.stringify({ doctorId: props.rowDetails._id })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setAvailableInOptions(response.data.clinic);
        }
      }
    });
  };

  const getMorningSlotOptions = () => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.getOptions,
      JSON.stringify({ name: 'morningSlots' })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setMorningSlotOptions(response.data.options[0].options);
          }
        }
      }
    });
  };

  const handleMorningSlots = (value, tabIndex, index) => {
    let findIndex = userForm.operationSlots[index].timeslots[
      tabIndex
    ].slot.findIndex((x) => x === value);

    if (findIndex === -1) {
      userForm.operationSlots[index].timeslots[tabIndex].slot.push(value);
    } else {
      userForm.operationSlots[index].timeslots[tabIndex].slot.splice(
        findIndex,
        1
      );
    }

    setUserForm({ ...userForm });
  };
  const getAfternoonSlotOptions = () => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.getOptions,
      JSON.stringify({ name: 'afternoonSlots' })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setAfternoonSlotOptions(response.data.options[0].options);
          }
        }
      }
    });
  };
  const getEveningSlotOptions = () => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.getOptions,
      JSON.stringify({ name: 'eveningSlots' })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setEveningSlotOptions(response.data.options[0].options);
          }
        }
      }
    });
  };
  const getNightSlotOptions = () => {
    APIRequest.request(
      'POST',
      ConfigAPIURL.getOptions,
      JSON.stringify({ name: 'nightSlots' })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.data.responseCode !== undefined &&
          response.data.responseCode !== null
        ) {
          if (response.data.responseCode === 109) {
            setNightSlotOptions(response.data.options[0].options);
          }
        }
      }
    });
  };

  const resetForm = () => {
    setUserForm(userFormDetails);
  };

  const getEditable = () => {
    if (
      props.rowDetails !== undefined &&
      props.rowDetails !== null &&
      props.formDialog.divType !== 'new'
    ) {
      APIRequest.request(
        'POST',
        ConfigAPIURL.slotDetails,
        JSON.stringify({ recordId: props.rowDetails._id })
      ).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            if (response.data.result !== null) {
              setUserForm(response.data.result);
            } else {
              setUserForm(userFormDetails);
            }
          }
        }
      });
    }
  };

  //keyboard shortcut

  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case 'save':
        sendToServer();
        break;
      case 'reset':
        resetForm();
        break;
      default:
        break;
    }
    props.shortcutKey('');
  }, [props.shortcutKeyValue]);

  const sendToServer = () => {
    let url = ConfigAPIURL.createOrUpdateSlots;
    let method = 'POST';

    if (
      props.formDialog.divType === 'edit' ||
      props.formDialog.divType === 'view'
    ) {
      url = ConfigAPIURL.createOrUpdateSlots;
      userForm['doctorId'] = props.rowDetails._id;
      method = 'POST';
    }
    let fieldValidation = [];

    FormValidation.validation(fieldValidation, userForm).then(
      (ValidationResult) => {
        if (ValidationResult) {
          APIRequest.request(method, url, JSON.stringify(userForm)).then(
            (response) => {
              if (response !== undefined && response !== null) {
                if (
                  response.code === 100 &&
                  response.data.responseCode === 109
                ) {
                  SnackbarUtils.sucess(
                    props.t('snackbar.transComplete'),
                    'bottomCenter',
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                  props.handleFormDialog();
                  setUserForm(userFormDetails);
                }
                if (
                  response.code === 100 &&
                  response.data.responseCode === 114
                ) {
                  SnackbarUtils.error(
                    props.t('snackbar.dupliRecords'),
                    'bottomCenter',
                    3000
                  ).then((notification) => {
                    props.publishNotification(notification);
                  });
                }
              }
            }
          );
        }
      }
    );
  };

  // const handleSlots = (value, index) => {
  //   let findIndex = userForm.operationSlots[index].timeslots.findIndex(
  //     (x) => x.slot === value
  //   );

  //   if (findIndex === -1) {
  //     userForm.operationSlots[index].timeslots.push({ slot: value });
  //   } else {
  //     userForm.operationSlots[index].timeslots.splice(findIndex, 1);
  //   }

  //   setUserForm({ ...userForm });
  // };

  return (
    <div className={classes.root} style={{ marginTop: props.marginTop }}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12} align='left'>
                {props.t('doctor.name')} :{' '}
                {props.rowDetails !== undefined && props.rowDetails !== null
                  ? props.rowDetails.name
                  : ''}
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.formGrid}
                align='right'
              >
                <TextField
                  label={props.t('doctor.category')}
                  select
                  style={{ textAlign: 'left' }}
                  defaultValue={userForm.category}
                  value={userForm.category}
                  onChange={(event) =>
                    setUserForm({
                      ...userForm,
                      category: event.target.value,
                    })
                  }
                  fullWidth
                >
                  <MenuItem key={0} value={'Video Consultation'}>
                    {props.t('doctor.videoConsultation')}
                  </MenuItem>
                  <MenuItem key={1} value={'Book Appointment'}>
                    {props.t('doctor.bookAppointment')}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {userForm.operationSlots.map((operations, index) => (
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      margin: '15px',
                      padding: '15px',
                      border: '5px solid black',
                      borderRadius: 10,
                    }}
                    className={classes.formGrid}
                  >
                    <Grid container>
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={3}
                        sm={5}
                        xs={5}
                        align='left'
                      >
                        <Avatar>{Number(operations.day) + 1}</Avatar>
                      </Grid>
                      <Grid
                        item
                        xl={11}
                        lg={11}
                        md={9}
                        sm={7}
                        xs={7}
                        align='left'
                      >
                        <Typography gutterBottom variant='p' component='p'>
                          {dayOptions[index].display}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ marginTop: '15px' }}
                      >
                        <AppBar position='static'>
                          <Tabs
                            value={tabValues[index]}
                            onChange={(event, value) =>
                              handleTabChange(event, value, index)
                            }
                            variant='scrollable'
                            scrollButtons='on'
                            aria-label='scrollable force tabs example'
                          >
                            {userForm.operationSlots[index].timeslots.map(
                              (slots, sIndex) => (
                                <Tab
                                  label={slots.shift}
                                  {...a11yProps(index)}
                                />
                              )
                            )}
                          </Tabs>
                        </AppBar>
                        <TabPanel value={tabValues[index]} index={0}>
                          <Grid container>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <TextField
                                id={index + 'isHoliday'}
                                label={props.t('clinic.isHoliday')}
                                select
                                fullWidth
                                onChange={(event) => {
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].isHoliday = event.target.value;
                                  if (event.target.value === true) {
                                    userForm.operationSlots[index].timeslots[
                                      tabValues[index]
                                    ].slot = [];
                                  }
                                  setUserForm({
                                    ...userForm,
                                  });
                                }}
                                value={
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].isHoliday
                                }
                                defaultValue={
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].isHoliday
                                }
                              >
                                <MenuItem key={0} value={true}>
                                  {props.t('clinic.yes')}
                                </MenuItem>
                                <MenuItem key={1} value={false}>
                                  {props.t('clinic.no')}
                                </MenuItem>
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <Autocomplete
                                id={index + 'availableIn'}
                                disabled={
                                  props.formDialog.divType === 'new' ||
                                  props.formDialog.divType === 'edit'
                                    ? false
                                    : true
                                }
                                options={availableInOptions}
                                getOptionLabel={(option) =>
                                  option.name !== undefined
                                    ? option.name + ' ' + option.township
                                    : ''
                                }
                                onChange={(event, data) => {
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].availableIn = data;
                                  setUserForm({
                                    ...userForm,
                                  });
                                }}
                                value={
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].availableIn
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label={props.t('doctor.clinics')}
                                    variant='standard'
                                  />
                                )}
                              />
                            </Grid>
                            {userForm.operationSlots[index].timeslots[
                              tabValues[index]
                            ].isHoliday === false && (
                              <>
                                {morningSlotOptions.map((slots, sIndex) => (
                                  <Grid
                                    item
                                    xl={1}
                                    lg={1}
                                    md={3}
                                    sm={6}
                                    xs={6}
                                    key={sIndex}
                                    style={{ width: '98%' }}
                                  >
                                    <Card
                                      style={{
                                        margin: 2,
                                        backgroundColor: !!userForm.operationSlots[
                                          index
                                        ].timeslots[tabValues[index]].slot.find(
                                          (el) => el == slots.display
                                        )
                                          ? '#76b852'
                                          : 'unset',

                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        handleMorningSlots(
                                          slots.display,
                                          tabValues[index],
                                          index
                                        )
                                      }
                                    >
                                      <CardContent align='center'>
                                        {slots.display}
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                ))}
                              </>
                            )}
                          </Grid>
                        </TabPanel>
                        <TabPanel value={tabValues[index]} index={1}>
                          <Grid container>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <TextField
                                id={index + 'isHoliday'}
                                label={props.t('clinic.isHoliday')}
                                select
                                fullWidth
                                onChange={(event) => {
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].isHoliday = event.target.value;
                                  if (event.target.value === true) {
                                    userForm.operationSlots[index].timeslots[
                                      tabValues[index]
                                    ].slot = [];
                                  }
                                  setUserForm({
                                    ...userForm,
                                  });
                                }}
                                value={
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].isHoliday
                                }
                                defaultValue={
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].isHoliday
                                }
                              >
                                <MenuItem key={0} value={true}>
                                  {props.t('clinic.yes')}
                                </MenuItem>
                                <MenuItem key={1} value={false}>
                                  {props.t('clinic.no')}
                                </MenuItem>
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <Autocomplete
                                id={index + 'availableIn'}
                                disabled={
                                  props.formDialog.divType === 'new' ||
                                  props.formDialog.divType === 'edit'
                                    ? false
                                    : true
                                }
                                options={availableInOptions}
                                getOptionLabel={(option) =>
                                  option.name !== undefined
                                    ? option.name + ' ' + option.township
                                    : ''
                                }
                                onChange={(event, data) => {
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].availableIn = data;
                                  setUserForm({
                                    ...userForm,
                                  });
                                }}
                                value={
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].availableIn
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label={props.t('doctor.clinics')}
                                    variant='standard'
                                  />
                                )}
                              />
                            </Grid>
                            {userForm.operationSlots[index].timeslots[
                              tabValues[index]
                            ].isHoliday === false && (
                              <>
                                {afternoonSlotOptions.map((slots, sIndex) => (
                                  <Grid
                                    item
                                    xl={1}
                                    lg={1}
                                    md={3}
                                    sm={6}
                                    xs={6}
                                    key={sIndex}
                                    style={{ width: '98%' }}
                                  >
                                    <Card
                                      style={{
                                        margin: 2,
                                        backgroundColor: !!userForm.operationSlots[
                                          index
                                        ].timeslots[tabValues[index]].slot.find(
                                          (el) => el == slots.display
                                        )
                                          ? '#76b852'
                                          : 'unset',

                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        handleMorningSlots(
                                          slots.display,
                                          tabValues[index],
                                          index
                                        )
                                      }
                                    >
                                      <CardContent align='center'>
                                        {slots.display}
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                ))}
                              </>
                            )}
                          </Grid>
                        </TabPanel>
                        <TabPanel value={tabValues[index]} index={2}>
                          <Grid container>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <TextField
                                id={index + 'isHoliday'}
                                label={props.t('clinic.isHoliday')}
                                select
                                fullWidth
                                onChange={(event) => {
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].isHoliday = event.target.value;
                                  if (event.target.value === true) {
                                    userForm.operationSlots[index].timeslots[
                                      tabValues[index]
                                    ].slot = [];
                                  }
                                  setUserForm({
                                    ...userForm,
                                  });
                                }}
                                value={
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].isHoliday
                                }
                                defaultValue={
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].isHoliday
                                }
                              >
                                <MenuItem key={0} value={true}>
                                  {props.t('clinic.yes')}
                                </MenuItem>
                                <MenuItem key={1} value={false}>
                                  {props.t('clinic.no')}
                                </MenuItem>
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <Autocomplete
                                id={index + 'availableIn'}
                                disabled={
                                  props.formDialog.divType === 'new' ||
                                  props.formDialog.divType === 'edit'
                                    ? false
                                    : true
                                }
                                options={availableInOptions}
                                getOptionLabel={(option) =>
                                  option.name !== undefined
                                    ? option.name + ' ' + option.township
                                    : ''
                                }
                                onChange={(event, data) => {
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].availableIn = data;
                                  setUserForm({
                                    ...userForm,
                                  });
                                }}
                                value={
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].availableIn
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label={props.t('doctor.clinics')}
                                    variant='standard'
                                  />
                                )}
                              />
                            </Grid>
                            {userForm.operationSlots[index].timeslots[
                              tabValues[index]
                            ].isHoliday === false && (
                              <>
                                {eveningSlotOptions.map((slots, sIndex) => (
                                  <Grid
                                    item
                                    xl={1}
                                    lg={1}
                                    md={3}
                                    sm={6}
                                    xs={6}
                                    key={sIndex}
                                    style={{ width: '98%' }}
                                  >
                                    <Card
                                      style={{
                                        margin: 2,
                                        backgroundColor: !!userForm.operationSlots[
                                          index
                                        ].timeslots[tabValues[index]].slot.find(
                                          (el) => el == slots.display
                                        )
                                          ? '#76b852'
                                          : 'unset',

                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        handleMorningSlots(
                                          slots.display,
                                          tabValues[index],
                                          index
                                        )
                                      }
                                    >
                                      <CardContent align='center'>
                                        {slots.display}
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                ))}
                              </>
                            )}
                          </Grid>
                        </TabPanel>
                        <TabPanel value={tabValues[index]} index={3}>
                          <Grid container>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <TextField
                                id={index + 'isHoliday'}
                                label={props.t('clinic.isHoliday')}
                                select
                                fullWidth
                                onChange={(event) => {
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].isHoliday = event.target.value;
                                  if (event.target.value === true) {
                                    userForm.operationSlots[index].timeslots[
                                      tabValues[index]
                                    ].slot = [];
                                  }
                                  setUserForm({
                                    ...userForm,
                                  });
                                }}
                                value={
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].isHoliday
                                }
                                defaultValue={
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].isHoliday
                                }
                              >
                                <MenuItem key={0} value={true}>
                                  {props.t('clinic.yes')}
                                </MenuItem>
                                <MenuItem key={1} value={false}>
                                  {props.t('clinic.no')}
                                </MenuItem>
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              className={classes.formGrid}
                            >
                              <Autocomplete
                                id={index + 'availableIn'}
                                disabled={
                                  props.formDialog.divType === 'new' ||
                                  props.formDialog.divType === 'edit'
                                    ? false
                                    : true
                                }
                                options={availableInOptions}
                                getOptionLabel={(option) =>
                                  option.name !== undefined
                                    ? option.name + ' ' + option.township
                                    : ''
                                }
                                onChange={(event, data) => {
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].availableIn = data;
                                  setUserForm({
                                    ...userForm,
                                  });
                                }}
                                value={
                                  userForm.operationSlots[index].timeslots[
                                    tabValues[index]
                                  ].availableIn
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label={props.t('doctor.clinics')}
                                    variant='standard'
                                  />
                                )}
                              />
                            </Grid>
                            {userForm.operationSlots[index].timeslots[
                              tabValues[index]
                            ].isHoliday === false && (
                              <>
                                {nightSlotOptions.map((slots, sIndex) => (
                                  <Grid
                                    item
                                    xl={1}
                                    lg={1}
                                    md={3}
                                    sm={6}
                                    xs={6}
                                    key={sIndex}
                                    style={{ width: '98%' }}
                                  >
                                    <Card
                                      style={{
                                        margin: 2,
                                        backgroundColor: !!userForm.operationSlots[
                                          index
                                        ].timeslots[tabValues[index]].slot.find(
                                          (el) => el == slots.display
                                        )
                                          ? '#76b852'
                                          : 'unset',

                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        handleMorningSlots(
                                          slots.display,
                                          tabValues[index],
                                          index
                                        )
                                      }
                                    >
                                      <CardContent align='center'>
                                        {slots.display}
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                ))}
                              </>
                            )}
                          </Grid>
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: 'SHORTCUTKEY', value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(mapStateToProps, mapDispachToProps)(AEVForm)
);
