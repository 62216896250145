import React from "react";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";

const useImageUpload = (setUserForm, userForm) => {
  const moveImagesToUserForm = (image) => {
    const existingImages = [...userForm.images];
    existingImages.push(image);
    setUserForm({
      ...userForm,
      images: existingImages,
    });
  };

  const onUpload = async (e) => {
    const formdata = new FormData();
    const file = e.target.files[0];
    formdata.append("attachment", file);
    formdata.append("bucketName", "profile");

    const response = await APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      formdata
    );
    if (response.data.responseCode === 109) {
      setTimeout(() => {
        moveImagesToUserForm(response?.data?.attachmentUrl[0]);
      }, 1500);
    }
  };

  const deleteImages = (ind) => {
    const data = [...userForm.images];
    data.splice(ind, 1);
    setUserForm({
      ...userForm,
      images: data,
    });
  };

  return { onUpload, deleteImages };
};

export default useImageUpload;
