import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ResponsiveDrawer from './Drawer_Appbar/ResponsiveDrawer';
import { makeStyles } from '@material-ui/core/styles';
import TopBar from './Drawer_Appbar/TopBar';
import AdminContentRouting from './AdminContentRouting';
import { connect } from 'react-redux';
import { Collapse, Drawer, Hidden, Snackbar } from '@material-ui/core';
import TranslateIcon from '@material-ui/icons/Translate';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import LanguageConfig from '../../../config/LanguageConfig';
import { Alert } from '@material-ui/lab';
import { useLocation } from 'react-router-dom';
import LocalStorage from '../../../config/LocalStorage';
const Mousetrap = require('mousetrap');
const useStyles = makeStyles((theme) => ({
  adminRoot: {
    display: 'flex',
    // zoom: '85%',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    position: 'relative',
    height: '100%',
    width: 'calc(100% - 250px)',
  },
  contentDesktop: {
    flexGrow: 1,
    padding: theme.spacing(1),
    position: 'relative',
    height: '100%',
  },
  background: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
    background: "#EDEDED",
  },
  backgroundColor: {
    height: '400px',
    backgroundImage: theme.custom.containerColor,
    backgroundAttachment: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    position: 'absolute',
  },
  backgroundImage: {
    left: '-2px',
    width: '100%',
    bottom: '-32px',
    position: 'absolute',
    height: 'auto',
    transform: 'scale(1.1,0.8)',
    transformOrigin: 'bottom',
  },
  backgroundImageMobile: {
    width: '100%',
    bottom: '-25px',
    position: 'absolute',
    height: 'auto',
    transformOrigin: 'bottom',
  },
  textLimit: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  menuStyle: {
    color: theme.custom.sideDrawer.menuColor,
  },
  drawerPaper: {
    height: 'auto',
    minHeight: 150,
    maxHeight: 300,
    overflowY: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },
}));

function Admin(props) {
  const classes = useStyles();
  const [languageList, setLanguageList] = React.useState([]);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [bottomDrawerOpen, setBottomDrawerOpen] = React.useState(false);
  const [pageName, setPageName] = React.useState('');
  const [menuStatus, setMenuStatus] = React.useState({ language: false });
  const location = useLocation();
  useEffect(() => {
    LanguageConfig.languageList().then((lngList) => {
      setLanguageList(lngList);
    });
    props.i18n.changeLanguage(props.languageData.code);
  }, []);
  //keyboard shortcut
  useEffect(() => {
    LocalStorage.adminKeyboardShortcut.map((data) => {
      return Mousetrap.bind(data.action, () =>
        props.shortcutKey(data.dispatchValue)
      );
    });
  }, []);
  // on location keyboard shortcut default
  useEffect(() => {
    props.shortcutKey('');
  }, [location]);

  const handleDrawerToggle = () => {
    props.sideDrawerChange(!mobileOpen);
    setMobileOpen(!mobileOpen);
  };
  const handleBottomDrawerOpen = () => {
    setMenuStatus({ language: false });
    setBottomDrawerOpen(!bottomDrawerOpen);
  };

  const languageChange = (data) => {
    props.i18n.changeLanguage(data.code);
    props.languageChange(data);
    localStorage.setItem('lng', JSON.stringify(data));
    handleBottomDrawerOpen();
  };
  const LanguageList = () => {
    return languageList.map((lng, lngIndex) => (
      <Collapse
        in={menuStatus['language'] ? menuStatus['language'] : false}
        timeout='auto'
        unmountOnExit
        key={lngIndex}
      >
        <List component='div' disablePadding>
          <ListItem
            button
            className={classes.nested}
            selected={
              lng.displayName === props.languageData.displayName ? true : false
            }
            onClick={() => languageChange(lng)}
          >
            <Tooltip title={lng.displayName}>
              <ListItemText
                className={[classes.textLimit, classes.menuStyle]}
                primary={lng.displayName}
              />
            </Tooltip>
          </ListItem>
        </List>
      </Collapse>
    ));
  };
  const BottomDrawerMenu = () => {
    return (
      <div>
        <List>
          <ListItem
            button
            key={1}
            onClick={() =>
              setMenuStatus({
                ...menuStatus,
                language: !menuStatus['language'],
              })
            }
          >
            <ListItemIcon>
              <TranslateIcon />
            </ListItemIcon>
            <Tooltip title={props.t('topNavBar.language')}>
              <ListItemText
                className={classes.menuStyle}
                primary={
                  props.languageData !== undefined &&
                  props.languageData !== null
                    ? props.t('topNavBar.language') +
                      ' ( ' +
                      props.languageData.displayName +
                      ' ) '
                    : props.t('topNavBar.language') + ' ( English ) '
                }
              />
            </Tooltip>
            {menuStatus['language'] ? <ExpandMore /> : <ExpandLess />}
          </ListItem>
          <LanguageList />
        </List>

        <List>
          <ListItem
            button
            key={2}
            onClick={() => {
              handleBottomDrawerOpen();
              props.logout();
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <Tooltip title={props.t('topNavBar.logout')}>
              <ListItemText
                className={[classes.textLimit, classes.menuStyle]}
                primary={props.t('topNavBar.logout')}
              />
            </Tooltip>
          </ListItem>
        </List>
      </div>
    );
  };
  return (
    <div className={classes.adminRoot}>
      <div className={classes.background}>
      
      </div>

      <TopBar
        handleDrawerToggle={handleDrawerToggle}
        pageName={pageName}
        mobileOpen={mobileOpen}
        handleBottomDrawerOpen={handleBottomDrawerOpen}
      />
      <ResponsiveDrawer
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        callback={setPageName}
      />
      <Hidden only={['lg', 'md', 'sm', 'xs']}>
        <main className={classes.contentDesktop}>
          <div className={classes.toolbar} />
          <AdminContentRouting />
        </main>
      </Hidden>
      <Hidden only={['xl']}>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <AdminContentRouting />
        </main>
      </Hidden>
      <Hidden only={['xl', 'lg', 'md']}>
        <Drawer
          variant='temporary'
          anchor={'bottom'}
          open={bottomDrawerOpen}
          onClose={handleBottomDrawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, 
          }}
        >
          <BottomDrawerMenu />
        </Drawer>
      </Hidden>
      <Snackbar
        open={props.notification.open}
        onClose={props.notificationClose}
        anchorOrigin={props.notification.anchorOrigin}
        autoHideDuration={props.notification.duration}
      >
        <Alert severity={props.notification.severity}>
          {props.notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    theme: state.theme,
    languageData: state.languageData,
    notification: state.notification,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: 'SHORTCUTKEY', value: shortcutKeyValue }),
    languageChange: (languageData) =>
      dispatch({ type: 'LANGUAGE', value: languageData }),
    logout: () => dispatch({ type: 'LOGOUT', value: '' }),
    notificationClose: () =>
      dispatch({ type: 'NOTIFICATION_DESTROY', value: '' }),
    sideDrawerChange: (sideDrawerData) =>
      dispatch({ type: 'SIDEDRAWER', value: sideDrawerData }),
  };
};
export default withTranslation('translations')(
  connect(mapStateToProps, mapDispachToProps)(Admin)
);
