import { MenuItem, TextField, Typography } from "@material-ui/core";
import { Button, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import Useupload from "./Useupload";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import APIRequest from "../../../../../utils/APIRequest";
import { DataContext } from "./MyloomsIndex";

const Create = (props) => {
  const { setUserForm, userForm, userId, showNotification } =
    React.useContext(DataContext);

  const [createData, setCreateData] = React.useState({
    serviceNo: "",
    loomNo: "",
    loomType: "",
    description: "",
    images: [],
  });

  const sendToServer = () => {
    let url = ConfigAPIURL.createLoom;
    let method = "POST";

    APIRequest.request(
      method,
      url,
      JSON.stringify({
        loom: createData,
        recordId: userId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setCreateData({
            serviceNo: "",
            loomNo: "",
            loomType: "",
            description: "",
            images: [],
          });

          SnackbarUtils.sucess(
            "Loom data added successfully!",
            "bottomCenter",
            3000
          ).then((notification) => {
            showNotification(notification);
          });
        }
        if (response.code === 100 && response.data.responseCode === 114) {
          SnackbarUtils.error(
            "Something went wrong! Please try again later.",
            "bottomCenter",
            3000
          ).then((notification) => {
            showNotification(notification);
          });
        }
      }
    });
  };

  const handleInputChange = (field, value) => {
    setCreateData({
      ...createData,
      [field]: value,
    });
  };

  const validationCheck = () => {
    if (
      createData?.serviceNo === "" ||
      createData?.loomNo === "" ||
      createData?.loomType === "" ||
      !createData?.images ||
      !Array.isArray(createData?.images) ||
      createData?.images.length === 0
    ) {
      SnackbarUtils.error(
        "Please fill all the required fields!",
        "bottomCenter",
        3000
      ).then((notification) => {
        showNotification(notification);
      });
      return false;
    } else {
      sendToServer();
      return true;
    }
  };

  useEffect(() => {
    if (props.activeTab === "create") {
      setCreateData({
        serviceNo: "",
        loomNo: "",
        loomType: "",
        description: "",
        images: [],
      });
    }
  }, [props.activeTab === "create"]);

  return (
    <>
      <Stack style={{ background: "white" }}>
        <Stack
          style={{
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "column",
              sm: "column",
            },
            background: "white",
            gap: "10px",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              style={{ marginTop: "7px", padding: "20px" }}
            >
              <TextField
                id="serviceNo"
                label="Service Number"
                variant="outlined"
                required
                fullWidth
                value={createData?.serviceNo}
                onChange={(event) =>
                  handleInputChange("serviceNo", event.target.value)
                }
              />
            </Grid>

            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              style={{ padding: "20px", marginTop: "10px" }}
            >
              <TextField
                variant="outlined"
                id="loomNo"
                required
                label="Loom Number"
                fullWidth
                value={createData?.loomNo}
                onChange={(event) =>
                  handleInputChange("loomNo", event.target.value)
                }
              />
            </Grid>
            <Grid
              itema
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              style={{ padding: "20px", marginTop: "10px" }}
            >
              <TextField
                variant="outlined"
                id="loomType"
                required
                label="Loom Type"
                fullWidth
                value={createData?.loomType}
                placeholder="handloom"
                onChange={(event) =>
                  handleInputChange("loomType", event.target.value)
                }
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack
          style={{
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "column",
              sm: "column",
            },
            background: "white",
            gap: "10px",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: "7px", padding: "20px" }}
            >
              <Useupload
                userForm={userForm}
                setUserForm={setUserForm}
                handleInputChange={handleInputChange}
                createData={createData}
                setCreateData={setCreateData}
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack
          style={{
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "column",
              sm: "column",
            },
            background: "white",
            gap: "10px",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: "7px", padding: "20px" }}
            >
              <TextField
                id="notes"
                variant="outlined"
                placeholder="Notes"
                label="Notes"
                style={{
                  width: "100%",
                  height: "100px",
                  padding: "10px",
                  resize: "vertical",
                  wordWrap: "break-word",
                }}
                value={createData?.description}
                onChange={(event) =>
                  handleInputChange("description", event.target.value)
                }
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack
          style={{
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "column",
              sm: "column",
            },
            background: "white",
            gap: "10px",
          }}
        >
          <Grid container spacing={2} justifyContent={"center"} mt={3}>
            <Button
              style={{
                width: "Hug (138px)px",
                height: "Hug (56px)px",
                padding: "16px 36px 16px 36px",
                gap: "8px",
                borderRadius: "21px",
                opacity: "0px",
                background: "#FF8D00",
                color: "#FFFFFF",
              }}
              onClick={() => validationCheck()}
            >
              <Typography style={{fontFamily:"Lato"}}>Submit</Typography>
            </Button>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default Create;
