import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  TextareaAutosize,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { Stack } from "@mui/material";
import landingImg from "../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
import "./dashboard.scss";
import Users from "../../../../assets/images/Users.png";
import Enquiry from "../../../../assets/images/Enquiry.png";
import Revenue from "../../../../assets/images/Fee.png";
const GlobalStatistics = () => {
const[globalcount,setGlobalCount]=useState([])


    const GlobalStatistics=()=>{
        APIRequest.request(
            "GET",
            ConfigAPIURL.globalstatic,
            "",
        ).then((response)=>{
            let userResult=response?.data?.result
            setGlobalCount(userResult)
            // console.log(response)
        })
    }
    useEffect(()=>{
        GlobalStatistics()
    },[])
// console.log(globalcount,"globalcount")

  return (
    <div>
      <Stack style={{marginTop:"0px"}}>

        <Grid container spacing={2}
        style={{marginTop:"10px"}}
        >
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Stack className="global" style={{ background: "#9900F0" ,padding:"20px" }}>
              <Stack >
              <img style={{width:"52px",height:"52px"}}src={Users} alt="Users"/>
              </Stack>
              <Stack style={{marginTop:"10px"}}>
                <Typography className="name"
                style={{color:"white"}}
                >Total Users</Typography>
              </Stack>
              <Stack>
                <Typography className="count"
                style={{color:"white"}}
                >{globalcount?.totalUser}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Stack className="global" style={{ background: "#FF8D00" ,padding:"20px" }}>
              <Stack style={{width:"52px",height:"52px"}}>
              <img src={Revenue} alt="Users"/>
              </Stack>
              <Stack style={{marginTop:"10px"}}>
                <Typography className="name">Total Revenue</Typography>
              </Stack>
              <Stack>
                <Typography className="count">{globalcount?.totalRevenue}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Stack className="global" style={{ background: "#FF60A8" ,padding:"20px" }}>
              <Stack style={{width:"52px",height:"52px"}}>
              <img src={Enquiry} alt="Users"/>
              </Stack>
              <Stack style={{marginTop:"10px"}}>
                <Typography className="name">Total Enquiry</Typography>
              </Stack>
              <Stack>
                <Typography className="count">{globalcount?.totalEnquiry}</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default GlobalStatistics;
