import { Button } from "@material-ui/core";
import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import useImageUpload from "./useImageUpload";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  button: {
    minWidth: 100,
    marginRight: theme.spacing(2),
  },
});

const ImagesUpload = ({ setUserForm, userForm }) => {
  const { onUpload, deleteImages } = useImageUpload(setUserForm, userForm);
  console.log(userForm);

  return (
    <Stack my={2} px={2}>
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        {/* <Typography variant="subtitle1" fontSize="18px" fontWeight="400">
          Images
        </Typography> */}
        {/* <input
          accept="image/*"
          style={{ display: "none" }}
          //   className={classes.input}\
           multiple
          id="icon-button-file"
          type="file"
          onChange={onUpload}
        />
        <label htmlFor="icon-button-file">
          <Button
            // variant="outlined"
            // className={classes.button}
            style={{fontFamily:"Lato"}}
            component="span"
          >
            Upload
          </Button>
        </label> */}
      </Stack>
      <Grid container>
        <Grid item sm={3}
                              style={{alignSelf:"center"}}
                              >
        <Typography
              style={{fontWeight:"600",
            fontSize:"14px",
          marginTop:"5px",
          alignSelf:"center"
          }}
              >Profile Image</Typography>
        </Grid>
        <Grid item sm={8}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography
                      style={{fontWeight:"600",
                      fontSize:"14px",
                    alignSelf:"center",
                    marginLeft:"30px"
                    }}
                      >Uploaded Images</Typography> 
                      <Stack 
                      style={{alignSelf:"center"}}
                      >
                      <input
          accept="image/*"
          style={{ display: "none" }}
          //   className={classes.input}\
           multiple
          id="icon-button-file"
          type="file"
          onChange={onUpload}
        />
        <label htmlFor="icon-button-file">
          <Button
            // variant="outlined"
            // className={classes.button}
            style={{fontFamily:"Lato"}}
            component="span"
          >
            Upload
          </Button>
        </label>
                      </Stack>
          
          </Stack>
      
        </Grid>
      </Grid>
      {userForm?.images && userForm?.images.length > 0 && (
        <Grid
          container
          sx={{
            // background: "#FFDDB3",
            borderRadius: "8px",
            marginTop:"8px",
            gap: 2,
          flexDirection:"row"
          }}
        >
           <Grid item sm={3}
         style={{
              padding:"15px",
              background:"#F9F9F9",
              borderRadius:"8px",


         }} 
          >
          
            <Stack
            style={{width:"100%",
          height:"220px",
        objectFit:"cover",
      // marginTop:"10px",
  }}
            >
          
            <img 
            style={{width:"100%",
          height:"220px",
          borderRadius:"4px"
        }}
            src={userForm?.profileImage}/>

            </Stack>
           </Grid>
        
          <Grid item  lg={8}sm={8} md={8}
                   style={{background:"#F9F9F9",padding:"15px",borderRadius:"8px",display:"flex",}} 

          >
                    

          {userForm?.images?.map((value, ind) => {
            return (
              // <Stack
              // style={{display:"flex",}}

              // >
                <Grid item xs={3} key={ind} sx={{ position: "relative" ,flexDirection:"row",paddingLeft:"10px"}}>
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: "9999",
                    right: "-9px",
                    top: "-4px",
                    width: "52px",
                    height: "52px",
                    borderRadius: "100%",
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "6px solid  #FFDDB3",
                  }}
                >
                  <DeleteIcon
                    style={{ cursor: "pointer" ,color:"#FF8D00" }}
                    onClick={() => deleteImages(ind)}
                  />
                </Box>
                <Stack
                  position="relative"
                  height="220px"
                  width="100%"
                  borderRadius="4px"
                  overflow="hidden"
                >
                  <img
                    src={value}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="images"
                  />
                </Stack>
              </Grid>
              // </Stack>
        
            );
          })}
          </Grid>
         
        </Grid>
      )}
    </Stack>
  );
};

export default ImagesUpload;
