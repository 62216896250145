/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import TableQuery from './TableQuery';
import TableHeader from './TableHeader';
import APIRequest from '../../../../utils/APIRequest';
import SlideInDialog from '../../../../templates/dialog/SlideInDialog';
import DataTable from '../../../../templates/tables/DataTable';
import APIRequestDataTableQuery from '../../../../utils/APIRequestDataTableQuery';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import LocalStorage from '../../../../config/LocalStorage';
import TableButtons from '../../../../templates/buttons/TableButtons';

import { connect } from 'react-redux';
import FormDialog from '../../../../templates/dialog/FormDialog';
import AEVForm from './AEVForm';
import SnackbarUtils from '../../../../utils/SnackbarUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: '-5%',
    position: 'relative',
    left: '42%',
  },
  userTypeMenuItem: {
    minWidth: '50%',
    marginTop: '6px%',
  },
}));

function Categories(props) {
  const classes = useStyles();

  const [slideInDialog, setSlideInDialog] = useState(false);

  /* table query body -
	keyword, page, pageSize, sortField, sortOrder should be constant for all the page. 
	Here status is use for filter condition.
	*/
  const [query, setQuery] = useState({
    "active":true,
    "status": "all",
    "pageSize":10,
    "page": 0,
    "startDate":null,
    "endDate":null,
    // sortField: '',
    // sortOrder: 'false',
    // isClinic: "all",
   
  });
  // initial table record
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });

  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: '',
    message: '',
  });

  const [openForm, setOpenForm] = useState({
    status: false,
    title: '',
    divType: '',
    functionName: '',
  });

  const handleSlideInDialog = () => {
    setSlideInDialog(false);
    tableQuery(query);
  };
  // selected table record information
  const [rowDetails, setRowDetails] = useState(null);
  const [roles, setRoles] = useState([]);
  const[companyName,setCompanyName]=useState([])

  // buttons list for data table
  const [buttonList, setButtonDetails] = useState([]);
  // useful of on page load
  useEffect(() => {
    getRoleTitles();
    tableQuery(query); // calling table api on page load by default value
    setButtonDetails(LocalStorage.adminButtonPermission);
  }, [LocalStorage.adminButtonPermission]);

  const getRoleTitles = () => {
    APIRequest.request('GET', ConfigAPIURL.roleTitles, '').then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setRoles(response.data.roles);
        }
      }
    });
  };

  useEffect(() => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== '') {
      if (rowDetails.dataTableDBClick === 'dataTableDBClick') {
        {
          LocalStorage.adminButtonPermission.find(function (element) {
            return element.button === 'edit' ? true : false;
          })
            ? tableEdit()
            : SnackbarUtils.error(
                props.t('toaster.permissionNotAvailable'),
                'bottomCenter',
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
        }
      }
    }
  }, [rowDetails]);
  //keyboard shortcut
  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case 'add':
        tableAdd();
        break;
      case 'edit':
        tableEdit();
        break;
      case 'view':
        tableView();
        break;
      case 'delete':
        tableDelete();
        break;
      case 'esc':
        handleFormDialog();
        break;
      default:
        break;
    }
    props.shortcutKey('');
  }, [props.shortcutKeyValue]);
  // table query body value handler
  const handleQuery = (query) => {
    setQuery(query);
    tableQuery(query);
  };
  // table query body value reset handler
  const resetQuery = () => {
    setQuery({
      active:true,
    keyword: '',
    page: 0,
    pageSize: 10,
    sortField: '',
    sortOrder: 'false',
    isClinic: "all",
    active: true,
  });
    tableQuery({
      keyword: '',
      page: 0,
      pageSize: 10,
      sortField: '',
      sortOrder: 'false',
      startAt: null,
      endAt: null,
      active: true,
      status:"all",
      startDate:null,
      endDate:null

  });
  };
  // fetch table record from server with current query body
//    const tableQuery = (query) => {
//      if (
//        LocalStorage.adminButtonPermission.find(function (element) {
//          return element.button === 'query' ? true : false;
//        })
//      ) {
//        APIRequestDataTableQuery.request(
//          'GET',
//          ConfigAPIURL.clinicTableURL,
//             ""
//             //  JSON.stringify(query)
//        ).then((tableData,response) => {
//          if (response !== undefined && response !== null) {
//            if (response.code === 100 && response.data.responseCode === 109) {
//  let classifieds=response?.data?.result
//          setTableData(tableData);
//          console.log(classifieds,tableData)
//            }
//          }
//        });
//      } else {
//         // notification(props.t('toaster.tableRecordPermission'), 'error');
//      }
//    };

  
const tableQuery = (query) => {
  if (
    LocalStorage.adminButtonPermission.find(function (element) {
      return element.button === 'query' ? true : false;
    })
  ) {
    APIRequestDataTableQuery.request(
      'POST',
      ConfigAPIURL.category,
       JSON.stringify(query)
    ).then((tableData) => {
    
      setTableData(tableData);
      console.log(tableData)
    });
  } 
  // else {
  //     notification(props.t('toaster.tableRecordPermission'), 'error');
  // }
};
    // const classifides=async()=>{
    //   try{
    //     let classifidesdata=await fetch("http://localhost:4000/admin/classified/all")
    //     let tableData=await classifidesdata.json()
    //     console.log(tableData.data)
    //     setTableData(tableData)
    //   }
    //   catch(err){
    //     console.log(err)
    //   }
    // }
    // useEffect(()=>{
    //   axios.get("http://localhost:4000/admin/classified/all")
    //   .then((res)=>{
    //     setTableData(res.tableData)
    //   })

    //         },[])
      

  const notification = (message, type) => {
    SnackbarUtils[type](message, 'bottomCenter', 3000).then((notification) => {
      props.publishNotification(notification);
    });
  };
  //  table record selected callback
  const tableCallBack = (rowDetails) => {
    setRowDetails(rowDetails);
  };

  // table record selected callback
  const tableDBLCallBack = (dbClickrowDetails) => {
    setRowDetails({
      ...dbClickrowDetails,
      dataTableDBClick: 'dataTableDBClick',
    });
  };

  //table button call back function
  const tableAdd = () => {
    setOpenForm({
      status: true,
      title: props.t('nekaramitracategory.add'),
      divType: 'new',
      functionName: '',
    });
  };
  const tableEdit = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== '') {
      setOpenForm({
        status: true,
        title: props.t('nekaramitracategory.edit'),
        divType: 'edit',
        functionName: '',
      });
    } else {
      SnackbarUtils.error(
        props.t('snackbar.selectRow'),
        'bottomCenter',
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };
  const tableView = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== '') {
      setOpenForm({
        status: true,
        title: props.t('clinic.viewClinic'),
        divType: 'view',
        functionName: '',
      });
      return true;
    } else {
      SnackbarUtils.error(
        props.t('snackbar.selectRow'),
        'bottomCenter',
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return false;
    }
  };
  const tableDelete = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== '') {
      if (query.active === true) {
        setSlideInDialog(true);
      } else {
        SnackbarUtils.error(
          props.t('snackbar.recordAlreadyDeleted'),
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      SnackbarUtils.error(props.t('snackbar.selectRow'), 3000).then(
        (notification) => {
          props.publishNotification(notification);
        }
      );
    }
  };
  const tableUpload = () => {
    // console.log('tableUpload');
  };
  const tableApprove = () => {
    // console.log('tableApprove');
  };
  const tableReject = () => {
    // console.log('tableReject');
  };

  const tableAssign = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== '') {
      
    } else {
      SnackbarUtils.error(props.t('snackbar.selectRow'), 3000).then(
        (notification) => {
          props.publishNotification(notification);
        }
      );
    }
  }
  const tableCheckStatus = () => {
    // console.log('tableCheckStatus');
  };
  const handleFormDialog = () => {
    setRowDetails(null);
    tableQuery(query);
    setOpenForm({ status: false, title: '', divType: '', functionName: '' });
  };
  const submit = () => {
    setOpenForm({ ...openForm, functionName: 'submit' });
  };
  const resetForm = () => {
    setOpenForm({ ...openForm, functionName: 'reset' });
  };

  const handleDeleteRequest = (response) => {
    if (response === 'agree') {
      APIRequest.request(
        'PUT',
        ConfigAPIURL.categorydelete,
        JSON.stringify({ recordId: rowDetails._id })
      ).then((response) => {
        console.log(response)
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            tableQuery(query);
            setRowDetails(null);
            setOpenForm({
              status: false,
              title: '',
              divType: '',
              functionName: '',
            });
            SnackbarUtils.sucess(
              props.t('snackbar.deletesuccess'),
              'bottomCenter',
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            handleSlideInDialog();
          }
        }
      });
    } else {
      handleSlideInDialog();
      setRowDetails(null);
      setOpenForm({ status: false, title: '', divType: '', functionName: '' });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}
        style={{textTransform:"capitalize"}}
        >
          <DataTable
            tableHeader={TableHeader.adminTableHeader} // table column header
            tableData={tableData} // pass table record
            query={query} // pass query condition
            setQuery={handleQuery} // update query fields
            resetQuery={resetQuery} // reset filter menu values
            selectAll={false} //select all check box props
            openForm={openForm} //un select all check box props
            tableCallBack={tableCallBack}
            searchLabel={props.t('nekaramitracategory.serachlabel')}
            tableDBLCallBack={tableDBLCallBack}
            queryComponent={<TableQuery query={query} setQuery={handleQuery} />} //filter menu option
            buttonComponent={
              <TableButtons
                tableAdd={tableAdd}
                tableEdit={tableEdit}
                tableView={tableView}
                tableDelete={tableDelete}
                tableUpload={tableUpload}
                tableApprove={tableApprove}
                tableReject={tableReject}
                tableAssign={tableAssign}
                tableCheckStatus={tableCheckStatus}
                buttonList={buttonList}
              />
            }
          />
        </Grid>
      </Grid>
      <FormDialog
        sideDrawerData={props.sideDrawerData}
        formDialog={openForm}
        handleFormDialog={handleFormDialog}
        submit={submit}
        resetForm={resetForm}
        formComponent={
          <AEVForm
            marginTop={60}
            roles={roles}
            formDialog={openForm}
            handleFormDialog={handleFormDialog}
            rowDetails={rowDetails}
            tableQuery={tableQuery}
          />
        }
      />

      <SlideInDialog
        title={props.t('nekaramitracategory.deletecategory')}
        contentComponent={props.t('dialog.delete')}
        fullWidth={true}
        maxWidth={'sm'}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleDeleteRequest}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: 'SHORTCUTKEY', value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: 'NOTIFICATION_OPEN', value: notification }),
  };
};
export default withTranslation('translations')(
  connect(mapStateToProps, mapDispachToProps)(Categories)
);
