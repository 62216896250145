/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import TableQuery from './TableQuery';
import TableHeader from './TableHeader';
import APIRequest from '../../../../utils/APIRequest';
import SlideInDialog from '../../../../templates/dialog/SlideInDialog';
import DataTable from '../../../../templates/tables/DataTable';
import APIRequestDataTableQuery from '../../../../utils/APIRequestDataTableQuery';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import LocalStorage from '../../../../config/LocalStorage';
import TableButtons from '../../../../templates/buttons/TableButtons';

import { connect } from 'react-redux';
import FormDialog from '../../../../templates/dialog/FormDialog';
import AEVForm from './AEVForm';
import SnackbarUtils from '../../../../utils/SnackbarUtils';
import AddUser from './AddUser';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: '-5%',
    position: 'relative',
    left: '42%',
  },
  userTypeMenuItem: {
    minWidth: '50%',
    marginTop: '6px%',
  },
}));
function User(props) {
  // console.log(props,"props")

  const classes = useStyles();

  const [slideInDialog, setSlideInDialog] = useState(false);
  const [resetPasswordAttemptDialog, setResetPasswordAttemptDialog] =
    useState(false);
  const [verifyUser, setVerifyUser] = useState(false);

  /* table query body -
	keyword, page, pageSize, sortField, sortOrder should be constant for all the page. 
	Here status is use for filter condition.
	*/

  const [query, setQuery] = useState({
    keyword: "",
    page: 0,
    pageSize: 10,
    sortField: "",
    sortOrder: "false",
    userType: "all",
    active: true,
    startDate:null,
    endDate:null,
    sector:"all",
    status:"all"
    
  });
  // initial table record
  const [tableData, setTableData] = useState({
    rows: null,
    pages: 0,
    filterRecords: 0,
  });

  const [snakbarValues, setSnakbarValues] = useState({
    status: false,
    severity: "",
    message: "",
  });

  const [openForm, setOpenForm] = useState({
    status: false,
    title: "",
    divType: "",
    functionName: "",
  });
  // user form Values
  const [userForm, setUserForm] = useState({
    userType: "all",
    active: true,
    fname: "",
    lname: "",
    mobileNo: "",
    email: "",
    age: "",
    dob: new Date(),
    gender: "",
    weight: 0,
    height: 0,
    isSmoker: false,
    alcoholIntake: "none",
    allergies: "",
    chronicConditions: "",
    familyHistory: "",
    surgeries: "",
    password: "",
  });

  const handleSlideInDialog = () => {
    setSlideInDialog(false);
    setVerifyUser(false);
    tableQuery(query);
  };
  // selected table record information
  const [rowDetails, setRowDetails] = useState(null);
  const [roles, setRoles] = useState([]);

  // buttons list for data table
  const [buttonList, setButtonDetails] = useState([]);
  const [status, setStatus] = useState(null);
  // useful of on page load
  useEffect(() => {
    getRoleTitles();
    tableQuery(query); // calling table api on page load by default value
    setButtonDetails(LocalStorage.adminButtonPermission);
  }, [LocalStorage.adminButtonPermission]);

  const getRoleTitles = () => {
    APIRequest.request("GET", ConfigAPIURL.roleTitles, "").then((response) => {
      if (response !== undefined && response !== null) {
        if (response.code === 100 && response.data.responseCode === 109) {
          setRoles(response.data.roles);
        }
      }
    });
  };

  useEffect(() => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (rowDetails.dataTableDBClick === "dataTableDBClick") {
        {
          LocalStorage.adminButtonPermission.find(function (element) {
            return element.button === "edit" || element.button === "view"
              ? true
              : false;
          })
            ? tableEdit()
            : SnackbarUtils.error(
                props.t("toaster.permissionNotAvailable"),
                "bottomCenter",
                3000
              ).then((notification) => {
                props.publishNotification(notification);
              });
        }
      }
    }
  }, [rowDetails]);
  //keyboard shortcut
  useEffect(() => {
    switch (props.shortcutKeyValue) {
      case "add":
        tableAdd();
        break;
      case "edit":
        tableEdit();
        break;
      case "view":
        tableView();
        break;
      case "delete":
        tableDelete();
        break;
      case "esc":
        handleFormDialog();
        break;
      default:
        break;
    }
    props.shortcutKey("");
  }, [props.shortcutKeyValue]);
  // table query body value handler
  const handleQuery = (query) => {
    setQuery(query);
    tableQuery(query);
  };
  // table query body value reset handler
  const resetQuery = () => {
    setQuery({
      keyword: "",
      page: 0,
      pageSize: 10,
      sortField: "",
      userType: "all",
      sortOrder: "false",
      active: true,
      status:"all",
      startDate:null,
      endDate:null,
      sector:"all",
    });
    tableQuery({
      keyword: "",
      page: 0,
      pageSize: 10,
      sortField: "",
      userType: "all",
      sortOrder: "false",
      active: true,
      status:"all",
      startDate:null,
      endDate:null,
      sector:"all",
      status:"all"
    });
  };
  // fetch table record from server with current query body
  const tableQuery = (query) => {
    if (
      LocalStorage.adminButtonPermission.find(function (element) {
        return element.button === "query" ? true : false;
      })
    ) {
      APIRequestDataTableQuery.request(
        "POST",
        ConfigAPIURL.userTableURL,
        JSON.stringify(query)
      ).then((tableData) => {
        // console.log(tableData)

        setTableData(tableData);
      });
    } else {
      // notification(props.t('toaster.tableRecordPermission'), 'error');
    }
  };
  const notification = (message, type) => {
    SnackbarUtils[type](message, "bottomCenter", 3000).then((notification) => {
      props.publishNotification(notification);
    });
  };
  // table record selected callback
  const tableCallBack = (rowDetails) => {
    setRowDetails(rowDetails);
    setStatus(rowDetails?.status);
  };

  // table record selected callback
  const tableDBLCallBack = (dbClickrowDetails) => {
    setRowDetails({
      ...dbClickrowDetails,
      dataTableDBClick: "dataTableDBClick",
    });
  };

  //table button call back function
  const tableAdd = () => {
    setOpenForm({
      status: true,
      title: props.t("user.addUser"),
      divType: "new",
      functionName: "",
    });
  };
  const tableEdit = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      setOpenForm({
        status: true,
        title: props.t("user.editUser"),
        divType: "edit",
        functionName: "",
      });
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  // const tableVerifyUser = () => {
  //   if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
  //     setOpenForm({
  //       status: true,
  //       title: props.t("user.viewUser"),
  //       divType: "verify",
  //       functionName: "",
  //     });
  //     return true;
  //   } else {
  //     SnackbarUtils.error(
  //       props.t("snackbar.selectRow"),
  //       "bottomCenter",
  //       3000
  //     ).then((notification) => {
  //       props.publishNotification(notification);
  //     });
  //     return false;
  //   }
  // };

  const tableView = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      setOpenForm({
        status: true,
        title: props.t("user.viewUser"),
        divType: "view",
        functionName: "",
      });
      return true;
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return false;
    }
  };
  const tableDelete = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (query.active === true) {
        setSlideInDialog(true);
      } else {
        SnackbarUtils.error(
          props.t("snackbar.recordAlreadyDeleted"),
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
    } else {
      SnackbarUtils.error(props.t("snackbar.selectRow"), 3000).then(
        (notification) => {
          props.publishNotification(notification);
        }
      );
    }
  };
  const tableUpload = () => {
    // console.log('tableUpload');
  };
  const tableApprove = () => {
    // console.log('tableApprove');

    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== ""  ){
      
      setOpenForm({
        status: true,
        title: props.t("user.approveUser"),
        divType: "approve",
        functionName: "",
      });
      return true;
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return false;
    }
    
  
  };
  
  
  const tableReject = () => {
    // console.log('tableReject');
  };
  const tableCheckStatus = () => {
    // console.log('tableCheckStatus');

    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      setVerifyUser(true);
    } else {
      SnackbarUtils.error(props.t("snackbar.selectRow"), 3000).then(
        (notification) => {
          props.publishNotification(notification);
        }
      );
    }
  };
  const handleFormDialog = () => {
    setRowDetails(null);
    tableQuery(query);

    setOpenForm({ status: false, title: "", divType: "", functionName: "" });
  };
  const submit = () => {
    setOpenForm({ ...openForm, functionName: "submit" });
  };
  const resetForm = () => {
    setOpenForm({ ...openForm, functionName: "reset" });
  };

 

  const tableResetPassword = () => {
    if (rowDetails !== undefined && rowDetails !== null && rowDetails !== "") {
      if (rowDetails.userType !== "admin") {
        return SnackbarUtils.error(
          props.t("snackbar.resetAdmin"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }
      if (rowDetails.passwordAttempt === 0) {
        return SnackbarUtils.error(
          props.t("snackbar.alreadyZeroAttempts"),
          "bottomCenter",
          3000
        ).then((notification) => {
          props.publishNotification(notification);
        });
      }

      setResetPasswordAttemptDialog(true);
    } else {
      SnackbarUtils.error(
        props.t("snackbar.selectRow"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
      return false;
    }
  };
  const handleDeleteRequest = (response) => {
    if (response === "agree") {
      APIRequest.request(
        "POST",
        ConfigAPIURL.userDelete,
        JSON.stringify({ userId: rowDetails._id })
      ).then((response) => {
        
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            tableQuery(query);
            setRowDetails(null);
            setOpenForm({
              status: false,
              title: "",
              divType: "",
              functionName: "",
            });
            SnackbarUtils.sucess(
              props.t("snackbar.updatedSuccess"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            handleSlideInDialog();
          }
        }
      });
    } else {
      handleSlideInDialog();
      setRowDetails(null);
      setOpenForm({ status: false, title: "", divType: "", functionName: "" });
    }
  };

  

  const handleResetAttempt = (response) => {
    if (response === "agree") {
      APIRequest.request(
        "POST",
        ConfigAPIURL.resetPasswordAttempt,
        JSON.stringify({ userId: rowDetails._id })
      ).then((res) => {
        if (res !== undefined && res !== null) {
          if (res.code === 100 && res.data.responseCode === 109) {
            SnackbarUtils.sucess(
              props.t("Password Attemps updated Successfully"),
              "bottomCenter",
              3000
            ).then((notification) => {
              props.publishNotification(notification);
            });
            tableQuery(query);
            setRowDetails(null);
            setOpenForm({
              status: false,
              title: "",
              divType: "",
              functionName: "",
            });
          }
        }
      });
      setResetPasswordAttemptDialog(false);
    } else {
      setResetPasswordAttemptDialog(false);
      setRowDetails(null);
      setOpenForm({ status: false, title: "", divType: "", functionName: "" });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container
      style={{
        textTransform:"capitalize"
    }}>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <DataTable
            tableHeader={TableHeader.adminTableHeader} // table column header
            tableData={tableData} // pass table record
            query={query} // pass query condition
            setQuery={handleQuery} // update query fields
            resetQuery={resetQuery} // reset filter menu values
            selectAll={false} //select all check box props
            openForm={openForm} //un select all check box props
            tableCallBack={tableCallBack}
            searchLabel={props.t("user.searchLabel")}
            tableDBLCallBack={tableDBLCallBack}
            queryComponent={<TableQuery query={query} setQuery={handleQuery} />} //filter menu option
            buttonComponent={
              LocalStorage.userDetails.isSuperAdmin ? (
                <TableButtons
                  tableAdd={tableAdd}
                  tableEdit={tableEdit}
                  // tableVerifyUser={tableVerifyUser}
                  tableView={tableView}
                  tableDelete={tableDelete}
                  tableUpload={tableUpload}
                  tableApprove={tableApprove}
                  tableReject={tableReject}
                  tableCheckStatus={tableCheckStatus}
                  tableResetPassword={tableResetPassword}
                  buttonList={buttonList}
                />
              ) : (
                <TableButtons
                  tableAdd={tableAdd}
                  tableEdit={tableEdit}
                  // tableVerifyUser={tableVerifyUser}
                  tableView={tableView}
                  tableDelete={tableDelete}
                  tableUpload={tableUpload}
                  tableApprove={tableApprove}
                  tableReject={tableReject}
                  tableCheckStatus={tableCheckStatus}
                  tableResetPassword={tableResetPassword}
                  buttonList={buttonList}
                />
              )
            }
          />
        </Grid>
      </Grid>
      <FormDialog
        sideDrawerData={props.sideDrawerData}
        label={
          openForm.divType !== 'new'
            ? rowDetails !== undefined && rowDetails !== null
              ? rowDetails.userType === 'user'
                ? rowDetails.fname + ' - ' + rowDetails.patientId
                : rowDetails.fname
              : ''
            : ''
        }
        formDialog={openForm}
        handleFormDialog={handleFormDialog}
        submit={submit}
        resetForm={resetForm}
        formComponent={
          openForm.divType==="edit"?
          (
          <AEVForm
            marginTop={60}
            roles={roles}
            formDialog={openForm}
            handleFormDialog={handleFormDialog}
            rowDetails={rowDetails}
            tableQuery={tableQuery}
            buttonList={buttonList}
          />):
          <AddUser
            marginTop={60}
            roles={roles}
            formDialog={openForm}
            handleFormDialog={handleFormDialog}
            rowDetails={rowDetails}
            tableQuery={tableQuery}
          />
          
        }
      />
      <SlideInDialog
        title={props.t("user.resetPassword")}
        contentComponent={props.t("dialog.resetPassword")}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={resetPasswordAttemptDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleResetAttempt}
      />

      <SlideInDialog
        title={props.t("user.deleteUser")}
        contentComponent={props.t("dialog.delete")}
        fullWidth={true}
        maxWidth={"sm"}
        dialogActionsStatus={true}
        slideInDialog={slideInDialog}
        setSlideInDialog={handleSlideInDialog}
        handleDeleteRequest={handleDeleteRequest}
      />
      {/* <ChangeStatusDialog
        title={props.t("user.verifyUser")}
        contentComponent={props.t("dialog.verifyUser")}
        fullWidth={true}
        maxWidth={"xs"}
        dialogActionsStatus={true}
        slideInDialog={verifyUser}
        setSlideInDialog={handleSlideInDialog}
        status={status}
        setStatus={setStatus}
        handleChangeStatus={handleChangeStatus}
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(User)
);

