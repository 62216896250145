import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.scss";
import DeleteIcon from "@material-ui/icons/Delete";
import { Stack } from "@fluentui/react";
import APIRequest from "../../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../../config/ConfigAPIURL";
import SnackbarUtils from "../../../../../../utils/SnackbarUtils";

function Slideshow({
  attachment,
  style,
  loomData,
  setLoomData,
  loomIndex,
  userId,
  loom,
  getEditable,
  listAllLooms,
  showNotification,
}) {
  const sendToServer = (loomIndex, index) => {
    let url = ConfigAPIURL.updateLoomDetails;
    let method = "POST";
    const loomToSend = loomData[loomIndex];
    const loomIdToSend = loomToSend._id;
    const payload = {
      userId: userId,
      loomId: loomIdToSend,
      loomType: loomToSend.loomType,
      serviceNo: loomToSend.serviceNo,
      loomNo: loomToSend.loomNo,
      description: loomToSend.description,
      images: loomToSend.images.filter((_, i) => i !== index),
    };
    console.log(payload);
    APIRequest.request(method, url, JSON.stringify(payload)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            getEditable();
            listAllLooms();
            SnackbarUtils.sucess(
              "Loom Image deleted successfully!",
              "bottomCenter",
              3000
            ).then((notification) => {
              showNotification(notification);
            });
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              "Something went wrong! Please try again later.",
              "bottomCenter",
              3000
            ).then((notification) => {
              showNotification(notification);
            });
          }
        }
      }
    );
  };

  return (
    <Stack style={{ gap: "4px", width: "100%", flexWrap: "wrap" }}>
      <Stack
        horizontal
        style={{
          background: "#F9F9F9",
          padding: "16px",
          borderRadius: "8px",
          border: "1px solid #E5E5E5",
          overflowX: "auto",
          justifyContent: "flex-start", 
          minHeight: "130px",
        }}
      >
        <Stack style={{ flexWrap: "nowrap", display: "flex" }}>
          <Stack horizontal gap={10} style={{ flexWrap: "nowrap", display: "flex" }}>
            {/* Render images and PDFs */}
            {attachment &&
              attachment.map((value, i) => (
                <Stack
                  style={{
                    position: "relative",
                    width: "240px",
                    marginBottom: "8px",
                    display: "inline-block", // Ensure each child is inline-block to fit within the horizontal scroll
                  }}
                  key={i}
                >
                  <Stack
                    verticalAlign="center"
                    horizontalAlign="center"
                    style={{
                      width: "49px",
                      height: "49px",
                      background: "white",
                      position: "absolute",
                      borderRadius: "100%",
                      top: "-4px",
                      right: "6px",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon
                      onClick={() => sendToServer(loomIndex, i)}
                      className="deleteIcon"
                      style={{ color: "#FF8D00" }}
                    />
                  </Stack>
                  {value.url &&
                    value.url.trim() !== "" &&
                    (value.url.endsWith(".pdf") ? (
                      <embed
                        src={value.url}
                        type="application/pdf"
                        width="100%"
                        height="150px"
                        // onClick={() => window.open(value.url, "_blank")}
                      />
                    ) : (
                      <img
                        src={value.url}
                        alt=""
                        onClick={() => window.open(value.url, "_blank")}
                        style={{
                          objectFit: "contain",
                          width: "90%",
                          height: "150px",
                          cursor: "pointer",
                        }}
                      />
                    ))}
                </Stack>
              ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Slideshow;
