import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  TextareaAutosize,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { Stack } from "@mui/material";
import landingImg from "../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
import "./dashboard.scss";
import "./total.scss";
import Cash from "../../../../assets/images/Cash.png";
import Enquiry from "../../../../assets/images/Enquiry.png";
import Revenue from "../../../../assets/images/Fee.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import DateConversion, {
  monthNameArray,
} from "../../../../utils/DateConversion";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useResponsiveObserver from "antd/es/_util/responsiveObserver";
import { borderRadius } from "@mui/system";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TotalUsers = () => {
  const myDate = new Date();
  const [value, setValue] = React.useState(dayjs(new Date()));
  const [startDate, setStartDate] = useState(myDate.setDate(-10));
  const [endDate, setEndDate] = useState(new Date());
  const [usersdata, setUsersdata] = useState([]);
  const [days, setDays] = useState("day");
  const [labels, setLabels] = useState([]);
  const [labelscount, setLabelsCount] = useState([]);
  const [labelyaxis, setLabelyaxis] = useState([]);
  const sortedToDates = (data) => {
    let result = [];
    let prevstate = 0;
    data.forEach((val) => {
      const date = val?._id?.day + "/" + val?._id?.month + "/" + val?._id?.year;
      if (prevstate === date) {
        // just modify and push to the stats array the count
        result[result.length - 1].stats.push({
          user: val?._id?.userType,
          count: val?.userCount,
        });
      } else {
        // prevstate is not matched create new date object
        result.push({
          day: date,
          stats: [
            {
              user: val?._id?.userType,
              count: val?.userCount,
            },
          ],
        });
        prevstate = date;
      }
    });
    return result;
  };

  const xAxisData = (data) => {
    let result = {
      // laboratory: [],
      // blood_bank: [],
      // home_nurse: [],
      // insurance: [],
      // ambulance: [],
      // doctor: [],
      // physiotherapy: [],
      shop: [],
      member: [],
    };

    const module = [
      // "insurance",
      // "ambulance",
      // "blood_bank",
      // "home_nurse",
      // "doctor",
      // "physiotherapy",
      // "laboratory",
      "shop",
      "member",
    ];
    const sortedData = sortedToDates(data);
    sortedData.forEach((data) => {
      //take all the user types in array
      const statsmodules = data.stats.map((val) => {
        return val.user;
      });
      module.forEach((m) => {
        if (statsmodules.includes(m)) {
          const moduleCount = data?.stats.find((value) => {
            return value?.user === m;
          });
          result[m].push(moduleCount.count);
        } else {
          result[m].push(0);
        }
      });
    });
    console.log("this is final result", result);
    return result;
  };

  function labeldata(data) {
    if (days === "day") {
      let result = [];
      let prevState = 0;
      data.forEach(function (value) {
        if (prevState === value?._id?.day) return;
        //  console.log(value,"value");
        const days =
          value?._id?.day + "/" + value?._id?.month + "/" + value?._id?.year;
        result.push(days);
        prevState = value?._id?.day;
      });

      // console.log(result);
      return result;
    }
    if (days === "month") {
      let result = [];
      let prevState = 0;
      let month = [];
      data.forEach(function (value) {
        if (prevState === value?._id?.month) return;

        //  console.log(value,"value");
        const days = monthNameArray[value?._id?.month - 1].short;
        // const days=value?._id?.month
        //  console.log(days);
        const daysdata = days + " " + value?._id?.year;
        result.push(daysdata);
        prevState = value?._id?.month;
      });

      // console.log(result);
      return result;
    }

    if (days === "year") {
      let result = [];
      let prevState = 0;
      data.forEach(function (value) {
        if (prevState === value?._id?.year) return;
        // console.log(value);
        const days = value?._id?.year;
        result.push(days);
        prevState = value?._id?.year;
      });

      // console.log(result);
      return result;
    }
  }
  const users = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.totalusertype +
        "?startDate=" +
        `${new Date(startDate) / 1000}` +
        "&" +
        "endDate=" +
        `${new Date(endDate) / 1000} ` +
        "&" +
        //
        "datetype=" +
        `${days}`,
      ""
    ).then((response) => {
      let userResult = response?.data?.result;
      // setGlobalCount(userResult)
      setUsersdata(userResult);
      setLabels(labeldata(userResult));
      setLabelyaxis(xAxisData(userResult));
      console.log(response);
    });
  };
  useEffect(() => {
    users();
  }, [startDate, endDate, days]);

  // graph data (react chartjs)

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Users Count",
        // fontSize:"14px",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Shop",
        userType: "shop",
        data: labelyaxis?.shop,
        borderColor: "#45A8F1",
        backgroundColor: "#45A8F1",
      },
      {
        label: "Member",
        userType: "member",
        data: labelyaxis?.member,
        borderColor: "#BDD1E1",
        backgroundColor: "#BDD1E1",
      },
    ],
  };

  return (
    <div>
      <Stack>
        <Stack style={{ marginTop: "25px" }}>
          <Grid Container>
            <Stack
              style={{
                background: "#FFE9CE",
                paddingBottom: "20px",
                 flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: "10px",
                borderRadius: "8px",
              }}
              sx={{
                flexDirection: {
                xs: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },}}
            >
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                // style={{paddingRight:"20px"}}
              >
                <Stack style={{ gap: "10px", paddingLeft: "25px" }}>
                  <Typography className="usersgraph" style={{ textAlign: "start" }}>
                    Start From
                  </Typography>
                  <Stack
                  className="calaneder"
                    style={{
                      // width: "200px",
                      border: " 0.1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius:"4px",
                      padding:"7px 0px",
                    }}
                  >
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        // label="Basic example"
                        className="calaneder1"
                        style={{ border: "none", }}
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        //  onChange={handelstarDate}
                        renderInput={(params) => <TextField
                          style={{marginLeft:"10px"}}
                          {...params} />}
                      />
                    </LocalizationProvider> */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{
                  width: 'auto',
                  minWidth: '100%',
                }}
                className="datepicker"

                margin='normal'
                disableFuture={true}
                autoOk={true}
                id='startAt'
                //  label={"End Date"}
                format='dd/MM/yyyy'
                value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        maxDate={
                          endDate !== null
                            ? new Date(endDate)
                            : new Date()
                        } 
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <Stack
                  
                  style={{ gap: "10px", paddingLeft: "10px" }}
                >
                  <Typography className="usersgraph" 
                  style={{textAlign:"start",paddinLeft:"20px"}}
                  >End From</Typography>
                  <Stack
                    className="calaneder"
                    style={{
                      // width: "200px",
                     
                      border: " 0.1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius:"4px",
                      padding:"7px 0px",
                      
                    }}
                  >
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        // label="Basic example"
                        className="calaneder"
                        variant="outlined"
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        // onChange={handelendDate}
                        renderInput={(params) => <TextField 
                          style={{marginLeft:"10px"}}
                          {...params} />}
                      />
                    </LocalizationProvider> */}
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{
                  width: 'auto',
                  minWidth: '100%',
                }}
                minDate={
                  startDate !== null
                    ? new Date(startDate )
                    : new Date(0)
                } 
                margin='normal'
                className="datepicker"
                disableFuture={true}
                autoOk={true}
                id='endAt'
                //  label={"End Date"}
                format='dd/MM/yyyy'
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
                  </Stack>
                </Stack>
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={12}
                xs={12}
                //  style={{height:"30px"}}
              >
                <Stack style={{marginRight:"20px",marginTop:"37px"}}>
                <TextField
                  id="userType"
                  // fullWidth
                  size="small"
                  // disableUnderline={false}
                  label={"Days"}
                  variant="outlined"
                  autoCapitalize="words"
                  select
                  style={{
                    // boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)",
                    autoCapitalize: "words",
                  }}
                  // className="textborder"
                  //   disabled={props.formDialog.divType === "edit"}
                  required
                  value={days}
                  onChange={(event) => setDays(event.target.value)}
                >
                  <MenuItem key={0} value={"day"}>
                    {"Days"}
                  </MenuItem>

                  <MenuItem key={1} value={"month"}>
                    {"Month"}
                  </MenuItem>
                  <MenuItem key={2} value={"year"}>
                    {"Year"}
                  </MenuItem>
                </TextField>
                </Stack>
              </Grid>
            </Stack>
          </Grid>
          <Stack>
            <Line options={options} data={data} />
            </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default TotalUsers;
