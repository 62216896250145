import { Stack, Typography } from "@mui/material";
import React from "react";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { FileIcon, defaultStyles } from "react-file-icon";

const ext = ["jpeg", "jpg", "png", "gif", "tiff", "svg"];

const Media = ({ media }) => {
  const downloadImage = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = media[0]?.url;
    downloadLink.download = media[0]?.name || "downloaded-image";
    downloadLink.setAttribute("target", "_blank"); // Ensure the link opens in a new tab
    downloadLink.style.display = "none"; // Hide the link
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

 
  return (
    <>
      {ext?.includes(media?.type?.toLowerCase()) ? (
        <img
          onClick={() => window.open(media?.url)}
          src={media?.url}
          style={{ width: "100%", borderRadius: "12px", cursor: "pointer" }}
          alt="img"
        />
      ) : (
        <Stack className="document">
          <Stack className="document__ext">
            <FileIcon
              extension={media[0]?.type}
              {...defaultStyles[media[0]?.type]}
            />
          </Stack>
          <Stack>
            <Typography variant="subtitle1">{media?.name}</Typography>
          </Stack>
          <PlayCircleOutlineIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(media[0]?.url);
            }}
          />
        </Stack>
      )}
    </>
  );
};

export default Media;
