import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Stack } from "@mui/system";
// import { Modal } from "antd"
import "./user.scss";

import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  TextareaAutosize,
} from "@material-ui/core";
import Comment from "./CommentModal";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "600px",
    // border:"none",
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ModalsReject(props) {
  console.log(props);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [reject, setReject] = useState(false);
  const [block, setBlock] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const hadelreject = () => {
    props.handelfunction();
    // props.hadelreject();
    setOpen(false);
  };
const handelblock=()=>{
    props.hadelreject();
    setOpen(false);
}
  const handleClose = () => {
    setOpen(false);
  };
  // const { confirm } = Modal;

  // function showDeleteConfirm() {
  //   confirm({
  //     title: "Are you sure Reject the User",
  //     content: "Reject the user",
  //     okText: "Yes",
  //     okType: "danger",
  //     cancelText: "No",
  //     onOk() {
  //      props.handelfunction();
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // }
  const handelclickno = () => {
    console.log("no");
  };
  return (
    <div>
    
      <Stack flexDirection={"row"} style={{ gap: "5px" }}>
        <Stack>
          <button
            className="approve"
            style={{
              backgroundColor:
                props?.data?.status === "reject" ? "#FF0000" : "#E5E5E5",
              color: props?.data?.status === "rejected" ? "white" : "black",
              border: props?.data?.status === "rejected" ? "none" : "black",
              borderRadius: "8px",
              fontWeight: "600",
              fontSize: "16px",
            }}
            onClick={() => {
              handleOpen();
              setReject(true);
              setBlock(false);
            }}
          >
            <Typography className="personaldetails">Reject</Typography>
          </button>
        </Stack>
        <Stack>
          <button
            className="approve"
            style={{
              backgroundColor:
                props?.data?.status === "blocked" ? "#444444" : "#E5E5E5",
              color: props?.data?.status === "blocked" ? "white" : "black",
              border: props?.data?.status === "blocked" ? "none" : "black",
              borderRadius: "8px",
              fontWeight: "600",
              fontSize: "16px",
            }}
            onClick={() => {
              handleOpen();
              setBlock(true);
              setReject(false);
            }}
          >
            <Typography className="personaldetails">Block</Typography>
          </button>
        </Stack>
      </Stack>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={{ borderRadius: "8px" }}>
            <h2>{reject ? "Reject User" : "Block User"}</h2>
            <p id="transition-modal-title"
            style={{fontWeight:"400",
            fontSize:"14px"}}>
              {reject
                ? "Are you sure want to Reject the user"
                : "Are you sure want to Block the user"}
            </p>
            {/* <TextField variant="outlined"></TextField> */}
            {reject?
            <Stack style={{ flexDirection: "row", gap: "10px" }}>
            {/* <Button
              variant="text"
              style={{ border: "none" }}
              onClick={hadelreject}
            >
              Yes
            </Button> */}
                  <Comment 
                          handelblock={hadelreject}
              handelCommit={props.handelCommit}
              setUserForm={props.setUserForm}
              userForm={props.userForm}
              data={console.log("krishna")}

              />
            <Button
              variant="text"
              style={{ border: "none" }}
              onClick={handleClose}
            >
              No
            </Button>
          </Stack>:
          <Stack style={{ flexDirection: "row", gap: "10px" }}>
              {/* <Button
                variant="text"
                style={{ border: "none" }}
                onClick={handelblock}
              >
                Yes
              </Button> */}
              <Comment handelblock={handelblock}
              handelCommit={props.handelCommit}
              setUserForm={props.setUserForm}
              userForm={props.userForm}

              />
              <Button
                variant="text"
                style={{ border: "none" }}
                onClick={handleClose}
              >
                No
              </Button>
            </Stack>}
            
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
