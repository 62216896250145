import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Typography } from '@material-ui/core';
import { Stack } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap:"15px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width:"80%",
    justifyContent:"space-evenly",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    overflow:"scroll",
    height:"70%",
    margin:"auto",
    rowGap:"10px",
  },
  clinicimg:{
    margin:"auto" ,
    width:"65%",
    boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)"
  },
  button:{
    width:"135px",
    height:"40px",
    borderRadius:"16px",
    marginLeft:"15px",
    background:"white",
    color:"#9900F0",
    border:"none",
    fontweight:600
  }

}));

export default function ModalReported(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const images=props?.reportedata
console.log(props,"images",images)
  return (
    <div>
        <Stack style={{flexDirection:"row",justifyContent:"center",
    }}>
            <Typography style={{marginLeft:"10px",marginTop:"10px"}}>Reported Images</Typography>

      {/* <button type="button" className={classes.button} >
        Clinic Images
      </button> */}
      <VisibilityIcon
                  style={{
                    margin: "auto",
                    color:"#FFDDB3",
                    fontSize:"35px",
                    marginTop:"05px"
                    //  marginTop:{lg:"40%"},
                    // marginLeft:{lg:"44%"},
                  }}
                  onClick={handleOpen}
                //   onClick={() => setVisible1(!visibile1)}
                />
                        </Stack>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
              {images?.map((el, index) =>
   
   (<img 
    className={classes.clinicimg}
    src={el?.attachment} key={index} alt=""/>))}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}