import React, { Component } from "react";
import General from "./components/general";
import AdminIndex from "./components/privateRouting/admin";
import PrivateRouteValidation from "./PrivateRouteValidation"; // for restricted access route
import { HashRouter, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
export default class App extends Component {
  render() {
    return (
      <div style={rootStyle}>
        <HashRouter>
          <Switch>
            <Route
              exact
              path={[
                "/",
                "/login",
                "/privacy-policy",
                "/user/password/generate",
                "/terms-and-conditions"
              ]}
              component={General}
            />

            <PrivateRouteValidation path={["/admin"]} component={AdminIndex} />
          </Switch>
        </HashRouter>
        <ToastContainer />
      </div>
    );
  }
}
const rootStyle = {
  overflowX: "hidden",
};
