import React from "react";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import { Typography } from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";
import { ArrowUpload24Filled } from "@fluentui/react-icons";
import { Stack } from "@fluentui/react";

const Upload = (props) => {
  const { createData, setCreateData } = props;

  const uploadFile = (props) => {
    APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      props
    ).then((response) => {
      if (response !== undefined && response !== null) {
        const attachmentUrl = {
          name: "",
          type: "",
          url: response.data.attachmentUrl[0],
          note: "",
        };
        setCreateData((prevData) => ({
          ...prevData,
          images: [...prevData.images, attachmentUrl],
        }));
      }
    });
  };

  const uploadFileHandler = (event) => {
    if (event.target.files.length === 0) return;

    [...event.target.files].forEach((file) => {
      let formData = new FormData();
      formData.append(`attachment`, file);
      formData.append("bucketName", "profile");
      uploadFile(formData);
    });
  };

  const deleteUpload = (index) => {
    const newAttachment = createData?.images.filter((value, i) => i !== index);
    setCreateData({
      ...createData,
      images: newAttachment,
    });
  };

  const openPDFInNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Stack style={{ gap: "4px", width: "100%", flexWrap: "wrap" }}>
      <Typography
        style={{ fontSize: "14px", fontWeight: "600", fontFamily: "Lato" }}
      >
        Upload Documents <span style={{ color: "red" }}>*</span>
      </Typography>
      <Stack
        horizontal
        style={{
          background: "#F9F9F9",
          padding: "16px",
          borderRadius: "8px",
          border: "1px solid #E5E5E5",
          overflowX: "auto",
          justifyContent: "space-between",
          minHeight: "142px",
        }}
      >
        <Stack style={{ flexWrap: "wrap" }}>
          <Stack horizontal gap={10} style={{ flexWrap: "wrap" }}>
            {/* Render images and PDFs */}
            {createData?.images &&
              createData?.images?.map((value, i) => (
                <Stack
                  style={{
                    position: "relative",
                    width: "117px",
                    marginBottom: "8px",
                  }}
                  key={i}
                >
                  <Stack
                    verticalAlign="center"
                    horizontalAlign="center"
                    style={{
                      width: "28px",
                      height: "28px",
                      background: "white",
                      position: "absolute",
                      borderRadius: "100%",
                      top: -8,
                      right: -5,
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon
                      onClick={() => deleteUpload(i)}
                      className="deleteIcon"
                      style={{ color: "#FF8D00" }}
                    />
                  </Stack>
                  {value?.url?.endsWith(".pdf") ? (
                    <>
                      <embed
                        src={value?.url}
                        alt="uploadDocuments"
                        style={{
                          objectFit: "cover",
                          borderRadius: "10px",
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                        }}
                        onClick={() => {
                          openPDFInNewTab(value?.url);
                        }}
                      />
                    </>
                  ) : (
                    <img
                      style={{
                        borderRadius: "10px",
                        width: "100%",
                        height: "100px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const newWindow = window.open("", "_blank");
                        if (newWindow) {
                          const imgElement = new Image();
                          imgElement.onload = function () {
                            newWindow.resizeTo(
                              1000,
                              (imgElement.height / imgElement.width) * 1000
                            );
                            imgElement.style.width = "1000px";
                            newWindow.document.body.appendChild(imgElement);
                          };
                          imgElement.src = value?.url;
                        } else {
                          console.error("Failed to open new window");
                        }
                      }}
                      src={value?.url}
                      alt="uploadDocuments"
                    />
                  )}
                </Stack>
              ))}
          </Stack>
        </Stack>
        <Stack>
          <Stack horizontalAlign="end" verticalAlign="end">
            <label htmlFor="file">
              <ArrowUpload24Filled style={{ cursor: "pointer" }} />
            </label>

            <input
              accept=".png, .jpg, .jpeg, .pdf"
              multiple
              id="file"
              type="file"
              style={{ display: "none" }}
              onChange={uploadFileHandler}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Upload;
