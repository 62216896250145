import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import APIRequest from "../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  Typography,
  Tooltip,
  IconButton,
  Popover,
} from "@material-ui/core";
import { connect } from "react-redux";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import DashboardQuery from "./DashboardQuery";
import { Stack } from "@mui/material";

import SnackbarUtils from "../../../../utils/SnackbarUtils";
import GlobalStatistics from "./GlobalStatistics";
import TotalSubscriptions from "./TotalSubscriptions";
import TypeofUsers from "./TypeofUsers";
import RegisteredUsers from "./RegisteredUsers";
import TotalUsers from "./TotalUsers";
import "./index.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "#F2F2F2",
    
  },
  backButton: {
    margin: 20,
  },
  userHeaderMargin: {
    marginTop: "-5%",
    position: "relative",
    left: "42%",
  },
  typographyQueryTitle: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14,
    alignSelf: "center",
  },
  userTypeMenuItem: {
    minWidth: "50%",
    marginTop: "6px%",
  },
}));

const queryParams = {
  startDate: new Date(new Date().setDate(1)),
  endDate: new Date(),
};

function Dashboard(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const [query, setQuery] = useState(queryParams);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const resetQuery = () => {
    setQuery(queryParams);
  };

  const open = Boolean(anchorEl);

  const handleQueryStartDates = (date) => {
    if (date > query.endDate) {
      return SnackbarUtils.error(
        props.t("toaster.startDateLesser"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }

    setQuery({
      ...query,
      startDate: date,
    });
  };
  const handleQueryEndDates = (date) => {
    if (date < query.startDate) {
      return SnackbarUtils.error(
        props.t("toaster.endDateLesser"),
        "bottomCenter",
        3000
      ).then((notification) => {
        props.publishNotification(notification);
      });
    }
    setQuery({
      ...query,
      endDate: date,
    });
  };

  return (
    <div className={classes.root}>
      <Stack className="" >
        <Grid
          container
          spacing={2}
          style={{ padding: "15px", borderRadius: "10px" }}
        >
          <Grid
            item
            xl={8}
            lg={8}
            md={8}
            sm={12}
            xs={12}
            style={{
              background: "white",
              borderRadius: "10px",
              padding: "15px",
              height:"270px",
              overflow:"auto",
            }}
          >
                  <Typography className="globalname">GLOBAL STATISTICS</Typography>

            <GlobalStatistics />
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            style={{
              background: "#F2F2F2",
              borderRadius: "10px",
              padding: "0px 15px 20px 20px",
               height:"380px"
            }}
            className="subscriptions"
          >
            <Stack
              style={{
                background: "white",
                borderRadius: "10px",
                padding: "15px",
              }}
            >
              <Typography className="globalname">
                Total Subscriptions
              </Typography>
              <TotalSubscriptions />
            </Stack>
          </Grid>
          <Grid
            item
            xl={8}
            lg={8}
            md={8}
            sm={12}
            xs={12}
            style={{
              background: "white",
              borderRadius: "10px",
              padding: "15px",
              marginTop: "-70px",
              // height:"530px"
            }}
            className="totalusers"
          >
            <Typography className="totalname">Total Users</Typography>
             <TotalUsers/>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            style={{
              background: "#F2F2F2",
              borderRadius: "10px",
              padding: "15px",
              marginTop: "30px",
              paddingBottom:"35px"
            }}
            className="typeofusers"
          >
            <Stack
              style={{
                background: "white",
                borderRadius: "10px",
                padding: "15px",
              }}
            >
              <Typography className="totalname">Type of Users</Typography>
               <TypeofUsers/>
            </Stack>
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              background: "#F2F2F2",
              borderRadius: "10px",
              padding: "15px",
              marginTop: "20px",
            }}
            className="registeredusers"
          >
            <Stack
              style={{
                background: "white",
                borderRadius: "10px",
                padding: "15px",
              }}
            >
              <RegisteredUsers />
            </Stack>
          </Grid>
        </Grid>
     
      </Stack>
    </div>
  );
}
const mapDispachToProps = (dispatch) => {
  return {
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(null, mapDispachToProps)(Dashboard)
);
