import React from "react";
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const DeleteLoomModal = (props) => {
  const { open, close, deleteLoom } = props;
  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            width: "420px",
            height: "167px",
          },
        }}
        open={open}
        onClose={close}
      >
        <DialogTitle>Are you sure you want to delete this loom ?</DialogTitle>
        <DialogActions>
          <Button
            style={{ position: "absolute", right: "100px", bottom: "1px" }}
            onClick={close}
            color="primary"
          >
            No
          </Button>
          <Button
            style={{ position: "absolute", bottom: "1px" }}
            onClick={() => deleteLoom()}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteLoomModal;
