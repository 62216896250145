import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  TextareaAutosize,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { Stack, Tooltip } from "@mui/material";
import landingImg from "../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
import "./dashboard.scss";
import "./total.scss";
import Users from "../../../../assets/images/Users.png";
import Enquiry from "../../../../assets/images/Enquiry.png";
import Revenue from "../../../../assets/images/Fee.png";
import Popover from "@mui/material/Popover";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FilterListIcon from "@mui/icons-material/FilterList";
const RegisteredUsers = () => {
  const myDate = new Date();
  const [startDate, setStartDate] = useState(myDate.setDate(-10));
  const [endDate, setEndDate] = useState(new Date());
  const [sector, setSector] = useState("");
  const [totalusers, setTotalUsers] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelstarDate = (newValue) => {
    setStartDate(newValue);
  };
  const handelendDate = (newValue) => {
    setEndDate(newValue);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  
  const Registered = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.registeredusers +
        "?startDate=" +
        `${new Date(startDate) / 1000}` +
        "&" +
        "endDate=" +
        `${new Date(endDate) / 1000} ` +
        "&" +
        //
        "sector=" +
        `${sector}`,
      ""
    ).then((response) => {
      let userResult = response?.data?.result;
      // setGlobalCount(userResult)
      setTotalUsers(userResult);
      // console.log(response);
    });
  };

  // console.log(totalusers, "totalusers");

  useEffect(() => {
    Registered();
  }, [startDate, endDate, sector]);
  // console.log(sector, "sector");
  return (
    <div>
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <Typography className="globalname">Registered Users</Typography>
        <Stack style={{marginRight:"20px"}}>
          <FilterListIcon
          fontSize="large"
            aria-describedby={id}
            //    variant="contained"
            onClick={handleClick}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Stack style={{ padding: "10px" }}>
              <Grid container>
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10} 
                style={{padding:"10px"}}
                >
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    {/* <Typography style={{color:"#BDBDBD"}}>Start Form</Typography> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Form"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        //  onChange={handelstarDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider> */}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{
                  width: 'auto',
                  minWidth: '100%',
                }}
                margin='normal'
                disableFuture={true}
                autoOk={true}
                id='startAt'
                label={"Start Date"}
                format='dd/MM/yyyy'
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                maxDate={
                  endDate !== null
                    ? new Date(endDate)
                    : new Date()
                } 
              />
            </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ marginTop: "15px" }}
                  >
                    {/* <Typography style={{color:"#BDBDBD"}}>End Form</Typography> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Form"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        //  onChange={handelstarDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider> */}
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{
                  width: 'auto',
                  minWidth: '100%',
                }}
                margin='normal'
                disableFuture={true}
                autoOk={true}
                id=''
                 label={"End Date"}
                format='dd/MM/yyyy'
                value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        minDate={
                          startDate !== null
                            ? new Date(startDate )
                            : new Date(0)
                        } 
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ marginTop: "15px" }}
                  >
                    <TextField
                      id="userType"
                      fullWidth
                      //    size="small"
                      // disableUnderline={false}
                      label={"Sector"}
                      variant="outlined"
                      autoCapitalize="words"
                      select
                      style={{
                        // boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)",
                        autoCapitalize: "words",
                      }}
                      // className="textborder"
                      //   disabled={props.formDialog.divType === "edit"}
                      required
                      value={sector}
                      onChange={(event) => setSector(event.target.value)}
                    >
                      <MenuItem key={0} value={"handloom"}>
                        {"Handloom"}
                      </MenuItem>

                      <MenuItem key={1} value={"powerloom"}>
                        {"Powerloom"}
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
            {/* <Typography style={{ padding: "10px" }}>The content of the Popover.</Typography> */}
          </Popover>
        </Stack>
      </Stack>
      <Stack style={{ marginTop: "10px" }}>
        <Grid Container>
          <Stack
            style={{
              background: "#FFE9CE",
              paddingBottom: "17px",
              flexDirection: "row",
              borderRadius:"4px",
              //   justifyContent: "space-evenly",
            }}
          >
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              style={{justifySelf:"center"}}
            >
              <Typography className="gridname" 
              style={{marginTop:"10px"}}
              >Categories</Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}
                          style={{justifySelf:"center"}}

            >
              <Typography
                className="gridname"
                style={{ textAlign: "center", marginRight: "30px",marginTop:"7px" }}
              >
                Number of Users
              </Typography>
            </Grid>
          </Stack>
        </Grid>
      </Stack>

      <Grid Container style={{ background: " #F2F2F2" ,height:"400px",overflow:"auto" }}>
        {Object.entries(totalusers).map(function (key, index) {
          return (
            // console.log(key[1],"totalusers")
            <Stack
              flexDirection={"row"}
              style={{
                marginTop: "3px",
                borderRadius: "4px",
                boxShadow: "initial",
                background: "white",
                padding: "10px",
              }}
            >
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                style={{ borderSpacing: "4px", paddingLeft: "60px" }}
              >
                <Typography className="keys">{key[0]}</Typography>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography className="keys" style={{ textAlign: "center" }}>
                  {key[1]}
                </Typography>
              </Grid>
            </Stack>
          );
        })}
        {/* {totalusers && totalusers.length>0 && totalusers?.map((option)=>{
                return(console.log(option,"totalusers")
                )
            })} */}
      </Grid>
    </div>
  );
};

export default RegisteredUsers;
