import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  TextareaAutosize,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { Stack } from "@mui/material";
import landingImg from "../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
import "./dashboard.scss";
import Cash from "../../../../assets/images/Cash.png";
import Enquiry from "../../../../assets/images/Enquiry.png";
import Revenue from "../../../../assets/images/Fee.png";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);
 
const TypeofUsers = () => {
    const [users,setUsers]=useState("")
    const[both,setBoth]=useState("")

    const TotalUsers = () => {
        APIRequest.request(
          "GET",
          ConfigAPIURL.totalUsers,
          ""
        ).then((response) => {
          let userResult = response?.data?.result;
          // setGlobalCount(userResult)
          setUsers(userResult);
          setBoth(userResult?.["handloom","powerloom"])
          // console.log(response);
        });
      };
      console.log(both,"both")
      // console.log(users,"Total users")
      useEffect(()=>{
TotalUsers()
      },[])
// const both=users?.handloom,powerloom
    const data = {
        labels: ['Handloom', 'PowerLoom','Both' ],
        datasets: [
          {
            label: '# of Votes',
            data: [users?.handloom,users?.powerloom,both?both:<></>],
            backgroundColor: [
              'rgba(250, 234, 177, 1)',
              'rgba(229, 186, 115, 1)',
              'rgba(210, 156, 105, 1)',
            ],
            borderColor: [
                'rgba(250, 234, 177, 1)',
                'rgba(229, 186, 115, 1)',
                'rgba(210, 156, 105, 1)',

            ],
            borderWidth: 1,
          },
        ],
      };
  return (
    <div>
<Stack style={{marginTop:"40px",paddingBottom:"20px"}}>
<Pie data={data} />
{/* krishna */}
</Stack>
    </div>
  )
}

export default TypeofUsers
