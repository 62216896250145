import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Checkbox,
  Box,
  styled,
  TextareaAutosize,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { Stack } from "@mui/material";
import "./post.scss";
import landingImg from "../../../../assets/images/Pic.png";
import DownloadIcon from "@mui/icons-material/Download";
import ModalReported from "./ModalReported";

const ReportedBy = (props) => {
  const [reportedata, setReportedData] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  // console.log(props);

  useEffect(() => {
    APIRequest.request(
      "GET",
      `${ConfigAPIURL.reportedByPost}?postId=${props?.rowDetails?._id}`,
      ""
    ).then((response) => {
      let userResult = response?.data?.result;
      setReportedData(userResult);
      console.log(response);
    });
  }, [props?.rowDetails?._id]);

  const handlePreviewImage = () => {
    setPreviewImage();
  };

  const handleClosePreview = () => {
    setPreviewImage();
  };

  return (
    <div>
      {reportedata?.map((option) => (
        <div key={option?.id}>
          <Stack
            style={{
              backgroundColor: "#FFECD3",
              height: "62px",
            }}
          >
            <Stack style={{ paddingLeft: "20px", marginTop: "10px" }}>
              <Typography style={{ fontSize: "20px", fontWeight: "700" }}>
                Reported By
              </Typography>
            </Stack>
          </Stack>

          <Stack
            className="k3"
            style={{
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
              },
              marginTop: "30px",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "12px" }}
              >
                <TextField
                  variant="outlined"
                  id="workingHours"
                  label={"Name"}
                  autoComplete="something-unsupported"
                  required
                  value={option?.userId?.name}
                  size="small"
                  className="textborder"
                />
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "12px" }}
              >
                <TextField
                  variant="outlined"
                  id="experience"
                  label={"Category"}
                  autoComplete="something-unsupported"
                  required
                  value={option?.userId?.occupationType}
                  size="small"
                  className="textborder"
                />
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{ padding: "12px" }}
              >
                <TextField
                  variant="outlined"
                  id="street"
                  className="textborder"
                  size="small"
                  label={"Phone Number"}
                  autoComplete="something-unsupported"
                  required
                  value={option?.userId?.mobileNo}
                  fullWidth
                />
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  <TextField
                    rows={3}
                    multiline
                    variant="outlined"
                    id="street"
                    className="textborder"
                    size="small"
                    InputProps={{
                      style: {
                        width: "100%",
                        marginLeft: "15px",
                        // paddingLeft: "10px",
                        border: "none",
                        boxShadow: "0px 1px 2px #c8c8c8",
                        borderRadius: "10px",
                      },
                    }}
                    label={"Description"}
                    autoComplete="something-unsupported"
                    required
                    value={option?.description}
                    fullWidth
                  />
                </Grid>
                {/* Reported Images */}
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "15px" }}
                >
                  {option?.attachment?.name && (
                    <Box
                      sx={{
                        width: "190px",
                        height: "54px",
                        background: "#FFFFFF",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={handlePreviewImage}
                    >
                      <span>{option.attachment.name}</span>
                    </Box>
                  )}
                </Grid>

                <Dialog onClose={handleClosePreview} open={previewImage}>
                  <DialogContent style={{ overflow: "hidden", width: "442px" }}>
                    {option && option.length > 0 && (
                      <>
                        {option.map((image) => (
                          <img
                            key={image.id}
                            src={image.url}
                            style={{
                              width: "170px",
                              objectFit: "contain",
                              borderRadius: "13px",
                              bottom: "7px",
                              height: "136px",
                            }}
                            alt=""
                          />
                        ))}
                      </>
                    )}
                  </DialogContent>
                </Dialog>
              </Grid>
            </Grid>
          </Stack>
        </div>
      ))}
    </div>
  );
};

export default ReportedBy;
