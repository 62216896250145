import React from 'react';
import AWS from 'aws-sdk';
// import { Modal, ProgressBar } from 'react-bootstrap';

import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
// import Toaster from '../utils/Toaster';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessKeyId: this.props.aws.accessKeyId,
      secretAccessKey: this.props.aws.secretAccessKey,
      region: this.props.aws.region,
      progress: 0,
      modelShow: false,
    };
    this._s3Upload = this._s3Upload.bind(this);
  }
  handleModalClose = () => {
    this.setState({
      modelShow: false,
    });
  };
  _s3Upload = () => {
    this.setState({ modelShow: true }, () => {
      AWS.config.update({
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: this.props.aws.CognitoIdentityCredentials
            .IdentityPoolId,
        }),
        region: this.props.aws.CognitoIdentityCredentials.region,
      });
      var s3 = new AWS.S3({ signatureVersion: 'v4' });
      let fileExtension = this.props.file.name.split('.');
      var filename =
        fileExtension[0] +
        Math.floor(Date.now() / 1000) +
        '.' +
        fileExtension[fileExtension.length - 1];
      var params = {
        Bucket: this.props.bucket,
        Key: filename.replace(/\s+/g, '_').replace(/-/g, '_'),
        ContentType: this.props.file.type,
        ACL: 'public-read',
        Body: Buffer.from(this.props.base64, 'base64'),
        ContentEncoding: 'base64',
      };

      var request = s3.putObject(params);
      request
        .on('httpUploadProgress', (progress) => {
          let progressValue = Math.ceil(
            (progress.loaded / progress.total) * 100
          );
          this.setState({
            progress: progressValue,
          });
        })
        .on('success', (response) => {
          this.setState({ modelShow: false, progress: 0 }, () => {
            // Toaster.sucess('Successfully uploaded', 'topRight');
            this.props.callback(
              'https://' +
                response.request.httpRequest.endpoint.hostname +
                response.request.httpRequest.path
            );
          });
        })
        .on('error', (error, response) => {
          console.log(error);
          // Toaster.error("File upload failed, try after some time", "topRight");
        })
        .on('complete', (response) => {})
        .send();
    });
  };
  _s3FileDelete = async (fileName) => {
    AWS.config.update({
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.props.aws.CognitoIdentityCredentials
          .IdentityPoolId,
      }),
      region: this.props.aws.CognitoIdentityCredentials.region,
    });
    var s3 = new AWS.S3({ signatureVersion: 'v4' });
    let file = fileName.split('/');
    var params = {
      Bucket: this.props.bucket,
      Key: file[file.length - 1],
    };
    var request = s3.deleteObject(params);
    return request
      .on('success', (response) => {
        return 'success';
      })
      .on('error', (error, response) => {
        return 'error';
      })
      .on('complete', (response) => {
        return 'complete';
      })
      .send();
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.state.modelShow}
          TransitionComponent={Transition}
          keepMounted
          fullWidth={true}
          maxWidth={'md'}
          onClose={this.handleModalClose}
        >
          <DialogTitle id='alert-dialog-slide-title'>
            Progress {this.state.progress}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              <LinearProgress
                variant='determinate'
                value={this.state.progress}
              />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
