import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Stack } from "@mui/system";
// import { Modal } from "antd"
import "./user.scss";

import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  TextareaAutosize,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "600px",
    // border:"none",
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function Comment(props) {
    console.log("blocked")

  console.log(props);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [reject, setReject] = useState(false);
  const [block, setBlock] = useState(false);
const[comment,setComment]=useState(false)
const[adminCommit,setAdminCommit]=useState(props?.userForm?.commentByAdmin)
  const handleOpen = () => {
    setOpen(true);
  };
  const hadelreject = () => {
    console.log("kumar")
    // props.handelfunction();
    // props.hadelreject();
    setOpen(true);
  };
  const submitdata=()=>{
    console.log("jasvanth")
   props.handelCommit(true) ;
   props.handelblock()
   setOpen(false);


  }

  const handleClose = () => {
  };
  // const { confirm } = Modal;

  // function showDeleteConfirm() {
  //   confirm({
  //     title: "Are you sure Reject the User",
  //     content: "Reject the user",
  //     okText: "Yes",
  //     okType: "danger",
  //     cancelText: "No",
  //     onOk() {
  //      props.handelfunction();
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // }
  const handelclickno = () => {
    console.log("no");
  };
  return (
    <div>
    
      <Stack flexDirection={"row"} style={{ gap: "5px" }}>
        <Stack>
        <Button
                variant="text"
                style={{ border: "none" }}
                onClick={hadelreject}
              >
                Yes
              </Button>
        </Stack>
        
      </Stack>

{      
  
  <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={{ borderRadius: "8px" }}>
            {/* <h2>{reject ? "Reject User" : "Block User"}</h2> */}
<Grid Container>
    <Grid item        xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}>
    <TextField
                          variant="outlined"
                          id="workReference"
                        //   size="small"
                          className="textborder"

                          multiline
                          maxRows={4}
                          label={"Remarks"}
                          autoComplete="something-unsupported"
                          required
                        //   value={adminCommit}
                          value={props?.userForm?.commentByAdmin}
                          fullWidth
                        //   onKeyPress={(event) => {
                        //     return (event.charCode >= 65 &&
                        //       event.charCode <= 90) ||
                        //       (event.charCode >= 97 && event.charCode <= 122) ||
                        //       event.charCode === 32
                        //       ? event
                        //       : event.preventDefault();
                        //   }}
                          onChange={(event) =>
                            props.setUserForm({
                              ...props.userForm,
                              commentByAdmin : event.target.value,
                            })
                            // setAdminCommit(event.target.value)
                          }
                        //   disabled={
                        //     props.formDialog.divType === "new" ||
                        //     props.formDialog.divType === "edit"
                        //       ? false
                        //       : true
                        //   }
                        />


    </Grid>
</Grid>
            
            <Stack style={{ gap: "10px" ,margin:"auto",width:"120px"}}>
            <Button
              variant="text"
              style={{ border: "none" }}
         onClick={submitdata}
            >
            submit
            </Button>
            
          </Stack>
          </div>
        </Fade>
      </Modal>
      
      }

    </div>
  );
}
