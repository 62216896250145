import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Button,
  Box,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import APIRequest from "../../../../utils/APIRequest";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Input } from "@material-ui/core";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";

const EditableCell = ({
  value,
  onChange,
  isDropdown,
  dropdownOptions,
  suffix,
}) => {
  const [editing, setEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClick = () => {
    setEditing(true);
  };

  const handleBlur = () => {
    setEditing(false);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    // Check suffix for specific input requirements
    if (suffix === "/day" || suffix === "ppl") {
      if (!/^\d*$/.test(inputValue)) {
        return;
      }
    }

    // Update the input value
    event.target.value = inputValue;
    onChange(event);
  };

  const handleRadioChange = (event) => {
    const inputValue = event.target.value;
    const syntheticEvent = {
      target: {
        value: inputValue,
      },
    };
    onChange(syntheticEvent);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.blur(); // Trigger handleBlur when Enter key is pressed
    }
  };

  return (
    <TableCell onClick={handleClick} style={{ fontFamily: "Lato" }}>
      {editing ? (
        isDropdown ? (
          <Select
            value={value}
            onChange={onChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          >
            {dropdownOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        ) : suffix === "" ? (
          <RadioGroup
            value={value}
            onChange={handleRadioChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        ) : (
          <input
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
        )
      ) : (
        <>
          {value}
          {suffix && ` ${suffix}`}
          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        </>
      )}
    </TableCell>
  );
};

const EditableTable = (props) => {
  console.log(props, "from editable table");
  const [data, setData] = useState([]);
  const [featuresList, setFeaturesList] = useState([]);
  const [addColumnDialogOpen, setAddColumnDialogOpen] = useState(false);
  const [newColumnName, setNewColumnName] = useState("");
  const [planType, setPlanType] = useState([]);
  const [hoveredPlan, setHoveredPlan] = useState(null);

  const [editTitleDialogOpen, setEditTitleDialogOpen] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [editingPlanId, setEditingPlanId] = useState(null);

  useEffect(() => {
    fetchData();
    fetchFeaturesData();
    fetchPlanTypeData();
  }, []);

  // Getting the planType
  const fetchPlanTypeData = async () => {
    try {
      const response = await APIRequest.request(
        "GET",
        ConfigAPIURL.getplantype,
        ""
      );
      const planTypeData = response.data.result.planType;
      const planTypes = planTypeData.map((item) => item.title);
      // console.log(planTypes);
      setPlanType(planTypes);
    } catch (error) {
      // console.error(error);
    }
  };

  // Getting all Plans Data
  const fetchData = async () => {
    try {
      const response = await APIRequest.request(
        "POST",
        ConfigAPIURL.plans,
        JSON.stringify({ active: true })
      );

      const rows = response.data.rows;

      setData(rows);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  const fetchFeaturesData = () => {
    try {
      APIRequest.request("GET", ConfigAPIURL.getFeatures, "").then((res) => {
        const updatedFeaturesList = [
          ...res.data.rows.map((feature) => {
            const updatedFeature = { ...feature };
            if (feature.suffix && feature.active) {
              updatedFeature.suffix = feature.suffix;
            }
            return updatedFeature;
          }),
          { _id: "planType", feature: "Plan Type" },
          { _id: "price", feature: "Price" },
          { _id: "validity", feature: "Validity" },
          { _id: "discount", feature: "Discount (%)" },
          { _id: "taxPercentage", feature: "Tax Percentage" },
          { _id: "offerPrice", feature: "Discounted Price" },
        ];

        setFeaturesList(updatedFeaturesList);
      });
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  // console.log("printing the featuresList", featuresList);

  const handleValueChange = (planIndex, featureIndex, event) => {
    const newData = [...data];

    if (!newData[planIndex]) {
      newData[planIndex] = { featuresList: [] };
    }

    const feature = featuresList[featureIndex];

    if (feature.featureCode) {
      if (newData[planIndex].featuresList.length > featureIndex) {
        newData[planIndex].featuresList[featureIndex].feature = feature.feature;
        newData[planIndex].featuresList[featureIndex].featureCode =
          feature.featureCode;
        newData[planIndex].featuresList[featureIndex].value =
          event.target.value;
        newData[planIndex].featuresList[featureIndex].suffix = feature.suffix;

        if (feature.featureCode === 10 && event.target.value === "0") {
          newData[planIndex].featuresList[featureIndex].value = "Unlimited";
        } else {
          newData[planIndex].featuresList[featureIndex].value =
            event.target.value;
        }
      } else {
        newData[planIndex].featuresList.push({
          feature: feature.feature,
          featureCode: feature.featureCode,
          value: event.target.value,
          suffix: feature.suffix || "",
        });
      }
    } else {
      if (feature._id === "planType") {
        newData[planIndex].planType = event.target.value;
      } else if (feature._id === "price") {
        newData[planIndex].price = event.target.value;
      } else if (feature._id === "discount") {
        newData[planIndex].discount = event.target.value;
      } else if (feature._id === "validity") {
        if (newData[planIndex].planType === "starter") {
          const inputValue = event.target.value;

          // Allow only numerical input
          if (!/^\d*$/.test(inputValue)) {
            return;
          }

          // Restrict the value to a maximum of 30
          const value = parseInt(inputValue);
          const maxValidity = 30;
          if (value > maxValidity) {
            // Set the error message
            setErrorMessage(`Validity cannot be greater than ${maxValidity}`);
            setData(newData);
            return;
          }
        }

        newData[planIndex].validity = event.target.value;
      } else if (feature._id === "offerPrice") {
        newData[planIndex].offerPrice = event.target.value;
      } else if (feature._id === "taxPercentage") {
        newData[planIndex].taxPercentage = event.target.value;
      }
    }

    newData[planIndex].updated = true;
    setData(newData);
  };

  const handleEditTitleConfirm = () => {
    const newData = data.map((plan) => {
      if (plan._id === hoveredPlan) {
        // console.log(plan._id)
        return { ...plan, title: editedTitle };
      }
      return plan;
    });

    setData(newData);
    setEditTitleDialogOpen(false);
  };

  const handleUpdateClick = async () => {
    try {
      const updatedPlans = data.map((plan) => {
        if (plan.updated) {
          const updatedFeaturesList = plan.featuresList
            .filter((feature) => feature.featureCode)
            .map((feature) => {
              const matchedFeature = featuresList.find(
                (fl) => fl._id === feature.active
              );
              if (matchedFeature && matchedFeature.suffix) {
                return {
                  ...feature,
                  suffix: matchedFeature.suffix,
                };
              }
              return feature;
            });

          const updatedPlan = {
            ...plan,
            featuresList: updatedFeaturesList,
          };

          // Add or update planType, price, validity, discount properties
          featuresList.forEach((feature) => {
            if (feature._id === "planType" && plan.planType) {
              updatedPlan.planType = plan.planType;
            } else if (feature._id === "price" && plan.price) {
              updatedPlan.price = plan.price;
            } else if (feature._id === "validity" && plan.validity) {
              updatedPlan.validity = plan.validity;
            } else if (feature._id === "discount" && plan.discount) {
              updatedPlan.discount = plan.discount;
            } else if (feature._id === "offerPrice" && plan.offerPrice) {
              updatedPlan.offerPrice = plan.offerPrice;
            } else if (feature._id === "taxPercentage" && plan.taxPercentage) {
              updatedPlan.taxPercentage = plan.taxPercentage;
            }
          });

          return updatedPlan;
        }
        return plan;
      });

      const requestBody = {
        plans: updatedPlans.filter((plan) => plan),
      };

      await APIRequest.request(
        "POST",
        ConfigAPIURL.plansupdate,
        JSON.stringify(requestBody)
      );
      fetchData();

      SnackbarUtils.sucess("Plan's Data Updated Successfully", 3000).then(
        (notification) => {
          props.publishNotification(notification);
        }
      );
    } catch (error) {
      SnackbarUtils.error("Error while updating", 3000).then((notification) => {
        props.publishNotification(notification);
      });
    }
  };

  const handleAddColumn = () => {
    setAddColumnDialogOpen(true);
    setNewColumnName("");
  };

  const handleAddDialogClose = () => {
    setAddColumnDialogOpen(false);
  };

  const handleAddColumnConfirm = () => {
    if (!featuresList) {
      return;
    }

    const newPlan = {
      active: true,
      title: newColumnName,
      planType: "basic",
      description: "A simple Basic plan",
      discount: 20,
      taxPercentage: 18,
      offerPrice: "",
      price: 100,
      validity: 365,
      featuresList: featuresList
        .filter((feature) => feature.active) // Include only features with featureCode
        .map((feature) => ({
          feature: feature.feature,
          value: feature.value,
          featureCode: feature.featureCode,
          suffix: feature.suffix,
          active: true,
        })),
    };

    const newData = [...data, newPlan];
    setData(newData);
    handleAddDialogClose();
    SnackbarUtils.sucess("Column Added Successfully", 3000).then(
      (notification) => {
        props.publishNotification(notification);
      }
    );
  };
  // console.log("385", data);
  const handleDeleteClick = async (planId) => {
    try {
      const updatedPlans = data.map((plan) => {
        if (plan._id === planId) {
          return { ...plan, active: false };
        }
        return plan;
      });

      const requestBody = {
        plans: updatedPlans.filter((plan) => plan),
      };

      await APIRequest.request(
        "POST",
        ConfigAPIURL.plansupdate,
        JSON.stringify(requestBody)
      );
      fetchData();
      // console.log("Plan deleted successfully");
      SnackbarUtils.sucess("Plan deleted successfully", 3000).then(
        (notification) => {
          props.publishNotification(notification);
        }
      );
    } catch (error) {
      SnackbarUtils.error("Error while Deleting Plan", 3000).then(
        (notification) => {
          props.publishNotification(notification);
        }
      );
    }
  };

  const handlePlanHeaderMouseEnter = (planId) => {
    setHoveredPlan(planId);
  };

  const handlePlanHeaderMouseLeave = () => {
    setHoveredPlan(null);
  };

  const tableHeaderStyle = {
    background: "#FF8D00",
    fontWeight: "bold",
    marginBottom: "8px",
    fontFamily: "Lato",
    cursor: "pointer",
    color: "#fff",
  };

  const tableCellStyle = {
    width: "350px",
    fontFamily: "Lato",
    border: "1px solid #ddd",
    padding: "16px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
    margin: "8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  //editing the plan's Header

  const handlePlanNameDoubleClick = (planId) => {
    setEditingPlanId(planId); // Set the editingPlanId state variable when double-clicked
  };

  const handlePlanNameBlur = (event, planId) => {
    const newPlanName = event.target.value;
    const newData = data.map((plan) => {
      if (plan._id === planId) {
        return { ...plan, title: newPlanName }; // Save the edited plan name
      }
      return plan;
    });

    setData(newData);
    setEditingPlanId(null); // Reset the editingPlanId state variable
    SnackbarUtils.sucess(
      props.t("Plan's Title Edited Successfully"),
      3000
    ).then((notification) => {
      props.publishNotification(notification);
    });
  };
  const handlePlanNameChange = (event, planId) => {
    const newPlanName = event.target.value;
    const newData = data.map((plan) => {
      if (plan._id === planId) {
        return { ...plan, title: newPlanName }; // Update the plan name
      }
      return plan;
    });

    setData(newData);
    SnackbarUtils.sucess("Plan's data Updated Successfully", 3000).then(
      (notification) => {
        props.publishNotification(notification);
      }
    );
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <TableContainer
        component={Paper}
        style={{
          fontFamily: "Lato",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        <Table style={{ fontFamily: "Lato" }}>
          <TableHead style={{ fontFamily: "Lato", background: "#FF8D00" }}>
            <TableRow style={{ fontFamily: "Lato" }}>
              <TableCell style={tableHeaderStyle}>Features</TableCell>
              {data.map((plan) => (
                <TableCell
                  key={plan._id}
                  style={tableHeaderStyle}
                  onMouseEnter={() => setHoveredPlan(plan._id)}
                  onMouseLeave={() => setHoveredPlan(null)}
                  onDoubleClick={() => handlePlanNameDoubleClick(plan._id)}
                >
                  {editingPlanId === plan._id ? ( // Conditionally render an input field for editing
                    <input
                      value={plan.title}
                      onChange={(event) =>
                        handlePlanNameChange(event, plan._id)
                      }
                      onBlur={(event) => handlePlanNameBlur(event, plan._id)}
                      autoFocus
                    />
                  ) : (
                    <>
                      {plan.title}

                      {hoveredPlan === plan?._id &&
                        plan?.isDeletable && ( // Display edit and delete icons on hover
                          <>
                            <IconButton
                              onClick={() => handleDeleteClick(plan._id)}
                            >
                              <DeleteIcon style={{ color: "#17024b " }} />
                            </IconButton>
                          </>
                        )}
                    </>
                  )}
                </TableCell>
              ))}
              <TableCell style={tableHeaderStyle}>
                <IconButton onClick={handleAddColumn}>
                  <LocalHospitalOutlinedIcon style={{ color: "#17024b " }} />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody style={{ fontFamily: "Lato" }}>
            {featuresList.length > 0 && (
              <>
                {featuresList.map((feature, featureIndex) => (
                  <TableRow key={feature._id}>
                    <TableCell style={tableCellStyle}>
                      {feature.featureCode !== 10 && feature.feature}
                      {feature.featureCode === 10 && (
                        <>
                          {feature.feature}
                          <p style={{ fontSize: "12px", marginTop: "4px" }}>
                            (Enter "0" for unlimited)
                          </p>
                        </>
                      )}
                    </TableCell>
                    {data.map((plan, planIndex) => {
                      const column = plan.featuresList[featureIndex] || {};
                      const isDefaultColumn =
                        feature._id === "price" ||
                        feature._id === "validity" ||
                        feature._id === "discount" ||
                        feature._id === "offerPrice" ||
                        feature._id === "taxPercentage";
                      const value = isDefaultColumn
                        ? plan[feature._id]
                        : column.value;

                      return (
                        <React.Fragment key={plan._id}>
                          {feature._id === "planType" ? (
                            <TableCell style={tableCellStyle}>
                              <select
                                value={plan.planType}
                                disabled={!plan?.isEditable}
                                onChange={(event) =>
                                  handleValueChange(
                                    planIndex,
                                    featureIndex,
                                    event
                                  )
                                }
                              >
                                {planType
                                  ?.filter((plan) => plan === "Regular")
                                  .map((plan, index) => (
                                    <option key={index} value={plan}>
                                      {plan}
                                    </option>
                                  ))}
                              </select>
                            </TableCell>
                          ) : (
                            <EditableCell
                              value={value}
                              onChange={(event) =>
                                handleValueChange(
                                  planIndex,
                                  featureIndex,
                                  event
                                )
                              }
                              suffix={feature.suffix}
                              isDropdown={feature._id === "planType"}
                              dropdownOptions={planType}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        justifyContent="flex-end"
        marginTop="16px"
        position="fixed"
        bottom="16px"
        right="16px"
      >
        <Button
          variant="contained"
          style={{ background: "#17024B", color: "white" }}
          color="primary"
          onClick={handleUpdateClick}
        >
          Update
        </Button>
      </Box>
      <Dialog open={addColumnDialogOpen} onClose={handleAddDialogClose}>
        <DialogTitle>Add Column</DialogTitle>
        <DialogContent>
          <TextField
            value={newColumnName}
            onChange={(event) => setNewColumnName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose}>Cancel</Button>
          <Button
            onClick={handleAddColumnConfirm}
            variant="contained"
            color="primary"
            disabled={!newColumnName}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editTitleDialogOpen}
        onClose={() => setEditTitleDialogOpen(false)}
      >
        <DialogTitle>Edit Title</DialogTitle>
        <DialogContent>
          <TextField
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditTitleDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleEditTitleConfirm} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    shortcutKeyValue: state.shortcutKeyValue,
    sideDrawerData: state.sideDrawerData,
  };
};
const mapDispachToProps = (dispatch) => {
  return {
    shortcutKey: (shortcutKeyValue) =>
      dispatch({ type: "SHORTCUTKEY", value: shortcutKeyValue }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};
export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(EditableTable)
);
