import React ,{useState,useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  MenuItem,
} from '@material-ui/core';
import APIRequest from '../../../../utils/APIRequest';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TableQuery(props) {
  const classes = useStyles();
  const [specializationOptions, setSpecializationOptions] = useState([]);

  useEffect(() => {
    APIRequest.request(
      'GET',
      ConfigAPIURL.classifiedsget
    ).then((response) => {
      console.log(response)
      if (response !== undefined && response !== null) {
        if (
          response.code === 100 && response.data.responseCode === 109
        ) {
            setSpecializationOptions(response.data.result);
          }
        }
      }
    );
  }, []);
  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t('query.activeStatus')}
              select
              defaultValue={props.query.active}
              value={props.query.active}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              style={{ width: 'auto', minWidth: '90%', marginBottom: 10 }}
            >
              <MenuItem key={0} value={true}>
                {props.t('query.active')}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t('query.inActive')}
              </MenuItem>
            </TextField>
          </Grid>
          {/* <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
                  id='specialization'
                  options={specializationOptions}
                  value={props.query.specialization}
                  freeSolo
                  getOptionLabel={(option) => option}
                  onChange={(event, value) =>
                    props.setQuery({
                      ...props.query,
                      specialization: value,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      label={props.t('doctor.specialization')}
                      variant='standard'
                    />
                  )}
                  />
          </Grid>
        */}
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation('translations')(TableQuery);
