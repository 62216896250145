import React, { useContext, useEffect, useRef } from "react";
import RecievedMsg from "./RecievedMsg";
import { CircularProgress, Stack } from "@mui/material";
import SenderMsg from "./SenderMsg";
import { DataContext } from "../index";

const MainChatArea = ({
  conversation,
  fetchMessages,
  hasMoreConverstion,
  selectedChat,
}) => {
  const { userData } = useContext(DataContext);
  const scrollRef = useRef(null);

  useEffect(() => {
    // this is for the pagination in chats
    const element = scrollRef.current;
    let currentPage = 0; // resets the current page whenever the selected chat changes
    const handleScroll = (e) => {
      const totalScrollHeight = element.scrollHeight - 750; // this is scroll total height of the div
      if (Math.abs(element.scrollTop) > totalScrollHeight) {
        // if the user has reached 800px height away from top the loading starts
        currentPage++;
        fetchMessages(selectedChat?.requestedBy?._id, currentPage);
      }
    };
    element.addEventListener("scroll", handleScroll);
    return () => element.removeEventListener("scroll", handleScroll);
  }, [selectedChat]);

  return (
    <Stack
      className="scrollsmall"
      style={{
        padding: "1rem 1rem",
        flexGrow: "2",
        flexDirection: "column-reverse",
        gap: 2,
        overflowY: "scroll",
        height: "400px",
      }}
      ref={scrollRef}
    >
      {conversation &&
        conversation?.length > 0 &&
        conversation
          ?.slice()
          ?.reverse()
          ?.map((value, ind) => {
            let showProfile = true;
            let showTime = true;
  
            // Determine the number of messages between the current message and the next sender's message
            const messagesBetween = conversation
              .slice(0, ind)
              .filter((msg) => msg.sendUserId === userData?._id).length;
  
            if (messagesBetween >= 3) {
              showProfile = false;
            }
  
            // checks the next index time is the same
            const nextTime = new Date(
              conversation[ind - 1]?.time * 1000
            ).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            });
  
            // checks the current index time is the same
            const currentTime = new Date(value?.time * 1000).toLocaleTimeString(
              "en-US",
              {
                hour: "2-digit",
                minute: "2-digit",
              }
            );
  
            if (nextTime === currentTime) {
              // if a message is sent at the same time, it's not required to display
              showTime = false;
            }
  
            let date;
  
            if (
              ind !== 0 &&
              new Date(
                conversation[ind + 1]?.time * 1000
              ).toLocaleDateString() !==
                new Date(value?.time * 1000).toLocaleDateString()
            ) {
              date = new Date(value?.time * 1000).toLocaleDateString("en-IN");
            }
  
            return (
              <React.Fragment key={ind}>
                {value?.sendUserId === userData?._id ? (
                  <SenderMsg
                    content={value?.msg}
                    image={value?.profileImage}
                    lastMessage={showProfile}
                    isMedia={value?.isMedia}
                    media={value?.media}
                    time={value?.time}
                    showTime={showTime}
                  />
                ) : (
                  <RecievedMsg
                    content={value?.msg}
                    image={value?.profileImage}
                    lastMessage={showProfile}
                    isMedia={value?.isMedia}
                    media={value?.media}
                    time={value?.time}
                    showTime={showTime}
                  />
                )}
                {date && <Stack className="date__time--order">{date}</Stack>}
              </React.Fragment>
            );
          })}
      <Stack alignItems="center">
        {hasMoreConverstion && <CircularProgress />}
      </Stack>
    </Stack>
  );
};

export default MainChatArea;
