import React from "react";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";

const useUpload = (handleMedia) => {
  const mediaUploadPart = (media) => {
    media &&
      media.forEach((value) => {
        const type = (value && value.split(".").pop()) || "";
        const name = (value && value.split("/").pop()) || "";

        const data = {
          type,
          name,
          url: value || "",
        };
        handleMedia(data);
      });
  };

  const fileUpload = async (e) => {
    const formdata = new FormData();
    const files = e.target.files;
    files &&
      files.forEach((file) => {
        formdata.append("attachment", file);
        formdata.append("bucketName", "files");
      });

    const response = await APIRequest.multipartForDataRequest(
      "PUT",
      ConfigAPIURL.uploadFile,
      formdata
    );
    if (response?.data?.responseCode === 109) {
      mediaUploadPart(response?.data?.attachmentUrl); //successfully uploaded as array
    } else return false; // an error occured
  };
  return { fileUpload };
};

export default useUpload;
