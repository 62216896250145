import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Box,
  styled,
  TextareaAutosize,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import APIRequest from "../../../../utils/APIRequest";
import FormValidation from "../../../../utils/FormValidation";
import ConfigAPIURL from "../../../../config/ConfigAPIURL";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";
import { Stack } from "@mui/material";
import "./planStack.scss";
import set from "date-fns/set";
import DateConversion from "../../../../utils/DateConversion";

const PlanStack = (props) => {
  const{plandata,setPlanData,planhistory,setPlanHistory,activecount,setActiveCount ,data}=props;
  // const [plandata, setPlanData] = useState([]);
  // const [planhistory,setPlanHistory]=useState([])
  // const[activecount,setActiveCount]=useState([])
  const Planhistory = () => {
    APIRequest.request(
      "GET",
      ConfigAPIURL.userhistoryplans + "?userId=" + `${data?._id}`,
      "",
    ).then((response) => {
      let userResult = response?.data;
      userResult["startAt"] =
            DateConversion.unixToLocaleDate(
              userResult["startAt"]
            );
            userResult["expireAt"] =
            DateConversion.unixToLocaleDate(
              userResult["expireAt"]
            );
      setPlanData(userResult);
      setPlanHistory(userResult?.planHistory)
      setActiveCount(userResult?.planHistory?.active)
      console.log(response, "response");
    });
  };
  useEffect(() => {
    Planhistory();
  }, []);
  const expiresOn = new Date(planhistory?.expireAt * 1000);

  console.log(planhistory.startAt,"plandata")
  return (
    <div>
      <Stack style={{ padding: "15px", }}>
        {planhistory && planhistory.length>0 && planhistory.map((option,ind)=>
        { return(
           
<Stack
          style={{
            background: "white",
            boxShadow: " 0px 1px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            marginTop:"10px"
            
          }}
        >
          <Stack flexDirection={"row"} justifyContent={"space-between"}
          padding={"10px"}>
            <Stack className="planbox">
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography className="plantypename">
                  {/* {userForm?.planName} */}
                 {option?.planName}
                </Typography>
              </Stack>
            </Stack>

            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignSelf={"center"}
              className="active"
            >
              <Stack
                className="radio"
                alignSelf={"center"}
                style={{
                  width: "7.96px",
                  height: "7.64px",
                  background: option?.active===true?"green":"#ADADAD",
                  borderRadius: "50px",
                  marginLeft:"10px"
                }}
              >

              </Stack>
              <Stack 
              style={{marginRight:"15px"}}
              >{option?.active===true?"Active":"InActive"}</Stack>
            </Stack>
          </Stack>
          <Stack justifyContent={"center"}
          padding={"5px"} 
          style={{marginLeft:"5px"}}>
            <Typography className="plantypename1">{"₹"+option?.subscribedPrice}</Typography>
          </Stack>

          <Stack style={{ padding:"5px",marginLeft:"5px" }} 
          flexDirection={"row"} justifyContent={"space-between"}
          >
            <Stack flexDirection={"row"}  alignItems={"center"}>
              
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "14px",

                  color: " #868686",
                }}
              >
                Starts on :
              </Typography>
              <Typography fontWeight={600}>
                { DateConversion.unixToLocaleDate(option?.startAt)}
              </Typography>
            </Stack>
            <Stack flexDirection={"row"}  alignItems={"center"}
            style={{ marginRight:"10px",}}
            >
             
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "14px",
                 
                  color: " #868686",
                }}
              >
                Expires on :
              </Typography>
              <Typography fontWeight={600}
              style={{fontSize:"14px",marginLeft:"3px"}}
              >
                {/* {(option?.expireAt*1000)} */}
                {DateConversion.unixToLocaleDate (option?.expireAt)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        )
        })}
        
      </Stack>
    </div>
  );
};

export default PlanStack;
