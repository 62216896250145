class TableHeader {
  /* -------------------------------- INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER --------------------------------
    
        id:- Use for uniquely identify each column in data table,
        accessor: - Use to locate the record from json objcet, If want to access the record from the child, use . operator.
                    Example:- 
                                1> name, is from parent, ( {name:'name'} )
                                2> owner.name, where owner is parent & name is inside owner object, ( owner:{name:'name'} )
        dataType:- This value can be string, number, boolean, date. This use for alignment of table header & table body.
                    1> string & date - left (handle in DataTable.js)
                    2> boolean- center (handle in DataTable.js)
                    3> number-  right (handle in DataTable.js)
        sortable:- Use to enable & disable sorting for perticuler column.
        disablePadding:- Use to enable padding.
        visibility:- Use to toggle column visibility.
        width:- Use to set individual column min-width in px. It's should be number format.
        label:- Refer the values from translations file, (./src/translations/**.js).
        className:- It's basically the js file name & all the file should be available in utils folder.
        functionName:-  It's basically the function name, which should be available inside above className 
        
    ----------------------------- END OF INSTRUCTION TO USE & DEFINE A DATA-TABLE COLUMN HEADER -----------------------------*/

  static adminTableHeader = [
    // {
    //   id: 'name',
    //   accessor: 'name',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 150,
    //   label: 'classifides.post-id',
    //   className: '',
    //   functionName: '',
    // },
    // {
    //   id: 'isClinic',
    //   accessor: 'isClinic',
    //   dataType: 'Boolean',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 150,
    //   label: 'classifides.name',
    //   className: 'StringUtils',
    //   functionName: 'isClinic',
    // },
    // {
    //   id: 'consultationFee',
    //   accessor: 'consultationFee',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 40,
    //   label: 'classifides.profecion',
    //   className: '',
    //   functionName: '',
    // },
    // {
    //   id: 'contact',
    //   accessor: 'contact.officeNo',
    //   dataType: 'date',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 150,
    //   label: 'classifides.postType',
    //   className: '',
    //   functionName: '',
    //   subMenu: [
    //     {
    //       id: 'contact',
    //       accessor: 'contact.officeNo',
    //       dataType: 'date',
    //       sortable: true,
    //       disablePadding: false,
    //       visibility: true,
    //       width: 150,
    //       label: 'classifides.job',
    //       className: '',
    //       functionName: '',
    //       subMenu: [],

    //     },
    //     {
    //       id: 'contact',
    //       accessor: 'contact.officeNo',
    //       dataType: 'date',
    //       sortable: true,
    //       disablePadding: false,
    //       visibility: true,
    //       width: 150,
    //       label: 'classifides.services',
    //       className: '',
    //       functionName: '',
    //       subMenu: [],

    //     },
    //   ],

    // },
    {
      id: "logo",
      accessor: "logo",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 150,
      label: "nekaramitracategory.photo",
      className: "",
      functionName: "",
    },
    {
      id: "category",
      accessor: "occupationType",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 160,
      label: "nekaramitrausers.category",
      className: "",
      functionName: "",
    },
    {
      id: "userType",
      accessor: "sector",
      dataType: "string",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 200,
      label: "nekaramitrausers.sector",
      className: "",
      functionName: "",
    },
    // {
    //   id: 'state',
    //   accessor: 'state',
    //   dataType: 'string',
    //   sortable: true,
    //   disablePadding: false,
    //   visibility: true,
    //   width: 150,
    //   label: 'classifides.state',
    //   className: '',
    //   functionName: '',
    // },

    {
      id: "createdAt",
      accessor: "createdAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 50,
      label: "nekaramitracategory.createdAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
    {
      id: "updatedAt",
      accessor: "updatedAt",
      dataType: "date",
      sortable: true,
      disablePadding: false,
      visibility: true,
      width: 50,
      label: "nekaramitracategory.updatedAt",
      className: "DateConversion",
      functionName: "unixToLocaleDate",
    },
  ];
}
export default TableHeader;
