import { Stack, Typography } from "@mui/material";
import React from "react";
import {DateConvert} from "../../../../../utils/DataConvert";
import Media from "./Media";

const SenderMsg = ({
  content,
  image,
  lastMessage,
  isMedia,
  media,
  time,
  showTime,
}) => {
  // this is the sended message component

  return (
    <Stack>
      <Stack
        style={{
          flexDirection: "row",
          justifyContent: "end",
          gap: 8,
          alignItems: "start",
        }}
      >
        <Stack
          className="recievermsg sendermsg"
          style={{
            // flexDirection: "row",
            position: "relative",
            padding: isMedia && "10px 10px",
            borderTopRightRadius: lastMessage && 0,
          }}
        >
          {isMedia ? <Media media={media} /> : content}
          {showTime && (
            <Typography
              variant="subtitle1"
              className="chat__time"
              style={{ alignSelf: "end", paddingLeft: "40px" }}
            >
              {DateConvert.formattedDate(time)}
            </Typography>
          )}
        </Stack>
        {
          <Stack
            style={{
              width: "28px",
              height: "28px",
              borderRadius: "12px",
              overflow: "hidden",
              visibility: lastMessage ? "visible" : "hidden",
              // background: "#D8D8D8",
            }}
          >
            <img src={image} alt="" style={{ objectFit: "cover" }} />
          </Stack>
        }
      </Stack>
    </Stack>
  );
};

export default SenderMsg;
