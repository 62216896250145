import { Grid } from "@mui/material";
import Create from "./Create";
import History from "./History";
import React, { useEffect, useState } from "react";

export const DataContext = React.createContext();

const Mylooms = (props) => {
  const {
    edit,
    data,
    setUserForm,
    userForm,
    userId,
    showNotification,
    getEditable,
  } = props;

  const [activeTab, setActiveTab] = useState("create");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <DataContext.Provider
      value={{
        edit,
        data,
        setUserForm,
        userForm,
        userId,
        showNotification,
        getEditable,
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%", // Ensure container fills its parent
        }}
      >
        <div
          style={{
            display: "flex",
            width: "240px",
            height: "51px",
            borderRadius: "42px",
            border: "1px solid #268581",
            backgroundColor: "#fff",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              width: "122px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              border: "none",
              outline: "none",
              borderRadius: activeTab === "create" ? "42px 0 0 42px" : "-1px",
              backgroundColor: activeTab === "create" ? "#17024B" : "inherit",
              color: activeTab === "create" ? "#fff" : "inherit",
            }}
            onClick={() => handleTabClick("create")}
          >
            <p style={{ fontSize: "16px", fontWeight: "600" }}>Create</p>
          </div>

          <div
            style={{
              width: "127px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              border: "none",
              outline: "none",
              borderRadius: "0 42px 42px 0",
              backgroundColor: activeTab === "history" ? "#17024B" : "inherit",
              color: activeTab === "history" ? "#fff" : "inherit",
            }}
            onClick={() => handleTabClick("history")}
          >
            <p style={{ fontSize: "16px", fontWeight: "600" }}>History</p>
          </div>
        </div>
      </Grid>

      <Grid
        style={{
          display: activeTab === "create" ? "block" : "none",
          width: "100%",
          padding: "20px",
        }}
      >
        <Create
          edit={edit}
          data={data}
          userForm={userForm}
          setUserForm={setUserForm}
          userId={userId}
          showNotification={showNotification}
          activeTab={activeTab}
        />
      </Grid>
      <Grid
        style={{
          display: activeTab === "history" ? "block" : "none",
          width: "100%",
          padding: "20px",
        }}
      >
        <History
          edit={edit}
          data={data}
          userForm={userForm}
          setUserForm={setUserForm}
          showNotification={showNotification}
          getEditable={getEditable}
          userId={userId}
          activeTab={activeTab}
        />
      </Grid>
    </DataContext.Provider>
  );
};

export default Mylooms;
