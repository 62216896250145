import React from "react";
import useImageUpload from "./useImageUpload";
import ReactDOM from "react-dom";

import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@mui/icons-material/Add";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";

import {
  Stack,
  Typography,
  Dialog,
  Box,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import pdf from "../../../../assets/images/pdfIcon.png";
import { useState } from "react";
import Item from "antd/es/list/Item";

const ImagesUpload = ({ setUserForm, userForm }) => {
  const [previewImageIndex, setPreviewImageIndex] = useState(null);
  const handlePreviewImage = (index) => {
    setPreviewImageIndex(index);
  };

  const handleClosePreview = () => {
    setPreviewImageIndex(null);
  };
  const { onUpload, deleteImages } = useImageUpload(
    setUserForm,
    userForm,
    handleClosePreview
  );
  // console.log(userForm);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [openFileUrl, setOpenFileUrl] = useState(null);

  const Icon = () => {
    return <img src={pdf} alt="pdf" width={24} height={24} />;
  };

  const handlePreviewVideo = (video) => {
    setPreviewVideo(video.url || video);
    setPreviewImage(null);
  };

  const handleOpenFile = (attachment) => {
    setOpenFileUrl(attachment.url);
  };

  const handleDownloadFile = (attachment) => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(7);
    const fileName = `file_${timestamp}_${randomString}.pdf`;

    const link = document.createElement("a");
    link.href = attachment.url;
    link.download = fileName;
    link.click();
  };

  const handleClosePreview2 = () => {
    setOpenFileUrl(null);
  };

  const displayImage = userForm?.media?.[0];
  const handleOpenImageInNewTab = (imageUrl) => {
    const newTab = window.open("", "_blank");
    newTab.document.write(
      `<html><body style="margin: 0;"><img src="${imageUrl}" style="width: 100%; height: 100%; object-fit: contain;"></body></html>`
    );
  };

  // const openPDFINNewTab = (pdf) => {
  //   const newTab = window.open();
  //   newTab.document.write(`
  //     <html>
  //       <body style="margin: 0;">
  //         <iframe src=${pdf} width="100%" height="100%" style="border: none;">
  //           <p>Your browser does not support PDFs. <a href=${pdf}>Download the PDF</a></p>
  //         </iframe>
  //       </body>
  //     </html>
  //   `);
  // };

  const openPDFINNewTab = (pdf) => {
    window.open(pdf, "_blank");
  };

  return (
    <Stack my={2} px={2}>
      <Stack direction="row">
        <Stack
          style={{
            display: displayImage ? "none" : "block", // Hide the div if an image is uploaded
            alignSelf: "center",
            position: "relative",
            left: "-14px",
            backgroundColor: "#FFEBD4",
            height: "112px",
            width: "216px",
            borderRadius: "13px",
            border: "1px dashed #FF8D00",
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              position: "relative",
              top: "-25px",
              fontWeight: "bold",
              fontSize: "13px",
            }}
          >
            Uploads
          </Typography>
          <input
            accept="image/*"
            style={{ display: "none" }}
            //   className={classes.input}\
            multiple
            id="icon-button-file"
            type="file"
            onChange={onUpload}
          />
          <label htmlFor="icon-button-file">
            <AddIcon
              style={{
                cursor: "pointer",
                position: "relative",
                top: "12%",
                left: "38%",
                backgroundColor: "whitesmoke",
                borderRadius: "100%",
                height: "38px",
                width: "38px",
                color: "orange",
              }}
            />
          </label>
        </Stack>
      </Stack>

      {displayImage && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            backgroundColor: "rgb(230 230 204)",
            height: " 93px",
            width: "110px",
            borderRadius: "13px",
            position: "relative",
          }}
        >
          <Box
            style={{
              width: "221px",
              height: "137px",
              background: "#F9F9F9",
              borderRadius: "8px",
            }}
          >
            <img
              src={displayImage.url}
              alt=""
              style={{
                width: "170px",
                objectFit: "contain",
                borderRadius: "13px",
                bottom: "7px",
                height: "136px",
              }}
            />
          </Box>
          {userForm?.media?.length > 0 && (
            <>
              <Button
                id="preview"
                onClick={() => handlePreviewImage(0)}
                style={{
                  backgroundColor: "orange",
                  color: "white",
                  textTransform: "none",
                  fontSize: "11px",
                  borderRadius: "5px",
                  position: "absolute",
                  bottom: "-1rem",
                  left: "8rem",
                }}
              >
                <VisibilityOutlinedIcon
                  style={{
                    fontSize: "15px",
                  }}
                />
                Preview
              </Button>
              <DeleteIcon
                style={{
                  cursor: "pointer",
                  backgroundColor: "whitesmoke",
                  borderRadius: "100%",
                  height: "36px",
                  width: "36px",
                  color: "orange",
                  position: "absolute",
                  left: "9rem",
                  top: "-1rem",
                }}
                onClick={() => deleteImages(0)}
              />
            </>
          )}
        </Stack>
      )}

      <Dialog open={previewImageIndex !== null} onClose={handleClosePreview}>
        <DialogContent style={{ overflow: "hidden", width: "442px" }}>
          <Carousel
            showThumbs={true}
            showStatus={false} // Hide the carousel numbers
            showArrows={true} // Hide the arrows
            infiniteLoop
            selectedItem={previewImageIndex}
            style={{ overflow: "hidden" }}
            renderIndicator={(clickHandler, isSelected, index) => (
              <span
                style={{
                  background: isSelected ? "black" : "green",
                  width: "6px",
                  height: "6px",
                  display: "inline-block",
                  borderRadius: "50%",
                  cursor: "pointer",
                  margin: "0 4px",
                }}
                onClick={clickHandler}
                key={index}
              />
            )}
          >
            {userForm?.media?.map((item, index) => (
              <div key={index} style={{ position: "relative" }}>
                {item.type === "jpg" ||
                item.type === "jpeg" ||
                item.type === "png" ? (
                  <>
                    <div
                      style={{
                        width: "412px",
                        height: "231px",
                        overflowY: "scroll",
                        scrollbarWidth: "none", // For Firefox
                        msOverflowStyle: "none", // For Internet Explorer and Edge
                      }}
                    >
                      <img
                        src={item.url}
                        alt=""
                        style={{
                          width: "412px",
                          borderRadius: "7px",
                          objectFit: "cover",
                          display: "block",
                        }}
                      />
                      <style>
                        {`
      div::-webkit-scrollbar {
        display: none;
      }
    `}
                      </style>
                    </div>
                    <Button
                      onClick={() => handleOpenImageInNewTab(item.url || item)}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "0px",
                        color: "black",
                        background: "bisque",
                      }}
                    >
                      <CenterFocusStrongIcon />
                    </Button>
                  </>
                ) : (
                  <video
                    src={item.url || item}
                    controls
                    style={{
                      width: "412px",
                      height: "231px",
                      borderRadius: "7px",
                      border: "2px solid",
                      objectFit: "cover",
                    }}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "39px",
                    width: "39px",
                    borderRadius: "100%",
                    position: "relative",
                    left: "361px",
                    top: "-31px",
                  }}
                >
                  <DeleteIcon
                    style={{
                      backgroundColor: "whitesmoke",
                      borderRadius: "100%",
                      height: "36px",
                      width: "36px",
                      color: "orange",
                      cursor: "pointer",
                    }}
                    onClick={() => deleteImages(index)}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </DialogContent>
        <DialogContentText>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "15px",
              marginTop: "10px",
              position: "relative",
              top: "-23px",
              paddingLeft: "20px",
              width: "443px",
            }}
          >
            {userForm?.description}
          </Typography>
        </DialogContentText>
        <Dialog open={Boolean(openFileUrl)} onClose={handleClosePreview2}>
          <DialogContentText>
            <iframe
              src={openFileUrl}
              title="Preview"
              width="100%"
              height="500px"
            />
          </DialogContentText>
        </Dialog>
        {userForm.attachment.map((attachment, index) => (
          <Stack
            key={index}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ mt: 1 }}
          >
            <Box
              sx={{
                width: "190px",
                height: "54px",
                background: "#FFFFFF",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              style={{
                position: "relative",
                left: "-155px",
                top: "-8px",
                overflowWrap: "breakWord",
                wordWrap: "breakWord",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  wordBreak: "break-all",
                  padding: "12px 14px",
                }}
              >
                <div style={{ marginRight: "5px" }}>
                  <Icon />
                </div>
                <div>{attachment.name}</div>
              </span>
            </Box>
            <Button
              onClick={() => {
                window.open(attachment.url, "_blank");
              }}
              // onClick={()=>openPDFINNewTab("https://www.pdf995.com/samples/pdf.pdf")}
              style={{
                background: "orange",
                color: "white",
                borderRadius: "6px",
                cursor: "pointer",
                minWidth: "30px",
                minHeight: "30px",
                padding: "2px",
                position: "relative",
                left: "-13px",
              }}
            >
              <VisibilityOutlinedIcon fontSize="small" />
            </Button>
            <Button
              onClick={() => handleDownloadFile(attachment)}
              style={{
                background: "orange",
                color: "white",
                borderRadius: "6px",
                cursor: "pointer",
                minWidth: "30px",
                minHeight: "30px",
                padding: "2px",
                position: "relative",
                left: "-13px",
              }}
            >
              <FileDownloadOutlinedIcon fontSize="small" />
            </Button>
          </Stack>
        ))}
        <DialogContentText>
          <Stack>
            <Typography
              item
              sm={3}
              style={{
                display: "flex",
                color: "blue",
                cursor: "pointer",
                textDecoration: "underline",
                overflowWrap: "breakWord",
                wordWrap: "breakWord",
                width: "300px",
                marginBottom: "7px",
                margin: "0 0 4rem 2rem",
              }}
              onClick={() => {
                const url = userForm?.referenceUrl;
                if (
                  url &&
                  !url.startsWith("http://") &&
                  !url.startsWith("https://")
                ) {
                  window.open(`https://${url}`, "_blank");
                } else {
                  window.open(url, "_blank");
                }
              }}
            >
              {userForm?.referenceUrl}
            </Typography>
          </Stack>
        </DialogContentText>
      </Dialog>
    </Stack>
  );
};

export default ImagesUpload;
