import React, {useState, useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  Paper,
  TextField,
  MenuItem,
} from '@material-ui/core';
import APIRequest from '../../../../utils/APIRequest';
import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TableQuery(props) {
  const classes = useStyles();
  
  const [specializationOptions, setSpecializationOptions] = useState([]);

  useEffect(() => {
    APIRequest.request(
      'GET',
      ConfigAPIURL.getSpecialization
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (
          response.code === 100 && response.data.responseCode === 109
        ) {
            setSpecializationOptions(response.data.result);
          }
        }
      }
    );
  }, []);


  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          {/* <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
              label={props.t('query.activeStatus')}
              select
              defaultValue={props.query.active}
              value={props.query.active}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              style={{ width: 'auto', minWidth: '90%', marginBottom: 10 }}
            >
              <MenuItem key={0} value={true}>
                {props.t('query.active')}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t('query.inactive')}
              </MenuItem>
            </TextField>
          </Grid> */}
          {/* <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              label={props.t('query.activeStatus')}
              select
              defaultValue={props.query.active}
              value={props.query.active}
              onChange={(event) =>
                props.setQuery({ ...props.query, active: event.target.value })
              }
              style={{ width: 'auto', minWidth: '90%', marginBottom: 10 }}
            >
              <MenuItem key={0} value={true}>
                {props.t('query.active')}
              </MenuItem>
              <MenuItem key={1} value={false}>
                {props.t('query.inactive')}
              </MenuItem>
            </TextField>
          </Grid> */}
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="startDate"
                  fullWidth
                  maxDate={
                    props.query.endDate !== null
                      ? new Date(props.query.endDate * 1000)
                      : new Date()
                  }
                  label={props.t("query.startFrom")}
                  format="dd/MM/yyyy"
                  // defaultValue={props.query.startDate !== null ? new Date(props.query.startDate * 1000) : null}
                  defaultValue={
                    props.query.startDate !== null
                      ? new Date(props.query.startDate * 1000)
                      : null
                  }
                  value={
                    props.query.startDate !== null
                      ? new Date(props.query.startDate * 1000)
                      : null
                  }
                  style={{ marginTop: "-0px", maxWidth: 200 }}
                  // onChange={(event) =>
                  //   props.setQuery({
                  //     ...props.query,
                  //     startDate: Math.floor(new Date(event).getTime() / 1000.0),
                  //   })
                  // }
                  onChange={(date) =>
                    props.setQuery({
                      ...props.query,
                      startDate: Math.floor(
                        new Date(
                          new Date(new Date(date).setHours(0)).setMinutes(0)
                        ).setSeconds(0) / 1000
                      ),
                    })
                  }
                  // InputProps={{
                  //   disabled: true,
                  // }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="endDate"
                  fullWidth
                  minDate={
                    props.query.startDate !== null
                      ? new Date(props.query.startDate * 1000)
                      : new Date(0)
                  }
                  label={props.t("query.endBefore")}
                  format="dd/MM/yyyy"
                  defaultValue={
                    props.query.endDate !== null
                      ? new Date(props.query.endDate * 1000)
                      : null
                  }
                  value={
                    props.query.endDate !== null
                      ? new Date(props.query.endDate * 1000)
                      : null
                  }
                  // value={new Date}
                  style={{ marginTop: "-0px", maxWidth: 200 }}
                  // onChange={(event) =>
                  //   props.setQuery({
                  //     ...props.query,
                  //     endDate: Math.floor(new Date(event).getTime() / 1000.0),
                  //   })
                  // }
                  onChange={(date) =>
                    props.setQuery({
                      ...props.query,
                      endDate: Math.floor(
                        new Date(
                          new Date(new Date(date).setHours(23)).setMinutes(59)
                        ).setSeconds(59) / 1000
                      ),
                    })
                  }
                  // InputProps={{
                  //   disabled: true,
                  // }}

                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        

          


        {/* <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
                  id='specialization'
                  options={specializationOptions}
                  value={props.query.specialization}
                  freeSolo
                  getOptionLabel={(option) => option}
                  onChange={(event, value) =>
                    props.setQuery({
                      ...props.query,
                      specialization: value,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      label={props.t('doctor.specialization')}
                      variant='standard'
                    />

                  )}
                />
        </Grid> */}
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation('translations')(TableQuery);
