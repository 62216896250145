import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  innerPadding: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TableQuery(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container className={classes.innerPadding}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{
                  width: 'auto',
                  minWidth: '100%',
                }}
                margin='normal'
                autoOk={true}
                disableFuture={true}
                id='startAt'
                label={props.t('query.startDate')}
                format='dd/MM/yyyy'
                value={props.query.startDate}
                onChange={(date) =>
                  props.handleQueryStartDates(date, 'startDate')
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{
                  width: 'auto',
                  minWidth: '100%',
                }}
                margin='normal'
                disableFuture={true}
                autoOk={true}
                id='endAt'
                label={props.t('query.endDate')}
                format='dd/MM/yyyy'
                value={props.query.endDate}
                onChange={(date) => props.handleQueryEndDates(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
export default withTranslation('translations')(TableQuery);
