import React from "react";
import PaymentIcon from "@mui/icons-material/Payment";

import {
  Board24Filled,
  Board24Regular,
  People24Filled,
  People24Regular,
  CommentEdit24Filled,
  CommentEdit24Regular,
  ShareAndroid24Regular,
  ShareAndroid24Filled,
  PeopleMoney24Filled,
  PeopleMoney24Regular,
  PeopleCall24Filled,
  PeopleCall24Regular,
  PeopleCheckmark24Filled,
  PeopleCheckmark24Regular,
  Settings24Filled,
  Settings24Regular,
  Headphones24Regular,
  Headphones24Filled,
  Megaphone24Regular,
  Megaphone24Filled,
} from "@fluentui/react-icons";

class SideBarMenu {
  static menu = [
    {
      display: "sideNavMenu.dashboard",
      link: "/admin/dashboard",
      linkPage: false,
      onclick: null,
      urlEndWith: "dashboard",
      className: "",
      permissionLevel: "Dashboard",
      filledIcon: <Board24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <Board24Regular style={{ color: "black" }} />,
      eventKey: "dashboard",
      subMenu: [],
    },
    {
      display: "sideNavMenu.user",
      link: "/admin/users",
      linkPage: false,
      onclick: null,
      urlEndWith: "users",
      className: "",
      permissionLevel: "Users",
      filledIcon: <People24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <People24Regular style={{ color: "black" }} />,
      eventKey: "users",
      color: "white",
      subMenu: [],
    },
    {
      display: "sideNavMenu.post",
      link: "/admin/posts",
      linkPage: false,
      onclick: null,
      urlEndWith: "posts",
      className: "",
      permissionLevel: "Posts",
      // icon: <FaEdit style={{ color: "black" }} />,
      filledIcon: <CommentEdit24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <CommentEdit24Regular style={{ color: "black" }} />,
      eventKey: "posts",
      color: "white",
      subMenu: [],
    },
    {
      display: "sideNavMenu.category",
      link: "/admin/category",
      linkPage: false,
      onclick: null,
      urlEndWith: "category",
      className: "",
      permissionLevel: "Categories",
      filledIcon: <ShareAndroid24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <ShareAndroid24Regular style={{ color: "black" }} />,
      eventKey: "category",
      subMenu: [],
    },

    {
      display: "sideNavMenu.plans",
      link: "/admin/plans",
      linkPage: false,
      onclick: null,
      urlEndWith: "plans",
      className: "",
      permissionLevel: "Plans",
      // icon: <SubscriptionsIcon style={{ color: "black" }} />,
      filledIcon: <PeopleMoney24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <PeopleMoney24Regular style={{ color: "black" }} />,
      eventKey: "plans",
      subMenu: [],
    },
    {
      display: "sideNavMenu.recharge",
      link: "/admin/recharge",
      linkPage: false,
      onclick: null,
      urlEndWith: "recharge",
      className: "",
      permissionLevel: "Recharge",
      filledIcon: (
        <PaymentIcon style={{ color: "#FF8D00" }} />
      ),
      regularIcon: <PaymentIcon style={{ color: "black" }} />,
      eventKey: "recharge",
      subMenu: [],
    },
    {
      display: "Customer Service",
      link: "/admin/customer",
      linkPage: false,
      onclick: null,
      urlEndWith: "customer",
      className: "",
      permissionLevel: "Customer",
      filledIcon: (
        <Headphones24Filled
          style={{ color: "#FF8D00" }}
        />
      ),
      regularIcon: <Headphones24Regular style={{ color: "black" }} />,
      eventKey: "recharge",
      subMenu: [],
    },

    {
      display: "sideNavMenu.advertisement",
      link: "/admin/advertisement",
      linkPage: false,
      onclick: null,
      urlEndWith: "advertisement",
      className: "",
      permissionLevel: "Advertisement",
      regularIcon: <Megaphone24Regular style={{ color: "black" }}/>,
      filledIcon: <Megaphone24Filled style={{ color: "#FF8D00" }}/>,
      eventKey: "advertisement",
      subMenu: [],
    },
    {
      // Divider Area
    },
    {
      display: "sideNavMenu.security",
      link: "/admin/security/role",
      linkPage: false,
      onclick: null,
      urlEndWith: "role",
      className: "",
      permissionLevel: "Role",
      filledIcon: <PeopleCall24Filled style={{ color: "#FF8D00" }} />,
      regularIcon: <PeopleCall24Regular style={{ color: "black" }} />,
      eventKey: "security",
      subMenu: [
        {
          display: "sideNavMenu.role_function",
          link: "/admin/security/role",
          linkPage: false,
          onclick: null,
          urlEndWith: "role",
          className: "",
          permissionLevel: "Role",
          regularIcon: <PeopleCheckmark24Regular style={{ color: "black" }} />,
          filledIcon: <PeopleCheckmark24Filled style={{ color: "#FF8D00" }} />,
          eventKey: "role",
        },
      ],
    },
    {
      // Divider Area
    },
    {
      display: "sideNavMenu.setting",
      link: "/admin/setting",
      linkPage: false,
      onclick: null,
      urlEndWith: "setting",
      className: "",
      permissionLevel: "",
      regularIcon: <Settings24Regular style={{ color: "black" }} />,
      filledIcon: <Settings24Filled style={{ color: "#FF8D00" }} />,
      eventKey: "setting",
      subMenu: [],
    },
  ];
}
export default SideBarMenu;
