import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./Login";
import PasswordGenaration from "./PasswordGenaration";
import HomePage from "./HomePage/HomePage";
import PrivacyPolicy from "./HomePage/PrivacyPolicy";
import TermsAndConditons from "./HomePage/Terms&Conditions";
export default class Content extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={["/"]} component={HomePage} />
        <Route exact path={["/privacy-policy"]} component={PrivacyPolicy} />
        <Route
          exact
          path={["/terms-and-conditions"]}
          component={TermsAndConditons}
        />
        <Route exact path={["/login"]} component={Login} />

        <Route
          exact
          path={["/user/password/generate"]}
          component={PasswordGenaration}
        />

        <Route render={() => <h3>Coming soon.</h3>} />
      </Switch>
    );
  }
}
