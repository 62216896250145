class ConfigAPIURL {
  //Base URL For Live & Staging
  static baseUrl =
    process.env.REACT_APP_PayDefination_BaseUrl ||
    process.env.REACT_APP_Dev_PayDefination_BaseUrl;

  //List of Pay Defination APIs and request method type
  static sessionValidation = this.baseUrl + "/admin/islogin"; //get
  static accountLogin = this.baseUrl + "/admin/accountLogin";
  static verifyOtp = this.baseUrl + "/admin/verify/otp";
  static resendOtp = this.baseUrl + "/admin/user/resend/otp";
  static userLogout = this.baseUrl + "/admin/logout";

  static adminGeneratePassword = this.baseUrl + "/admin/password/generate";

  static forgotPassword = this.baseUrl + "/admin/user/forgot/password";
  static resetPassword = this.baseUrl + "/admin/reset/password";

  //Admin APIS
  static roleTableURL = this.baseUrl + "/admin/roles/all"; //post
  static roleTitles = this.baseUrl + "/admin/roles/titles"; //post
  static roleDetails = this.baseUrl + "/admin/role/details"; //post
  static roleCreate = this.baseUrl + "/admin/role/create"; //post
  static roleUpdate = this.baseUrl + "/admin/role/permission/update"; //post
  static roleUnassigned = this.baseUrl + "/admin/role/permission/unassigned"; //post
  static roleGrant = this.baseUrl + "/admin/role/permission/grant"; //post

  //  User Table API
  static userTableURL = this.baseUrl + "/admin/list/users"; //post
  static userCreate = this.baseUrl + "/admin/user/add"; //post
  static userUpdate = this.baseUrl + "/admin/user/profile/update"; //post
  static userDelete = this.baseUrl + "/admin/user/profile/delete"; //post
  static userDetails = this.baseUrl + "/admin/list/user/details"; //post
  static useravgrating = this.baseUrl + "/admin/list/user/review/ratings"; //GET
  static userratingsbyid = this.baseUrl + "/admin/list/user/review/details"; // GET
  static userhistoryplans = this.baseUrl + "/admin/user/subscription/history";

  static reportedby = this.baseUrl + "/admin/reported/user/details"; //GET
  //  classifieds

  // static classifiedsget = this.baseUrl + "/admin/classified/category/all"; //POST
  static advertisementTableURL = this.baseUrl + "/admin/advertisement/getads"; //post

  static resetPasswordAttempt =
    this.baseUrl + "/admin/user/resetpasswordattempt";

  //posts

  static posts = this.baseUrl + "/admin/posts/list"; //POST
  static postDetails = this.baseUrl + "/admin/posts/list/details"; //GET
  static postCreate = this.baseUrl + "/admin/post/create"; //POST
  static postUpdate = this.baseUrl + "/admin/post/update"; //PUT
  static postReport = this.baseUrl + "/admin/reported/post/details"; //GET
  static deletePost = this.baseUrl + "/admin/post/delete"; //POST
  static reportedByPost = this.baseUrl + "/admin/reported/post/details"; //GET

  //plans
  static plans = this.baseUrl + "/admin/plans";
  static planbyid = this.baseUrl + "/admin/plans/details";
  static plansupdate = this.baseUrl + "/admin/plans/update"; // Updated for phase 2//POST
  static plancreate = this.baseUrl + "/admin/plan/create";
  static palnsdelete = this.baseUrl + "/admin/plans/delete";
  static getplantype = this.baseUrl + "/admin/configuration"; //to be used in plan create
  static getFeatures = this.baseUrl + "/admin/setting/get/features/list"; //GET
  // categories

  static category = this.baseUrl + "/admin/sector/occupations"; // GET
  static categoryId = this.baseUrl + "/admin/sector/occupations/details"; // GET
  static categoryupdate = this.baseUrl + "/admin/sector/occupations/update"; // PUT
  static categorydelete = this.baseUrl + "/admin/sector/occupations/delete"; //PUT
  static categorycreate = this.baseUrl + "/admin/sector/occupations/create"; //POST
  static listcategory = this.baseUrl + "/admin/sector/occupations/list"; //get

  //Sector

  static sectorData = this.baseUrl + "/admin/sector/get/list"; //GET

  //Location
  static getLocation = this.baseUrl + "/admin/getcords"; //GET
  static getLonLan = this.baseUrl + "/admin/getlatandlng"; //GET
  // Recharge

  static recharge = this.baseUrl + "/admin/subscriptions/list"; // POST
  static rechargeId = this.baseUrl + "/admin/subscriptions/list/details"; //GET
  static companyDetails = this.baseUrl + "/admin/configuration";
  // advertisments
  static advertisement = this.baseUrl + "/admin/advertisement/getads"; // POST
  static advertismentbyid = this.baseUrl + "/admin/advertisement/getadsbyid"; // GET
  static advertismentupdate = this.baseUrl + "/admin/advertisement/updateads"; //PUT
  static createadvertisment = this.baseUrl + "/admin/advertisement/createads"; //POST
  static deleteadvertisment = this.baseUrl + "/admin/advertisement/deleteads"; //PUT

  //  dashboard api
  static globalstatic = this.baseUrl + "/admin/stats/global"; // GET
  static subscriptions = this.baseUrl + "/admin/stats/total/subscription"; // GET
  static totalUsers = this.baseUrl + "/admin/stats/count/user/sector"; //GET
  static registeredusers = this.baseUrl + "/admin/stats/total/users/occupation"; // GET
  static totalusertype = this.baseUrl + "/admin/stats/total/user/usertype"; //GET

  // uplaodfile
  static inventoryUploadItems = this.baseUrl + "/admin/inventory/upload/items"; //post
  static inventoryUploadStock = this.baseUrl + "/admin/inventory/upload/stock"; //post
  static inventoryUploadPrice = this.baseUrl + "/admin/inventory/upload/price"; //post
  static uploadInventoryImages = this.baseUrl + "/admin/inventory/upload/image";
  static uploadFile = this.baseUrl + "/admin/upload/file"; //PUT
  static uploadHistory = this.baseUrl + "/admin/list/all/upload/file";
  static productStock = this.baseUrl + "/admin/inventory/availablecount";

  static canVisitHome =
    this.baseUrl + "/user/doctor/canVisitPatientHome?doctorId="; //get

  // customer service apis

  static listAllComplaints = this.baseUrl + "/admin/complaints/list"; //POST
  static deleteComplaint = this.baseUrl + "/admin/complaint/delete"; //POST
  static resolveComplaint = this.baseUrl + "/admin/complaints/markasresolved"; //POST


  //role
  static deleteRole = this.baseUrl + "/admin/role/delete"; //POST

  //creating loom api

  static createLoom = this.baseUrl + "/admin/loom/create"; //POST
  static getLoomDetails = this.baseUrl + "/admin/loom/details"; //POST
  static updateLoomDetails = this.baseUrl + "/admin/loom/update"; //POST
  static listAllLooms = this.baseUrl + "/admin/loom/list"; //POST
  static deleteLoom = this.baseUrl + "/admin/loom/delete"; //POST

  //for getting tags for post
  static getAllTagsForPost = this.baseUrl + "/admin/tags"; //GET
}

export default ConfigAPIURL;
