import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { MenuItem } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import iosappimg from "../../../assets/images/ios-app-store.png";
import iphoneimg from "../../../assets/images/iPhone 12 Mockup.png";
import googleimg from "../../../assets/images/google-play-store.png";
import logo from "../../../assets/images/logo.png";
import "./index.css";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));
export default function Header(props) {
  const classes = useStyles();
  const [termsHover, setTermsHover] = React.useState(false);
  const [privacyHover, setPrivacyHover] = React.useState(false);
  return (
    <div className={classes.root}>
      {/* <AppBar position='fixed'
      style={{background:" #16004D"}}
      >
        <Toolbar>
          <Typography variant='h6' className={classes.title}>
            <Avatar
              alt='Nekaaramitra'
              src='https://nekaaramitra.s3.ap-south-1.amazonaws.com/advertisement/Nekaaramitra1675835227.png'
              m={3}
              className={classes.large}
            />
          </Typography>
          <MenuItem style={{fontFamily:"Lato"}} onClick={() => props.history.push('/login')}>
            Login
          </MenuItem>
        </Toolbar>
      </AppBar> */}

      <div>
        <nav className="nav">
          <div className="logo-container">
            <img src={logo} alt="logo" className="logo" height="55px" />
          </div>
          <div>
            <button
              className="login"
              onClick={() => {
                props.history.push("/login");
              }}
            >
              Login
            </button>
          </div>
        </nav>
        <main className="container">
          <h1 style={{ fontSize: "2rem", fontWeight: 700, padding: "1rem 0" }}>
            Welcome to Nekaaramitra
          </h1>
          <p className="tag-line">The weavers community</p>
          <div className="appstore-container">
            <a
              href="https://play.google.com/store/apps/details?id=com.nekaaramitra.app&hl=en_US"
              target="_blank"
            >
              <img
                src={googleimg}
                alt="Get it on Google Play Store"
                height="75px"
              />
            </a>
            <a
              href="https://apps.apple.com/in/app/nekaaramitra/id6447051617"
              target="_blank"
              alt="Get it on Apple App Store"
            >
              <img
                src={iosappimg}
                alt="Download on the AppStore"
                height="50px"
              />
            </a>
          </div>
        </main>
        <div className="ellipse">
          <div className="position">
            <div className="image-container">
              <img
                src={iphoneimg}
                alt=""
                height="400px"
                style={{ paddingLeft: "3rem" }}
              />
            </div>
            <footer className="footer-container">
              <p className="footer-heading">Nekaaramitra</p>

              <div className="contact">
                <i
                  className="fa fa-map-marker"
                  aria-hidden="true"
                  style={{ display: "flex" }}
                >
                  <p style={{ paddingLeft: "10px", lineHeight: 1.5 }}>
                    # 27-8-202/c2 near ZPH School, Dandu Road,
                    <br />
                    Muddireddypalli, Hindupur, <br />
                    Sri Sathya Sai (Dist),
                    <br />
                    Andhra Pradesh-515201
                  </p>
                </i>
                <span className="terms">
                  <Typography
                    style={{
                      cursor: "pointer",
                      textDecoration: termsHover ? "underline" : "none",
                    }}
                    onClick={() => {
                      props.history.push("/terms-and-conditions");
                    }}
                    onMouseEnter={() => setTermsHover(true)}
                    onMouseLeave={() => setTermsHover(false)}
                  >
                    Terms & Conditions
                  </Typography>
                  <br />
                  <Typography
                    style={{
                      cursor: "pointer",
                      textDecoration: privacyHover ? "underline" : "none",
                      marginLeft:"5px"
                    }}
                    onClick={() => {
                      props.history.push("/privacy-policy");
                    }}
                    onMouseEnter={() => setPrivacyHover(true)}
                    onMouseLeave={() => setPrivacyHover(false)}
                  >
                    Privacy Policy
                  </Typography>
                </span>
                <span>
                  <i
                    className="fa fa-envelope-o"
                    aria-hidden="true"
                    style={{ display: "flex" }}
                  >
                    <p style={{ paddingLeft: "10px" }}>
                      nekaaramitra@gmail.com
                    </p>
                  </i>
                  <br />
                  <i
                    className="fa fa-phone"
                    aria-hidden="true"
                    style={{ display: "flex" }}
                  >
                    <p style={{ paddingLeft: "10px" }}>92905 87656</p>
                  </i>
                </span>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}
