import ConfigAPIURL from '../../../../config/ConfigAPIURL';
import APIRequest from '../../../../utils/APIRequest';

const notificationDefaultSetUp = {
  message: '',
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  severity: '',
  duration: 3000,
  open: false,
};

const initialState = {
  theme: localStorage.getItem('adminTheme') || 'LIGHT',
  languageData: JSON.parse(localStorage.getItem('lng')) || {
    displayName: 'English',
    code: 'en',
  },
  notification: notificationDefaultSetUp,
  sideDrawerData: false,
};
const reducer = (state = initialState, action) => {
  const newState = { ...state };
  // console.log({ newState, action });
  switch (action.type) {
    case 'SHORTCUTKEY':
      newState.shortcutKeyValue = action.value || '';
      break;
    case 'THEME':
      newState.theme = action.value;
      break;
    case 'LANGUAGE':
      newState.languageData = action.value;
      break;
    case 'NOTIFICATION_OPEN':
      newState.notification = action.value;
      break;
    case 'NOTIFICATION_DESTROY':
      newState.notification = notificationDefaultSetUp;
      break;
    case 'SIDEDRAWER':
      newState.sideDrawerData = action.value;
      break;
    case 'LOGOUT':
      logoutFunction();
      break;
    default:
      break;
  }
  return newState;
};
const logoutFunction = (props) => {
  APIRequest.request('GET', ConfigAPIURL.userLogout, '').then((response) => {
    if (response.code === 100) {
      window.location.href = '#/login';
    }
  });
  sessionStorage.clear();
  localStorage.clear();
  // LocalStorage.permission = null;
};
export default reducer;
