import React, { useEffect, useState } from "react";
import { Box, Grid, Pagination, Stack } from "@mui/material";
import { Typography } from "antd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import APIRequest from "../../../../../utils/APIRequest";
import ConfigAPIURL from "../../../../../config/ConfigAPIURL";
import SnackbarUtils from "../../../../../utils/SnackbarUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UpdateLoom from "./UpdateLoom";
import SearchImg from "../../../../../assets/images/SearchImg.png";
import ScrollImage from "./ScrollImage/ScrollImage";
import DeleteLoomModal from "./DeleteLoomModal";
import { Popover } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const History = (props) => {
  const {
    userForm,
    getEditable,
    showNotification,
    userId,
    setUserForm,
    activeTab,
  } = props;
  const [loomData, setLoomData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [focused, setFocused] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState("");
  const [currentLoom, setCurrentLoom] = useState(null);
  const [page, setPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loomIdToDelete, setLoomIdToDelete] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [loomDataFromPopover, setLoomDataFromPopOver] = useState(null);
  const itemsPerPage = 10;

  const handleClickpop = (event, value) => {
    setLoomDataFromPopOver(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClosepop = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Handle viewing all attachments
  const handleSeeAll = () => {
    const value = loomDataFromPopover;
    if (value) {
      value.images.forEach((attachment) => {
        window.open(attachment?.url, "_blank");
      });
    }
  };

  // Filter data based on search query
  const filteredData =
    loomData &&
    loomData?.filter(
      (value) =>
        value?.loomNo?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        value?.serviceNo?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );

  // Handle deleting a particular loom
  const deleteParticualarLoom = (loomId) => {
    setDeleteModal(true);
    setLoomIdToDelete(loomId);
  };

  const deleteLoom = () => {
    console.log(loomIdToDelete);
    let url = ConfigAPIURL.deleteLoom;
    let method = "POST";
    const requestBody = {
      userId: userId,
      loomId: loomDataFromPopover?._id,
    };
    APIRequest.request(method, url, JSON.stringify(requestBody)).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.code === 100 && response.data.responseCode === 109) {
            listAllLooms();
            setDeleteModal(false);
            handleClosepop();
            SnackbarUtils.sucess(
              "Loom data deleted successfully!",
              "bottomCenter",
              3000
            ).then((notification) => {
              showNotification(notification);
            });
          }
          if (response.code === 100 && response.data.responseCode === 114) {
            SnackbarUtils.error(
              "Something went wrong! Please try again later.",
              "bottomCenter",
              3000
            ).then((notification) => {
              showNotification(notification);
            });
          }
        }
      }
    );
  };

  const handleClose = () => {
    setDeleteModal(false);
    handleClosepop();
  };

  // Handle opening update modal
  const handleOpenUpdateModal = (loom, index) => {
    setCurrentLoom(loom);
    setCurrentIndex(index);
    setOpenUpdateModal(true);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filteredData?.slice(startIndex, endIndex);

  const listAllLooms = () => {
    let url = ConfigAPIURL.listAllLooms;
    let method = "POST";

    APIRequest.request(
      method,
      url,
      JSON.stringify({
        userId: userId,
      })
    ).then((response) => {
      if (response !== undefined && response !== null) {
        if (response?.code === 100 && response?.data?.responseCode === 109) {
          setLoomData(response?.data?.looms);
        }
      }
    });
  };

  useEffect(() => {
    if (activeTab === "history") {
      listAllLooms();
    }
  }, [activeTab === "history"]);

  return (
    <div>
      {/* Custom search box */}
      <Stack spacing={1} direction="row" justifyContent="flex-end" margin={2}>
        <Box
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0px 8px 18px 0px #06C05D14",
            padding: "5px",
            width: "30%",
            marginBottom: "1rem",
            border: "1px solid #EEEEEE",
            ...(focused && { outline: "1px solid #06C05D" }),
          }}
        >
          <input
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            style={{
              backgroundColor: "white",
              border: "none",
              outline: "none",
              paddingTop: "5px",
            }}
            placeholder="Search Here"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <img
            src={SearchImg}
            alt=""
            style={{
              height: "38px",
              width: "38px",
              padding: "4px",
              color: "orange",
              fontSize: "2rem",
              float: "right",
            }}
          />
        </Box>
      </Stack>
      {currentPageData?.length === 0 ||
      currentPageData === undefined ||
      currentPageData === null ? (
        <Typography
          variant="body1"
          style={{
            textAlign: "center",
            padding: "20px",
            backgroundColor: "#f9f9f9",
            borderRadius: "10px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            color: "#000",
            fontWeight: "bold",
          }}
        >
          No data available!
        </Typography>
      ) : (
        currentPageData?.map((value, i) => (
          <Box
            key={i}
            style={{
              position: "relative",
              boxShadow: "0px 8px 18px 0px #00000014",
              padding: "24px",
              margin: "1rem",
              borderRadius: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <div style={{ padding: "10px" }}>
                  <div style={{ gap: 10 }}>
                    <div style={{ marginRight: "20px" }}>
                      <span style={{ fontWeight: "400" }}>Service Number:</span>
                      <span
                        style={{
                          color: "#000",
                          marginLeft: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        {value?.serviceNo}
                      </span>
                    </div>
                    <div style={{ marginRight: "20px" }}>
                      <span style={{ fontWeight: "400" }}>Loom Number:</span>
                      <span
                        style={{
                          color: "#000",
                          marginLeft: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        {value?.loomNo}
                      </span>
                    </div>
                    <div>
                      <span style={{ fontWeight: "400" }}>Loom Type:</span>
                      <span
                        style={{
                          color: "#000",
                          marginLeft: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        {value?.loomType}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div style={{ marginLeft: "6px" }}>
                  <MoreVertIcon
                    icon="more_vert"
                    style={{ height: "32px", width: "32px" }}
                    onClick={(event) => handleClickpop(event, value)}
                  />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosepop}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: {
                        width: "158px",
                        height: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        cursor: "pointer",
                        minWidth: "154px",
                        gap:"20px"
                      },
                    }}
                  >
                    <div style={{ padding: "10px",gap:"20px" }}>
                      {/* <Typography onClick={() => handleSeeAll(i)}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <VisibilityIcon
                            style={{ height: "24px", width: "24px" }}
                          />
                          <span
                            style={{
                              marginLeft: "6px",
                              fontSize: "16px",
                              fontWeight: "400",
                            }}
                          >
                            View
                          </span>
                        </div>
                      </Typography> */}
                      <Typography
                        onClick={() => handleOpenUpdateModal(value, i)}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <EditIcon style={{ height: "24px", width: "24px" }} />
                          <span
                            style={{
                              marginLeft: "6px",
                              fontSize: "16px",
                              fontWeight: "400",
                            }}
                          >
                            Edit
                          </span>
                        </div>
                      </Typography>
                      <Typography
                        onClick={() => deleteParticualarLoom(value?._id)}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <DeleteIcon
                            style={{ height: "24px", width: "24px" }}
                          />
                          <span
                            style={{
                              marginLeft: "6px",
                              fontSize: "16px",
                              fontWeight: "400",
                            }}
                          >
                            Delete
                          </span>
                        </div>
                      </Typography>
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "9px" }}>
              <Typography
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "21.79px",
                  textAlign: "left",
                  color: "#737373",
                }}
              >
                {value?.description}
              </Typography>
            </div>
            <Stack
              style={{
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                },
                background: "white",
                gap: "10px",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    marginTop: "7px",
                    padding: "20px",
                    overflowX: "auto",
                  }}
                >
                  <ScrollImage
                    attachment={value?.images}
                    loomIndex={i}
                    setLoomData={setLoomData}
                    loomData={loomData}
                    isModalOpen={openUpdateModal}
                    setModalOpen={setOpenUpdateModal}
                    loom={currentLoom}
                    getEditable={getEditable}
                    showNotification={showNotification}
                    setUserForm={setUserForm}
                    userForm={userForm}
                    userId={userId}
                    i={currentIndex}
                    listAllLooms={listAllLooms}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Box>
        ))
      )}
      <UpdateLoom
        isModalOpen={openUpdateModal}
        setModalOpen={setOpenUpdateModal}
        loom={currentLoom}
        getEditable={getEditable}
        showNotification={showNotification}
        setUserForm={setUserForm}
        userForm={userForm}
        loomData={loomData}
        userId={userId}
        i={currentIndex}
        listAllLooms={listAllLooms}
        loomId={loomDataFromPopover}
        handleClosepop={handleClosepop}
      />
      {/* {filteredData && filteredData.length > itemsPerPage && ( */}
      <Box
        sx={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          count={Math.ceil(filteredData?.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          size="large"
        />
      </Box>
      <DeleteLoomModal
        open={deleteModal}
        close={handleClose}
        deleteLoom={deleteLoom}
      />
    </div>
  );
};

export default History;
