import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation } from "react-i18next";
import TranslateIcon from "@material-ui/icons/Translate";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LanguageConfig from "../../../../config/LanguageConfig";
import Tooltip from "@material-ui/core/Tooltip";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Hidden } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { connect } from "react-redux";
import SnackbarUtils from "../../../../utils/SnackbarUtils";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: "Lato",
  },
  title: {
    flexGrow: 1,
    fontFamily: "Lato",
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      fontFamily: "Lato",
    },
    backgroundImage: theme.custom.topHeader.toolbarColor,
    fontFamily: "Lato",
  },
  appBarDrawerClose: {
    backgroundImage: theme.custom.topHeader.toolbarColor,
    fontFamily: "Lato",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    fontFamily: "Lato",
  },
  textLimit: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontFamily: "Lato",
  },
  floatRight: {
    textAlign: "right",
    fontFamily: "Lato",
  },
  languageMenuColor: {
    color: theme.custom.topHeader.toolBarLanguage,
    background: "transparent",
    fontFamily: "Lato",
  },
  logoutIcon: {
    color: theme.custom.topHeader.toolBarLogout,
    fontFamily: "Lato",
  },
}));

function TopBar(props) {
  const classes = useStyles();
  const [languageList, setLanguageList] = React.useState([]);
  useEffect(() => {
    LanguageConfig.languageList().then((lngList) => {
      setLanguageList(lngList);
    });
    props.i18n.changeLanguage(props.languageData.code);
  }, []);
  const notificationTest = () => {
    SnackbarUtils.sucess("HI", "bottomCenter", 3000).then((notification) => {
      props.publishNotification(notification);
    });
  };
  const languageChange = (data) => {
    props.i18n.changeLanguage(data.code);
    props.languageChange(data);
    localStorage.setItem("lng", JSON.stringify(data));
    handleLanguageMenuClose();
  };
  const [languageDropdown, setLanguageDropdown] = React.useState(null);
  const handleLanguageMenuClick = (event) => {
    setLanguageDropdown(event.currentTarget);
  };
  const handleLanguageMenuClose = () => {
    setLanguageDropdown(null);
  };

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const handleLogout = () => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutConfirm = () => {
    setLogoutDialogOpen(false);
    props.logout();
  };

  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <AppBar
        position="fixed"
        className={
          props.mobileOpen ? classes.appBarDrawerClose : classes.appBar
        }
        style={{ background: "#17024B" }}
      >
        <Toolbar style={{ fontFamily: "Lato" }}>
          <Tooltip
            ml={2}
            title={props.t("topNavBar.menu")}
            style={{ fontFamily: "Lato" }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.handleDrawerToggle}
              className={classes.menuButton}
              style={{ color: "white" }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>

          <Typography
            variant="h6"
            className={classes.title}
            style={{ fontFamily: "Lato !important", color: "white" }}
          >
            {props.t(props.pageName)}
          </Typography>

          <Tooltip ml={2} title={props.t("topNavBar.logout")}>
            <IconButton onClick={handleLogout}>
              <ExitToAppIcon className={classes.logoutIcon} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Dialog
        PaperProps={{
          style: {
            width: "420px",
            height: "167px",
          },
        }}
        open={logoutDialogOpen}
        onClose={handleLogoutCancel}
      >
        <DialogTitle>Confirm Logout ?</DialogTitle>
        <DialogActions>
          <Button
            style={{ position: "absolute", right: "100px", bottom: "1px" }}
            onClick={handleLogoutCancel}
            color="primary"
          >
            No
          </Button>
          <Button
            style={{ position: "absolute", bottom: "1px" }}
            onClick={handleLogoutConfirm}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

TopBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    languageData: state.languageData,
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    languageChange: (languageData) =>
      dispatch({ type: "LANGUAGE", value: languageData }),
    logout: () => dispatch({ type: "LOGOUT", value: "" }),
    publishNotification: (notification) =>
      dispatch({ type: "NOTIFICATION_OPEN", value: notification }),
  };
};

export default withTranslation("translations")(
  connect(mapStateToProps, mapDispachToProps)(TopBar)
);
