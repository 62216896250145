import { createTheme } from "@material-ui/core/styles";
import "./font.css";

const font = "Lato";

export const LIGHT = createTheme({
  name: "LIGHT",
  overrides: {
    //.MuiPopover-paper

    MuiPopover: {
      paper: {
        minWidth: "200px",
      },
    },
    MuiDialog: {
      root: {
        // background: 'linear-gradient(-45deg, #2196f3a1 0%, #2196f3a1 33%, #00bfa5a1 100%)',
      },
      container: {
        // background: 'linear-gradient(-45deg, #2196f3a1 0%, #2196f3a1 33%, #00bfa5a1 100%)',
      },
      paperFullScreen: {
        background: "linear-gradient(to right, #EDEDED, #EDEDED)",
        // background: "#621990",

        // 'linear-gradient(30deg, #6da5de  0%,#0575e6  15%, #7DE3DC 100%)',
        // 'linear-gradient(-45deg, #2196f3a1 0%, #2196f3a1 33%, #00bfa5a1 100%)',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        background: "linear-gradient(to right, #FF8D00, #FF8D00)",
        // background: "#621990",

        // 'linear-gradient(30deg, #6da5de  0%,#0575e6  15%, #7DE3DC 100%)',
        // 'linear-gradient(-45deg, #2196f3a1 0%, #2196f3a1 33%, #00bfa5a1 100%)',
      },
    },
    MuiGrid: {
      root: {
        paddingRight: "3px",
        fontFamily: font,
      },
    },

    MuiCheckbox: {
      colorSecondary: {
        Mui: {
          checked: {
            color: "#fff",
          },
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        fontFamily: font,
      },
    },

    MuiTypography: {
      root: {
        fontFamily: font,
        fontSize: 16,
        // color: '#666c73',
      },
      body1: {
        fontFamily: font,
        fontSize: 16,
        // color: '#666c73',
      },
      body2: {
        fontFamily: font,
        fontSize: 16,
      },
    },

    // MuiTabScrollButton-root

    MuiTab: {
      wrapper: {
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "bold",
      },
      textColorInherit: {
        opacity: "unset",
      },
      // scrollButtons: {
      //   backgroundColor: 'black',
      //   color: 'black',
      // },
    },

    MuiTableCell: {
      stickyHeader: {
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: font,
        color: "#666c73",
      },
      head: {
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: font,
        color: "#666c73",
      },

      body: {
        fontFamily: font,
      },
    },
    // MuiFormLabel: {
    // 	root: {
    // 		color: '#42A5F5',
    // 		'&$focused': {
    // 			color: '#42A5F5',
    // 		},
    // 	},
    // 	asterisk: {
    // 		color: 'red',
    // 	},
    // },

    MuiInputLabel: {
      formControl: {
        fontSize: 16,
        width: "max-content",
        fontFamily: font,
        lineHeight: "0",
      },
      asterisk: {
        color: "red",
      },
    },

    MuiInputBase: {
      input: {
        fontSize: 14,
        fontFamily: font,
      },
    },
    // MuiInput: {
    //   underline: {
    //     '&:hover:not($disabled):after': {
    //       borderBottom: '1px solid #42A5F5',
    //     },
    //     '&:hover:not($disabled):before': {
    //       borderBottom: '1px solid #42A5F5',
    //     },
    //     '&:focus:not($disabled):after': {
    //       borderBottom: '1px solid #42A5F5',
    //     },
    //     '&:focus:not($disabled):before': {
    //       borderBottom: '1px solid #42A5F5',
    //     },
    //     '&:after': {
    //       borderBottom: '1px solid #42A5F5',
    //     },
    //     '&:before': {
    //       borderBottom: '1px solid #42A5F5',
    //     },
    //     borderBottom: '1px solid #42A5F5',
    //   },
    // },

    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: "#fff",
      },
    },

    MuiButton: {
      root: {
        // background: '#fff',
        // '&:hover': {
        //   backgroundColor: '#fff',
        // },
      },
      textPrimary: {
        // color: '#fff',
        // '&:hover': {
        //   backgroundColor: '#fff',
        // },
      },
      label: {
        backgroundColor: "rgba(255, 221, 179, 1)",
        border: "1px solid  #FF8D00",
        padding: "7px 20px",
        borderRadius: 15,
        color: "black",
        fontWeight: "bold",
      },
    },
    MuiTableRow: {
      selected: {
        backgroundColor: "#FF8D00",
        color: "white",
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        // height: 'unset',
        height: "125px",
        // backgroundColor: 'unset',
      },
    },

    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: "white",
      },
      toolbarTxt: { fontWeight: "bold", color: "#FF8D00" },
    },

    MuiPickerDTToolbar: {
      separator: {
        color: "#fff",
      },
    },

    MuiPickersModal: {
      dialog: {
        overflow: "hidden",
      },
    },

    // MuiPickersModal: {
    //   dialog: {
    //     overflow: 'initial',
    //   },
    // },

    MuiPickersToolbarButton: {
      toolbarBtn: {
        textTransform: "math-auto",
      },
    },
  },
  // #621990
  palette: {
    type: "light",
    primary: {
      main: "#FF8D00",
    },
    secondary: {
      main: "#FF8D00",
    },
    info: {
      main: "#FF8D00",
    },
    background: {
      default: "#FFE9CE",
    },
  },
  custom: {
    dark: "#000 !important",
    background: "#fff",
    // greenColor:
    //   "linear-gradient(to right, #76b852, #8dc26f)" /* fallback for old browsers */,
    orangeColor:
      "linear-gradient(to right, #f7971e, #ffd200)" /* fallback for old browsers */,
    //     ongoingColor: "linear-gradient(to right, #de6262, #ffb88c)",
    //     verifyPaymentColor: "linear-gradient(to right, #d53369, #cbad6d)",
    //     deliveredColor: "linear-gradient(to right, #a73737, #7a2828)",
    //     completedColor: "green",
    //     refundedColor: "linear-gradient(to right, #4b6cb7, #182848)",
    //     cancelledColor: "red",
    // accepted:"0xff833eaf",
    containerColor: "linear-gradient(to right, #FF8D00, #f3e1fe)",
    // 'linear-gradient(30deg, #6da5de  0%,#0575e6  15%, #7DE3DC 100%)',
    // containerColor:
    //   'linear-gradient(-45deg, #2196F3 0%, #2196F3 33%, #00BFA5 100%)',
    topHeader: {
      toolbarColor:
        "linear-gradient(30deg, #FF8D00  0%,#FF8D00  15%, #f3e1fe 100%)",
      // toolbarColor:
      //   'linear-gradient(-45deg, #2196F3 0%, #2196F3 33%, #00BFA5 100%)',
      toolBarLogout: "#fff",
      toolBarLanguage: "#fff",
    },
    sideDrawer: {
      boxShadow: "0 10px 15px -5px rgba(62, 57, 107, .07)",
      backdropFilter: "saturate(180%) blur(20px)",
      backgroundColor: "rgba(23, 2, 75, 1)",
      companyName: "#FFFFFF",
      userName: "#000",
      menuColor: "white",
    },
  },
  MuiListItemIcon: {
    root: {
      color: "white",
    },
  },
});
